import { useEffect, useMemo, useState } from "react";
import { Card, Col, Dropdown, Form, Nav, Row, Tab } from "react-bootstrap";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import MarketModal from "./MarketModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getMarketDetail,
  getOrderInfo,
} from "../../../slices/market/MarketSlice";
import { debounce, getMatched, isValidArray } from "../../../helpers/util";
import ScheduleModal from "./ScheduleModal";
import NotifyModal from "./NotifyModal";
import Loader from "../../../Common/Loader/Loader";
interface MarketDataProps {
  isShow?: any;
  hideUserModal?: any;
}
const MarketSideTable = ({}: MarketDataProps) => {
  const marketDetail = useSelector((state: any) => state?.market?.data ?? []);
  const marketLoading = useSelector(
    (state: any) => state?.market?.loading ?? false
  );

  const [marketData, setMarketData] = useState<any>([]);
  const [columnFilter, setColumnFilter] = useState<any>({
    index: 0,
    order: false,
    name: "target_currency_short_name",
  });
  const [selectMore, setSelectMore] = useState<string>("More");
  const [sellCheckBox, setSellCheckBox] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<string>("All");
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 50,
  });
  let marketDataObject = {
    page: selected?.page,
    perPage: selected?.perPage,
    sort: columnFilter?.name,
    search: selected?.search,
    direction: columnFilter?.order === true ? "desc" : "asc",
    filter:
      selectMore !== "More"
        ? selectMore
        : currentTab === "All"
        ? ""
        : currentTab,
    available_to_sell: sellCheckBox,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMarketDetail(marketDataObject));
  }, [selected, columnFilter, currentTab, selectMore, sellCheckBox]);

  useEffect(() => {
    setMarketData(marketDetail?.data);
  }, [marketDetail]);
  const [isShow, setIsShow] = useState(false);
  const [scheduleShow, setScheduleShow] = useState(false);
  const [notifyShow, setNotifyShow] = useState(false);

  const [actionData, setActionData] = useState<any>();
  const getMarketData = (value?: any) => {
    let data = {
      base_currency_short_name: value?.base_currency_short_name,
      target_currency_short_name: value?.target_currency_short_name,
      base_currency_precision: value?.base_currency_precision,
      target_currency_precision: value?.target_currency_precision,
      coindcx_name: value?.coindcx_name,
    };
    return data;
  };
  //  buy sell modal
  const hideUserModal = (value: any) => {
    !isShow && dispatch(getOrderInfo(getMarketData(value)));

    setIsShow(!isShow);
  };
  //Schedule modal
  const hideScheduleModal = (value: any) => {
    !scheduleShow && dispatch(getOrderInfo(getMarketData(value)));

    setScheduleShow(!scheduleShow);
  };
  //Notify modal
  const hideNotifyModal = (value: any) => {
    if (notifyShow === true) {
      setActionData("");
    } else {
      dispatch(getOrderInfo(getMarketData(value)));
    }

    // !notifyShow && dispatch(getOrderInfo(data));

    setNotifyShow(!notifyShow);
  };
  const handleDebounceSearch = debounce((value: string) => {
    setSelected({ ...selected, search: value });
    // Perform search operation here
  }, 500);
  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;
    handleDebounceSearch(item);
  };

  const handleTab = (value: any) => {
    if (value?.menu) {
      setSelectMore(value?.data);
      setCurrentTab("");
    } else {
      setCurrentTab(value?.data);
      setSelectMore("More");
    }
  };

  const changeDropDown = (value: string, menu: string) => {
    setSelectMore(value);
    handleTab({ data: value, menu: menu });
  };
  const columnChnageFilter = (value: any) => {
    let data = columns[value?.index].accessor;

    setColumnFilter({
      ...columnFilter,
      index: value?.index,
      order: value?.order,
      name: data === "target_currency" ? "target_currency_short_name" : data,
    });
  };
  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "PAIRS",
        accessor: "coindcx_name",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return (
            <div>
              <img
                src={`${getMatched(
                  cell.row.original.base_currency_short_name
                )}`}
                alt=""
                className="avatar-xs rounded-circle me-2"
              />
              {cell.row.original.target_currency_short_name}
              <span className="fs-5">{"-"}</span>
              {cell.row.original.base_currency_short_name}
              <span className="text-dark"></span>
            </div>
          );
        },
      },
      {
        Header: "CURRENCY",
        accessor: "target_currency_short_name",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.target_currency_short_name}
              <span className="mx-2">
                ({cell.row.original?.target_currency_name})
              </span>
            </div>
          );
        },
      },
      {
        Header: "TRADE CURRENCY",
        accessor: "base_currency_short_name",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.base_currency_short_name}
              <span className="mx-2">
                ({cell.row.original?.base_currency_name})
              </span>
            </div>
          );
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <li
              className="list-inline-item"
              data-bs-toggle="tooltip"
              data-bs-trigger="hover"
              data-bs-placement="top"
              aria-label="View"
              data-bs-original-title="View"
            >
              <button
                className="btn btn-soft-primary btn-sm d-inline-block"
                type="button"
                onClick={() => {
                  hideUserModal(cell.row.original);
                  let item = {
                    ...cell.row.original,
                    type: "Buy",
                  };

                  setActionData(item);
                }}
              >
                Buy
              </button>
            </li>
            <li className="list-inline-item edit">
              <button
                type="button"
                className="btn btn-soft-danger btn-sm d-inline-block"
                onClick={() => {
                  hideUserModal(cell.row.original);
                  let item = {
                    ...cell.row.original,
                    type: "Sell",
                  };
                  setActionData(item);
                }}
              >
                Sell
              </button>
            </li>

            <li className="list-inline-item edit">
              <button
                type="button"
                className="btn btn-soft-success btn-sm d-inline-block"
                onClick={() => {
                  hideScheduleModal(cell.row.original);
                  let item = {
                    ...cell.row.original,
                  };
                  setActionData(item);
                }}
              >
                Schedule
              </button>
            </li>

            <li className="list-inline-item edit">
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm d-inline-block"
                onClick={() => {
                  hideNotifyModal(cell.row.original);
                  let item = {
                    ...cell.row.original,
                  };
                  setActionData(item);
                }}
              >
                Notify Me
              </button>
            </li>
          </ul>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <div className="tab ">
        <Row className="">
          <Col>
            <Card>
              <Card.Body id="scrollbar">
                <Row>
                  <div className="col-sm-auto ms-auto">
                    <div className="d-flex gap-3 align-items-center">
                      <div className=" d-flex justify-content-between align-items-center gap-2">
                        <Form.Label
                          className="form-check-label"
                          htmlFor="formCheck"
                        >
                          Available for sell
                        </Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="formCheck"
                          className="form-check-input-checked-border-danger"
                          onChange={() => {
                            setSellCheckBox(!sellCheckBox);
                          }}
                        />
                      </div>
                      <div className="search-box">
                        <Form.Control
                          type="text"
                          id="searchOrderList"
                          placeholder="Search for Result"
                          onChange={(e: any) => handleSearch(e.target)}
                        />
                        <i className="las la-search search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Row>
                <div>
                  <Tab.Container defaultActiveKey={`${currentTab ?? ""}`}>
                    <Nav
                      as="ul"
                      variant="tabs"
                      className="nav-tabs-custom nav-primary gap-2"
                      role="tablist"
                    >
                      {marketDetail?.currencies?.length > 0 && (
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey={currentTab && `All`}
                            onClick={() => {
                              handleTab({ data: "All", tab: "tab" });
                            }}
                          >
                            All
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {isValidArray(marketDetail?.currencies) &&
                        marketDetail?.currencies?.map(
                          (tab: any, tabIndex: number) =>
                            tabIndex < 5 && (
                              <Nav.Item as="li" key={tabIndex}>
                                <Nav.Link
                                  eventKey={`${currentTab && tab}`}
                                  onClick={() =>
                                    handleTab({ data: tab ?? "", tab: "tab" })
                                  }
                                >
                                  {tab}
                                </Nav.Link>
                              </Nav.Item>
                            )
                        )}
                      {marketDetail?.currencies?.length > 5 && (
                        <Dropdown>
                          <Dropdown.Toggle variant="primary btn-sm">
                            {selectMore ?? "More"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-menu-end dropdownmenu-primary">
                            {isValidArray(marketDetail?.currencies) &&
                              marketDetail?.currencies?.map(
                                (selectTab: any, selectIndex: number) =>
                                  selectIndex >= 5 && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        changeDropDown(selectTab, "menu");
                                      }}
                                      key={selectIndex}
                                    >
                                      {" "}
                                      {selectTab}
                                    </Dropdown.Item>
                                  )
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Nav>
                  </Tab.Container>
                </div>

                <div className="mt-4">
                  {marketLoading ? (
                    <div>
                      <Loader />
                    </div>
                  ) : marketData && marketData.length > 0 ? (
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={marketData || []}
                      customPageSize={selected?.perPage ?? 50}
                      divClassName="table-card table-responsive"
                      tableClass="table-hover table-nowrap align-middle mb-0"
                      isBordered={false}
                      PaginationClass="align-items-center mt-4 gy-3"
                      selected={selected}
                      setSelected={setSelected}
                      totalPage={marketDetail?.totalPages}
                      columnFilter={columnFilter}
                      setColumnFilter={setColumnFilter}
                      columnChnageFilter={columnChnageFilter}
                    />
                  ) : (
                    <NoSearchResult
                      title={`${
                        marketDetail?.message ?? "No Market Data Found"
                      }`}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <MarketModal
          isShow={isShow}
          handleClose={hideUserModal}
          actionData={actionData}
        />
        <ScheduleModal
          isShow={scheduleShow}
          handleClose={hideScheduleModal}
          actionData={actionData}
        />
        <NotifyModal
          isShow={notifyShow}
          handleClose={hideNotifyModal}
          actionData={actionData}
        />
      </div>
    </div>
  );
};

export default MarketSideTable;
