import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  logoutData: [],
};

export const logout = createAsyncThunk(
  "login",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/logout`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        showErrorMsg(error);
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const logoutSlice = createSlice({
  name: "logoutSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // logout
    builder.addCase(logout.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(logout.rejected, (state: any, action: any) => {
      state.loading = false;
      state.logoutData = [];
      state.error = action.payload || null;
    });
    builder.addCase(logout.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.logoutData = action?.payload?.data;
    });
  },
});

export default logoutSlice.reducer;
