import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getGroupOrderReport } from "../../../slices/orders/groupOrderSlice";
import { useSelector } from "react-redux";
import BreadCrumb from "../../../Common/BreadCrumb";
import { getMinusPlus } from "../../../helpers/util";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import MultiHeadTable from "../../../Common/Tabledata/MultiHeadTable";

const GroupOrderReport = () => {
  document.title = "Group Report | Crypto-Trade-App";
  const id = useLocation();
  const dispatch = useDispatch();
  const [report, setReport] = useState<any>();
  const reportDetail = useSelector(
    (state: any) => state?.groupOrder?.groupReportData ?? []
  );
  const [columnFilter, setColumnFilter] = useState<any>({
    index: 0,
    order: false,
    name: "market",
  });
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 50,
  });

  useEffect(() => {
    id?.state && dispatch(getGroupOrderReport({ id: id?.state }));
  }, [id?.state]);
  const columnChnageFilter = (value: any) => {
    let data = columns[value?.index].accessor;
    setColumnFilter({
      ...columnFilter,
      index: value?.index,
      order: value?.order,
      name: data,
    });
  };

  const columns = [
    {
      Header: "Market",
      accessor: "market_name",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Trade Currency",
      accessor: "trade_currency",
    },

    {
      Header: "Buy",
      colSpan: 4,

      style: {
        textAlign: "center",
      },

      columns: [
        {
          Header: "Average Price ",
          accessor: "filled-buy",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.buy?.avg_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Price ",
          accessor: "buy_avg_price",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.buy?.total_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Quantity ",
          accessor: "buy_total_price",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.buy?.total_quantity}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Trade ",
          accessor: "Trade",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.buy?.total_trade}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "Sell",
      colSpan: 4,

      style: {
        textAlign: "center",
      },
      columns: [
        {
          Header: "Average Price ",
          accessor: "sell-avg",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.sell?.avg_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Price ",
          accessor: "sell_total_price",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.sell?.total_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Quantity ",
          accessor: "sell_total_quantity",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.sell?.total_quantity}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Trade ",
          accessor: "sell_Trade",
          Filter: false,
          background: true,
          Cell: (cell: any) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <span className="text-dark">
                  {cell.row.original?.sell?.total_trade}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "Profit/Loss",
      accessor: "profit_loss",
      Cell: (cell: any) => {
        return (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.profit_loss ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.profit_loss}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Price",
      colSpan: 2,
      style: {
        textAlign: "center",
      },
      columns: [
        {
          Header: "buy Price ",
          accessor: "total_buy_price",
          style: {
            textAlign: "right",
          },
          Filter: false,
          background: true,
        },
        {
          Header: "current Price ",
          style: {
            textAlign: "right",
          },
          accessor: "total_current_price",
          Filter: false,
          background: true,
        },
      ],
    },
    {
      Header: "current status",
      accessor: "status",

      Cell: (cell: any) => {
        return (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.total_buy_price ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.total_buy_price}
            </span>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    setReport(reportDetail?.data);
  }, [reportDetail]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb pageTitle="Group Order" title="Report" />
        <Link to={"/group_order"} className="btn btn-primary mb-2">
          Back
        </Link>
        <Row className="justify-content-center">
          <Col xxl={12}>
            <Card id="demo">
              <Card.Body>
                {report ? (
                  <Row>
                    <Row className="mb-2">
                      <Col className="mb-2" sm={4}>
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="fs-18 text-muted mt-2">Total Trade</p>
                          <small className="badge bg-success-subtle text-success fs-18">
                            {report?.total_trades ?? 0}
                          </small>
                        </div>
                      </Col>
                      <Col className="mb-2" sm={4}>
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Buy
                          </p>
                          <small className="badge bg-primary-subtle text-primary fs-18">
                            {report?.total_buy_trades ?? 0}
                          </small>
                        </div>
                      </Col>

                      <Col className="mb-2" sm={4}>
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Sell
                          </p>
                          <small className="badge bg-danger-subtle text-danger fs-18">
                            {report?.total_sell_trades ?? 0}
                          </small>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4">
                      <MultiHeadTable
                        isPagination={false}
                        columns={columns}
                        data={report?.trades || []}
                        customPageSize={report?.trades?.length}
                        divClassName="table-card table-responsive"
                        tableClass="table-hover table-nowrap align-middle mb-0 border"
                        isBordered={false}
                        PaginationClass="align-items-center mt-4 gy-3"
                        selected={selected}
                        setSelected={setSelected}
                        totalPage={reportDetail?.totalPages}
                        columnFilter={columnFilter}
                        setColumnFilter={setColumnFilter}
                        columnChnageFilter={columnChnageFilter}
                      />
                    </div>
                  </Row>
                ) : (
                  <NoSearchResult
                    title={`${
                      reportDetail?.message ??
                      "No Group Order Report Data Found"
                    }`}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GroupOrderReport;
