import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  secretData: [],
  defaultLoading: false,
  updateStatusLoading: false,
};

export const addSecretKey = createAsyncThunk(
  "add-secretKey",
  async (data: any) => {
    try {
      const response: any = await apiClient.create(`api/user-keys`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const setDefaultKey = createAsyncThunk(
  "default-key",
  async (data: any) => {
    try {
      const response: any = await apiClient.put(
        `api/user-keys/default/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getSecretKey = createAsyncThunk(
  "get-secretKey",
  async (data: any) => {
    try {
      const response: any = await apiClient.get(`api/user-keys`, data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
//  deleteKey
export const deleteKey = createAsyncThunk(
  "deleteKey",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.delete(
        `api/user-keys/${data?.id}`,
        data
      );

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const userSettingSlice = createSlice({
  name: "userSetting",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(addSecretKey.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(addSecretKey.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload || null;
    });
    builder.addCase(addSecretKey.fulfilled, (state: any, action: any) => {
      state.loading = false;
    });

    // get secret key
    builder.addCase(getSecretKey.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getSecretKey.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload || null;
    });
    builder.addCase(getSecretKey.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.secretData = action.payload;
    });

    // default secret key
    builder.addCase(setDefaultKey.pending, (state: any, action: any) => {
      state.defaultLoading = true;
    });
    builder.addCase(setDefaultKey.rejected, (state: any, action: any) => {
      state.defaultLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(setDefaultKey.fulfilled, (state: any, action: any) => {
      state.defaultLoading = false;
      state.secretData = action.payload;
    });
    // update status
    builder.addCase(deleteKey.pending, (state: any, action: any) => {
      state.updateStatusLoading = true;
    });
    builder.addCase(deleteKey.rejected, (state: any, action: any) => {
      state.updateStatusLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(deleteKey.fulfilled, (state: any, action: any) => {
      state.updateStatusLoading = false;
    });
  },
});

export default userSettingSlice.reducer;
