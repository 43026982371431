import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { orderNote } from "../../../slices/orders/orderSlice";
import { useSelector } from "react-redux";

interface userProps {
  isShow?: boolean;
  handleClose?: any;
  actionData?: any;
}

const NoteModal = ({ isShow, handleClose, actionData }: userProps) => {
  const dispatch = useDispatch();
  const noteLoading = useSelector((state?: any) => state?.order?.noteLoading);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: actionData?.note ?? "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("Please Enter Note").max(255),
    }),

    onSubmit: (values: any) => {
      let data = {
        ...values,
        id: actionData?.id,
      };

      dispatch(orderNote(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  return (
    <React.Fragment>
      <Modal centered show={isShow} style={{ display: "block" }} tabIndex={-1}>
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5" className="text-capitalize">
              {actionData?.note ? "Update" : "Add"} Note
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row className="mt-3">
                  <Col lg={12}>
                    <div className="mb-3">
                      <Form.Label htmlFor="note">Note</Form.Label>
                      <textarea
                        className={`form-control ${
                          formik.touched.note && formik.errors.note
                            ? "is-invalid"
                            : ""
                        }`}
                        rows={5}
                        id="note"
                        name="note"
                        placeholder="Enter Your Note"
                        onChange={formik?.handleChange}
                        value={formik?.values?.note || ""}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.note && formik.errors.note ? (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.note}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="submit"
                  className={`btn text-capitalize btn-secondary `}
                  disabled={noteLoading}
                >
                  {noteLoading && <Spinner size="sm" />}{" "}
                  {actionData?.note ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default NoteModal;
