import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  orderInfo: [],
  orderInfoLoading: false,
};
//get market
export const getMarketDetail = createAsyncThunk(
  "get-market-details",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/market`, data);

      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//get order info
export const getOrderInfo = createAsyncThunk(
  "get-orderInfo",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/get-order-info`, data);

      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const marketSlice = createSlice({
  name: "marketSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get market details
    builder.addCase(getMarketDetail.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getMarketDetail.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(getMarketDetail.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    // get order info
    builder.addCase(getOrderInfo.pending, (state: any, action: any) => {
      state.orderInfoLoading = true;
    });
    builder.addCase(getOrderInfo.rejected, (state: any, action: any) => {
      state.orderInfoLoading = false;
      state.orderInfo = [];
      state.error = action.error.message;
    });
    builder.addCase(getOrderInfo.fulfilled, (state: any, action: any) => {
      state.orderInfoLoading = false;
      state.orderInfo = action.payload;
    });
  },
});

export default marketSlice.reducer;
