import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../../Common/DeleteModal";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import {
  cancelOrder,
  fetchOrders,
  getOrders,
} from "../../../slices/orders/orderSlice";
import {
  changeLableArray,
  debounce,
  dropDownStyle,
  formatedDateAndTime,
  getMatched,
} from "../../../helpers/util";
import {
  createGroupOrder,
  removeGroupOrder,
} from "../../../slices/orders/groupOrderSlice";
import PriceModal from "./PriceModal";
import {
  getMarketDetail,
  getOrderInfo,
} from "../../../slices/market/MarketSlice";
import GroupSellModal from "../GroupOrder/GroupSellModal";
import Loader from "../../../Common/Loader/Loader";
import NoteModal from "./NoteModal";

interface OrderProps {
  dropDownList?: any;
  selectedOption?: any;
  setGroupList?: any;
  groupSelect?: any;
  setSelectedOption?: any;
  setGroupSelect?: any;
}
const OrderTable = ({
  dropDownList,
  selectedOption,
  setGroupList,
  groupSelect,
  setSelectedOption,
  setGroupSelect,
}: OrderProps) => {
  const dispatch = useDispatch();

  const orderList = useSelector((state: any) => state?.order?.data ?? []);
  const orderListLoading = useSelector(
    (state: any) => state?.order?.loading ?? false
  );

  const createGroupOrderLoading = useSelector(
    (state: any) => state?.groupOrder?.createGroupOrderLoading
  );
  const removeGroupOrderLoading = useSelector(
    (state: any) => state?.groupOrder?.removeGroupOrderLoading
  );
  const orderFetchLoading = useSelector(
    (state: any) => state?.order?.fetchOrderLoading
  );
  const orderLoading = useSelector((state: any) => state?.order?.loading);
  const orderCancelLoading = useSelector(
    (state: any) => state?.order?.cancelOrderLoading
  );
  const noteLoading = useSelector((state: any) => state?.order?.noteLoading);

  const [order, setOrder] = useState<any>([]);
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 50,
  });
  const [columnFilter, setColumnFilter] = useState<any>({
    index: 15,
    order: true,
    name: "updated_at",
  });
  const [isShow, setIsShow] = useState(false);
  const [noteShow, setNoteShow] = useState(false);
  const [sellModal, setSellModal] = useState(false);
  const [actionData, setActionData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  let tabData = selectedOption?.value ?? "all";
  let groupData = groupSelect === "All Group" ? "all" : groupSelect?.id;
  let data =
    dropDownList === "active_order"
      ? "active"
      : dropDownList === "complete_order"
      ? "completed"
      : "";
  let message = `No ${data ?? ""} Orders Found`;
  let orderData = {
    page: selected?.page,
    perPage: selected?.perPage,
    search: selected?.search,
    sort: columnFilter?.name,
    direction: columnFilter?.order === true ? "desc" : "asc",
    group_data: tabData ?? "",
    group: groupData ?? "",
    filter: data,
  };
  const getOrdersDetail = () => {
    dispatch(getOrders(orderData)).then((res: any) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    getOrdersDetail();
  }, [
    selected,
    orderFetchLoading,
    createGroupOrderLoading,
    removeGroupOrderLoading,
    columnFilter,
    orderCancelLoading,
    selectedOption,
    groupSelect,
    noteLoading,
  ]);

  useEffect(() => {
    setOrder(orderList?.data);
    if (orderList?.data) {
      let updatedPairs = [
        { label: "All Group", value: "All Group" },
        ...orderList?.order_groups,
      ];
      setGroupList(updatedPairs);
    }
  }, [orderList]);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const [removeId, setremoveId] = useState<boolean>(false);

  const handleDeleteModal = useCallback(
    (data: any) => {
      if (delet === true) {
        setDeletid("");
      } else {
        setDeletid(data);
      }
      setDelet(!delet);
    },
    [delet]
  );
  const handleDeleteId = () => {
    dispatch(cancelOrder({ id: deletid }));
    setDelet(false);
  };
  const hideUserModal = () => {
    setIsShow(!isShow);
  };
  // note modal
  const hideNoteModal = (value?: any) => {
    if (noteShow === true) {
      setActionData("");
    } else {
      setActionData(value);
    }
    setNoteShow(!noteShow);
  };

  const handleRemoveModal = useCallback(
    (data: any) => {
      if (removeId === true) {
        setDeletid("");
      } else {
        setDeletid(data);
      }
      setremoveId(!removeId);
    },
    [removeId]
  );

  const handleRemoveId = () => {
    dispatch(removeGroupOrder({ id: deletid })).then((res: any) => {
      setremoveId(false);
    });
  };

  const handleDebounceSearch = debounce((value: string) => {
    setSelected({ ...selected, search: value });
  }, 500);
  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;
    handleDebounceSearch(item);
  };

  const addGroup = (value: string) => {
    dispatch(createGroupOrder({ id: value }));
  };

  const changeFilter = (value: any) => {
    let addGroupData = {
      group_id: value?.group_id,
      id: value?.main_id,
    };

    dispatch(createGroupOrder(addGroupData));
  };

  const getMarketDetails = (value: any) => {
    dispatch(getMarketDetail({ market_name: value?.market })).then(
      (res: any) => {
        if (res?.payload?.type === "success") {
          let data = {
            base_currency_short_name:
              res?.payload?.data?.base_currency_short_name,
            target_currency_short_name:
              res?.payload?.data?.target_currency_short_name,
            base_currency_precision:
              res?.payload?.data?.base_currency_precision,
            target_currency_precision:
              res?.payload?.data?.target_currency_precision,
            coindcx_name: res?.payload?.data?.coindcx_name,
          };
          setActionData({
            ...value,
            type: "order",
            coindcx_name: res?.payload?.data?.coindcx_name,
          });

          dispatch(getOrderInfo(data));
        }
      }
    );
  };
  //order Sell modal
  const showSellModal = (value?: any) => {
    !sellModal && getMarketDetails(value);
    setSellModal(!sellModal);
    if (sellModal === true) {
      setActionData("");
    }
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "MARKET",
        accessor: "market",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return (
            <div className="d-flex gap-1">
              <img
                src={`${getMatched(cell.row.original.trade_currency)}`}
                alt=""
                className="avatar-xxs rounded-circle me-2"
              />

              <div className="text-body align-middle fw-medium ">
                {cell.row.original.market}
              </div>
            </div>
          );
        },
      },

      {
        Header: "CURRENCY",
        accessor: "currency",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle fw-medium d-flex gap-2 ">
                {cell.row.original.currency}{" "}
                {cell.row.original?.note && (
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={
                      <Popover className="border border-primary">
                        <Popover.Header
                          as="h3"
                          className="border-b border-primary"
                        >
                          Note
                        </Popover.Header>
                        <Popover.Body className="">
                          {cell.row.original?.note ?? "No Note Found"}
                        </Popover.Body>
                      </Popover>
                    }
                    rootClose={true} // Close the popover when clicking outside
                  >
                    <div className="mt-1">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={<Tooltip>Click to view note</Tooltip>}
                        rootClose={true}
                      >
                        <span>
                          <i className="ri-information-fill ri-lg text-info cursor-pointer">
                            {" "}
                          </i>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
      },

      {
        Header: "TRADE CURRENCY",
        accessor: "trade_currency",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER TYPE",
        accessor: "order_type",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          const orderType = cell.row.original.order_type?.split("_").join(" ");
          return orderType;
        },
      },
      {
        Header: "TOTAL QTY",
        accessor: "total_quantity",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "REMAINING QTY",
        accessor: "remaining_quantity",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "FILLED QTY",
        accessor: "filled_quantity",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "ORDERED PRICE",
        accessor: "price_per_unit",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "AVRAGE PRICE",
        accessor: "avg_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER PLACE PRICE",
        accessor: "order_placed_price",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "ORDER EXECUTED PRICE",
        accessor: "order_executed_price",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "SIDE",
        accessor: "side",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.side) {
            case "buy":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.side}
                </span>
              );
            case "sell":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.side}
                </span>
              );
            default:
              return cell.row.original.side;
          }
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "filled":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.status}
                </span>
              );
            case "partially_filled":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status?.split("_")?.join(" ")}
                  {}
                </span>
              );
            case "open":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "cancelled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            case "rejected":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },

      {
        Header: "GROUP NAME",
        accessor: "group_name",
        Filter: false,
        isSortable: false,
      },
      {
        Header: "CREATED AT",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.created_at);
        },
      },
      {
        Header: "UPDATED AT",
        accessor: "updated_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.updated_at);
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <div>
            {!["cancelled", "rejected"]?.includes(cell.row.original.status) && (
              <ul className="list-inline hstack  m-0">
                {!cell.row.original.group_id ? (
                  <li className="list-inline-item">
                    <OverlayTrigger
                      trigger={["click", "hover"]}
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-bottom"> Create Group </Tooltip>
                      }
                      rootClose={true}
                    >
                      <button
                        className="btn btn-soft-primary btn-sm d-inline-block"
                        type="button"
                        onClick={() => {
                          addGroup(cell.row.original.id);
                        }}
                      >
                        <i className="las la-plus me-1 la-lg"></i>
                      </button>
                    </OverlayTrigger>
                  </li>
                ) : (
                  <li className="list-inline-item">
                    <OverlayTrigger
                      trigger={["click", "hover"]}
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-bottom">
                          {" "}
                          Leave From Group{" "}
                        </Tooltip>
                      }
                      rootClose={true}
                    >
                      <button
                        className="btn btn-soft-danger btn-sm d-inline-block"
                        type="button"
                        onClick={() => {
                          handleRemoveModal(cell.row.original.id);
                        }}
                      >
                        <i className="ri-user-unfollow-fill me-1 align-middle ri-lg"></i>
                      </button>
                    </OverlayTrigger>
                  </li>
                )}

                {!["cancelled", "rejected", "filled"]?.includes(
                  cell.row.original.status
                ) && (
                  <>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom"> Cancel Order </Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-danger btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            handleDeleteModal(cell.row.original.id);
                          }}
                        >
                          <i className="ri-close-circle-fill me-1 align-middle  ri-lg"></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom"> Edit Price </Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-secondary btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            hideUserModal();
                            setActionData(cell.row.original);
                          }}
                        >
                          <i className="ri-edit-2-fill me-1 align-middle  ri-lg"></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </>
                )}

                {cell.row.original.available_groups?.length > 0 && (
                  <li
                    className="list-inline-item "
                    style={{ minWidth: "160px", maxWidth: "160px" }}
                  >
                    <Select
                      placeholder={"Select Group"}
                      onChange={changeFilter}
                      options={changeLableArray(
                        cell.row.original.available_groups,
                        cell.row.original?.id
                      )}
                      styles={dropDownStyle()}
                    />
                  </li>
                )}

                <li className="list-inline-item">
                  <OverlayTrigger
                    trigger={["click", "hover"]}
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">
                        {cell.row.original?.note ? "Update note" : "Add note"}{" "}
                      </Tooltip>
                    }
                    rootClose={true}
                  >
                    <button
                      className="btn btn-soft-secondary btn-sm d-inline-block py-1"
                      type="button"
                      onClick={() => {
                        hideNoteModal({
                          id: cell.row.original?.id,
                          note: cell.row.original?.note,
                        });
                      }}
                    >
                      <i
                        className={`${
                          cell.row.original?.note
                            ? "ri-file-edit-fill"
                            : "ri-file-add-fill"
                        } align-middle ri-xl`}
                      ></i>
                    </button>
                  </OverlayTrigger>
                </li>
                {cell.row.original.side === "buy" &&
                  cell.row.original.available_to_sell_quantity > 0 && (
                    <li className="list-inline-item">
                      <button
                        className="btn btn-soft-danger fw-bold btn-sm d-inline-block"
                        type="button"
                        onClick={() => {
                          showSellModal(cell.row.original);
                        }}
                      >
                        Sell
                      </button>
                    </li>
                  )}
              </ul>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const columnChnageFilter = (value: any) => {
    let invalidAccessor = [
      "filled_quantity",
      "order_price",
      "order_place_price",
      "group_name",
      "order_executed_price",
    ];
    let data = columns[value?.index].accessor;
    setColumnFilter({
      ...columnFilter,
      index: value?.index,
      order: value?.order,
      name: !invalidAccessor?.includes(data) ? data : "",
    });
  };

  const handleFetchHistory = () => {
    dispatch(fetchOrders({}));
  };

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4} className="d-flex gap-2">
          <button
            className="btn btn-primary addPayment-modal"
            onClick={() => {
              handleFetchHistory();
            }}
            disabled={orderFetchLoading}
          >
            {orderFetchLoading && <Spinner size="sm" />} Fetch Orders
          </button>

          <button
            className="btn btn-primary addPayment-modal"
            onClick={() => {
              getOrdersDetail();
            }}
            disabled={orderLoading}
          >
            {orderLoading && <Spinner size="sm" />}
            Refresh Orders
          </button>
        </Col>
        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                id="searchOrderList"
                placeholder="Search for Result"
                onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div>
                {orderListLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    {order && order.length > 0 ? (
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={order || []}
                        customPageSize={selected?.perPage}
                        divClassName="table-card table-responsive"
                        tableClass="table-hover table-nowrap align-middle mb-0"
                        isBordered={false}
                        PaginationClass="align-items-center mt-4 gy-3"
                        selected={selected}
                        setSelected={setSelected}
                        totalPage={orderList?.totalPages}
                        columnFilter={columnFilter}
                        setColumnFilter={setColumnFilter}
                        columnChnageFilter={columnChnageFilter}
                      />
                    ) : (
                      <NoSearchResult
                        title={` ${orderList?.message ?? message}`}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <DeleteModal
        title={{ text: "cancel order", btnText: "cancel order" }}
        show={delet}
        handleClose={handleDeleteModal}
        deleteModalFunction={handleDeleteId}
      />
      <DeleteModal
        title={{ text: "left group", btnText: "left group" }}
        show={removeId}
        handleClose={handleRemoveModal}
        deleteModalFunction={handleRemoveId}
      />
      <PriceModal
        isShow={isShow}
        handleClose={hideUserModal}
        actionData={actionData}
      />
      <GroupSellModal
        isShow={sellModal}
        handleClose={showSellModal}
        actionData={actionData}
      />
      <NoteModal
        isShow={noteShow}
        handleClose={hideNoteModal}
        actionData={actionData}
      />
      {/* {noteText && <NoteModal isShow={noteText} />} */}
    </React.Fragment>
  );
};

export default OrderTable;
