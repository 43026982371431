import { Button, Modal } from "react-bootstrap";
interface userProps {
  isShow: boolean;
  handleClose: any;
  actionData: any;
}
export const ImportCsvModal = ({
  isShow,
  handleClose,
  actionData,
}: userProps) => {

  return (
    <div>
      <Modal
        centered
        show={isShow}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <Modal.Header className="pb-0">
          <Modal.Title as="h5" className="text-capitalize ">
            <p className="text-center">Imported File Detail</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className=" ">
            <div className="d-flex justify-content-between">
              <div>
                <p className="fs-18">Total Orders</p>
                <p className="fs-18">Total Cancelled and Rejected Orders</p>
                <p className="fs-18">Valid Orders</p>
                <p className="fs-18">Group Created</p>
                <p className="fs-18">TDS Updated Orders </p>
              </div>
              <div>
                <p className="fs-18">{actionData?.total_orders ?? 0}</p>
                <p className="fs-18">
                  {actionData?.cancelled_and_rejected_orders ?? 0}
                </p>
                <p className="fs-18">{actionData?.valid_orders ?? 0}</p>
                <p className="fs-18">{actionData?.group_created ?? 0}</p>
                <p className="fs-18">{actionData?.tds_updated_orders ?? 0}</p>
              </div>
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="secondary" onClick={() => handleClose()}>
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
