import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button } from "react-bootstrap";
import { DefaultColumnFilter } from "./Filter";

import { ProductsGlobalFilter } from "../GlobalSearchFilter";
import { Link } from "react-router-dom";

interface GlobalFilterProps {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  SearchPlaceholder?: string;
  isProductsFilter?: boolean;
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  SearchPlaceholder,
  isProductsFilter,
}: GlobalFilterProps) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value: any) => {
    setGlobalFilter(value || undefined);
  };

  return (
    <React.Fragment>
      {isProductsFilter && <ProductsGlobalFilter />}
      <Col className="col-sm">
        <div className="d-flex justify-content-sm-end">
          <label htmlFor="search-bar-0" className="search-label d-flex">
            <i
              className="bx bx-search-alt search-icon"
              style={{ lineHeight: "55px" }}
            ></i>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={SearchPlaceholder}
              value={value || ""}
            />
          </label>
        </div>
      </Col>
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isAddOptions?: any;
  divClassName?: any;
  tableClassName?: any;
  theadClassName?: any;
  tableClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  isAddUserList?: any;
  handleOrderClicks?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  className?: any;
  customPageSizeOptions?: any;
  iscustomPageSize?: boolean;
  SearchPlaceholder?: string;
  isProductsFilter?: boolean;
  isPagination?: boolean;
  PaginationClass?: any;
  selectedPage?: any;
  setSelectedPage?: any;
  perPage?: any;
  setPerPage?: any;
  selected?: any;
  setSelected?: any;
  totalPage?: number;
  columnFilter?: any;
  setColumnFilter?: any;
  columnChnageFilter?: any;
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isGlobalFilter,
  isProductsFilter,
  isPagination,
  customPageSize,
  iscustomPageSize,
  customPageSizeOptions,
  SearchPlaceholder,
  PaginationClass,
  selectedPage,
  setSelectedPage,
  perPage,
  setPerPage,
  selected,
  setSelected,
  totalPage,
  columnFilter,
  setColumnFilter,
  columnChnageFilter = () => {},
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize ? customPageSize : data?.length,
        // sortBy: [
        //   {
        //     id: "updated_at",
        //     desc: true,
        //   },
        // ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column: any, columndata: any) => {
    return columnFilter?.name !== "action" && column === columnFilter?.index ? (
      columnFilter?.order === true ? (
        <span>&#9650;</span>
      ) : (
        <span>&#9660;</span>
      )
    ) : (
      ""
    );
  };
  const columnSortingIndicator = (column: any) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#9650;</span>
      ) : (
        <span>&#9660;</span>
      )
    ) : (
      ""
    );
  };
  const handleColumn = (value: any) => {
    let toggle = columnFilter?.index === value && !columnFilter?.order;
    columnChnageFilter({ index: value, order: toggle });
  };
  const total = totalPage ?? "";
  const onChangeInSelect = (event: any) => {
    setSelected({ ...selected, perPage: Number(event.target.value) });
    setPageSize(
      Number(selected?.perPage ? selected?.perPage : event?.target?.value)
    );
  };
  const handleNextPage = () => {
    selected?.page < total &&
      setSelected({ ...selected, page: selected?.page + 1 });
  };
  const handlePreviousPage = () => {
    selected?.page > 1 &&
      setSelected({ ...selected, page: selected?.page - 1 });
  };
  const handleFirstPage = () => {
    setSelected({ ...selected, page: 1 });
  };
  const handleLastPage = () => {
    setSelected({ ...selected, page: total });
  };

  return (
    <Fragment>
      {(iscustomPageSize || isGlobalFilter) && (
        <Row className="mb-2">
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              SearchPlaceholder={SearchPlaceholder}
              isProductsFilter={isProductsFilter}
            />
          )}
        </Row>
      )}

      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table
          {...getTableProps()}
          className={tableClass}
          bordered={isBordered}
        >
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any, headerIndex: number) => (
              <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (columns: any, columnIndex: number) => (
                    <th
                      key={columnIndex}
                      className={`${
                        columns.isSortable && columnFilter
                          ? "cursor-pointer"
                          : ""
                      } `}
                      onClick={() => {
                        if (columns.isSortable) {
                          handleColumn(columnIndex);
                        }
                      }}
                      {...columns.getHeaderProps()}
                      style={{
                        border: `${columns.style && "1px solid black"}`,
                        textAlign: columns.style && columns.style.textAlign,
                      }}
                    >
                      {columns.isSortable && (
                        <>
                          {!columnFilter && columnSortingIndicator(columns)}
                          {generateSortingIndicator(columnIndex, columnFilter)}
                          <span {...columns.getSortByToggleProps()}>
                            {columns.render("Header")}
                          </span>
                        </>
                      )}
                      {!columns.isSortable && (
                        <span>{columns.render("Header")}</span>
                      )}
                    </th>
                  )
                )}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {isPagination ? (
        <div>
          {selected?.page ? (
            <Row className={PaginationClass} id="pagination-element">
              <Col md={2}>
                <div className="text-muted">
                  Showing
                  <span className="fw-semibold ms-1">
                    {selected?.page}
                  </span> of <span className="fw-semibold">{totalPage}</span>{" "}
                  Results
                </div>
              </Col>
              <Col md={2}>
                <div className="">
                  <select
                    className="form-select"
                    value={selected?.perPage}
                    onChange={onChangeInSelect}
                  >
                    {[50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map(
                      (pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </Col>

              <div className="col-sm-auto ms-auto">
                <ul className="pagination mb-0 d-flex gap-2 ">
                  <li
                    className={
                      selected?.page === 1 ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handleFirstPage();
                      }}
                    >
                      First Page
                    </Link>
                  </li>
                  <li
                    className={
                      selected?.page === 1 ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handlePreviousPage();
                      }}
                    >
                      <i className="ri-arrow-left-s-line me-1 align-middle ri-xl"></i>
                    </Link>
                  </li>

                  <li
                    className={
                      selected?.page === total
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-next"
                      onClick={() => {
                        handleNextPage();
                      }}
                    >
                      <i className=" ri-arrow-right-s-line me-1 align-middle ri-xl"></i>
                    </Link>
                  </li>
                  <li
                    className={
                      selected?.page === total
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handleLastPage();
                      }}
                    >
                      Last Page
                    </Link>
                  </li>
                </ul>
              </div>
            </Row>
          ) : (
            <Row className="align-items-center mt-2 py-2 px-2 gy-2 text-center text-sm-start">
              <div className="col-sm">
                <div className="text-muted">
                  Showing <span className="fw-semibold">{pageIndex + 1}</span>{" "}
                  of <span className="fw-semibold">{pageOptions.length}</span>{" "}
                  Results
                </div>
              </div>
              <div className="col-sm-auto">
                <ul className="pagination pagination-separated mb-0 justify-content-center justify-content-sm-start">
                  <li
                    className={
                      !canPreviousPage ? "page-item disabled" : "page-item"
                    }
                    onClick={previousPage}
                  >
                    <Button variant="link" className="page-link">
                      &lt;
                    </Button>
                  </li>

                  <li
                    className={
                      !canNextPage ? "page-item disabled" : "page-item"
                    }
                    onClick={nextPage}
                  >
                    <Button variant="link" className="page-link">
                      &gt;
                    </Button>
                  </li>
                </ul>
              </div>
            </Row>
          )}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
