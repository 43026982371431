import React, { useCallback, useMemo, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { formatedDateAndTime } from "../../../helpers/util";
import Loader from "../../../Common/Loader/Loader";
import { cancelOrder } from "../../../slices/orders/orderSlice";
import { useDispatch } from "react-redux";

interface userProps {
  isShow?: boolean;
  handleClose?: any;
  actionData?: any;
  setActionData?: any;
}

const GroupOrderModal = ({
  isShow,
  handleClose,
  actionData,
  setActionData,
}: userProps) => {
  const dispatch = useDispatch();

  const [calcelOrderModal, setCancelOrderModal] = useState<boolean>(false);
  const [calcelOrderId, setCancelOrderId] = useState<any>();

  // cancel order  from group order
  const handleCancelModal = useCallback(
    (id: any) => {
      if (calcelOrderModal === true) {
        setCancelOrderId("");
      } else {
        setCancelOrderId(id);
      }
      setCancelOrderModal(!calcelOrderModal);
    },
    [calcelOrderModal]
  );

  const handleCancelId = () => {
    dispatch(cancelOrder({ id: calcelOrderId })).then((res?: any) => {
      if (res?.payload?.type === "success") {
        setCancelOrderModal(false);
      }
    });
  };
  //   const hideUserModal = () => {
  //     setIsShow(!isShow);
  //   };
  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }
  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "SIDE",
        accessor: "side",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.side) {
            case "buy":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.side}
                </span>
              );
            case "sell":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.side}
                </span>
              );
            default:
              return cell.row.original.side;
          }
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "filled":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.status}
                </span>
              );
            case "partially_filled":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case "open":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "cancelled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            case "rejected":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "TOTAL QTY",
        accessor: "total_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.total_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "REMAINING QTY",
        accessor: "remaining_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.remaining_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "FILLED QTY",
        accessor: "filled_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.filled_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "ORDER PRICE",
        accessor: "price_per_unit",
        Filter: false,
        isSortable: true,
      },

      {
        Header: "AVERAGE PRICE",
        accessor: "avg_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER PLACE PRICE",
        accessor: "order_placed_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER EXECUTED PRICE",
        accessor: "order_executed_price",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.order_executed_price}
              </span>
            </div>
          );
        },
      },
      {
        Header: "ORDER TYPE",
        accessor: "order_type",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return cell.row.original.order_type?.split("_").join(" ");
        },
      },

      {
        Header: "CREATED AT",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return formatedDateAndTime(cell.row.original.created_at);
        },
      },
      {
        Header: "UPDATED AT",
        accessor: "updated_at",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return formatedDateAndTime(cell.row.original.updated_at);
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            {cell.row.original?.status !== "filled" && (
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-bottom"> Edit </Tooltip>}
                  rootClose={true}
                >
                  <button
                    className="btn btn-soft-secondary btn-sm d-inline-block"
                    type="button"
                    // onClick={() => {
                    //   hideUserModal();
                    //   setActionData(cell.row.original);
                    // }}
                  >
                    <i className="ri-edit-2-fill me-1 align-middle  ri-lg"></i>
                  </button>
                </OverlayTrigger>
              </li>
            )}
            <li className="list-inline-item ">
              <OverlayTrigger
                trigger={["click", "hover"]}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-bottom"> Remove from group </Tooltip>
                }
                rootClose={true}
              >
                <button
                  type="button"
                  className="btn btn-soft-danger btn-sm d-inline-block"
                  //   onClick={() => {
                  //     handleDeleteModal(cell.row.original.id);
                  //   }}
                >
                  <i className="ri-user-unfollow-fill me-1 align-middle ri-lg"></i>
                </button>
              </OverlayTrigger>
            </li>
            {!["cancelled", "rejected", "filled"]?.includes(
              cell.row.original.status
            ) && (
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-bottom"> Cancel Order </Tooltip>
                  }
                  rootClose={true}
                >
                  <button
                    className="btn btn-soft-danger btn-sm d-inline-block"
                    type="button"
                    onClick={() => {
                      handleCancelModal(cell.row.original.id);
                    }}
                  >
                    <i className="ri-close-circle-fill me-1 align-middle  ri-lg"></i>
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        ),
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div style={{ width: "80%" }}>
        <Modal
          centered
          show={isShow}
          style={{ display: "block" }}
          tabIndex={-1}
          className="modal-xl"
        >
          <div className="modal-content">
            <Modal.Header className="pb-0">
              <Modal.Title as="h5" className="text-capitalize">
                <p>order</p>
              </Modal.Title>
              <button
                type="button"
                className="btn-close d-flex align-items-start"
                onClick={() => {
                  handleClose();
                }}
                style={{ marginTop: "-20px" }}
              ></button>
            </Modal.Header>
            <Modal.Body className="p-4">
              {actionData?.length > 0 && (
                <div className="mt-4">
                  <TableContainer
                    isPagination={false}
                    columns={columns}
                    data={actionData || []}
                    customPageSize={actionData?.length ?? 50}
                    divClassName="table-card table-responsive"
                    tableClass="table-hover table-nowrap align-middle mb-0"
                    isBordered={false}
                    PaginationClass="align-items-center mt-4 gy-3"
                  />
                </div>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default GroupOrderModal;
