import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Card, Col, Container, Row } from "react-bootstrap";
import GraphData from "./graphData";
import moment from "moment";
import { useDispatch } from "react-redux";
import { profitLossReport } from "../../../slices/Reports/ReportSlice";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMinusPlus } from "../../../helpers/util";
import MultiHeadTable from "../../../Common/Tabledata/MultiHeadTable";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import ViewReportModal from "./viewReportModal";

const DailyReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [graphTable, setGraphtable] = useState<any>();
  const [viewModal, setViewModal] = useState(false);
  const [actionData, setActionData] = useState<any>();

  const dispatch = useDispatch();
  const profitLossData = useSelector(
    (state?: any) => state?.report?.data ?? []
  );

  const getMonthYear = (event?: any) => {
    setStartDate(event);
  };
  useEffect(() => {
    const parsedDate = moment(startDate, "ddd MMM DD YYYY HH:mm:ss");
    const month = parsedDate.month() + 1;
    const year = parsedDate.year();

    dispatch(profitLossReport({ month: month, year: year }));
  }, [startDate]);
  useEffect(() => {
    setGraphtable(profitLossData?.data?.graph_data);
  }, [profitLossData?.data?.graph_data, startDate]);
  const minDate = moment("03-2024", "MM-YYYY").toDate();
  const maxDate = new Date(); // Today's date

  //order Sell modal
  const showViewModal = (value?: any) => {
    if (viewModal === true) {
      setActionData("");
    } else {
      setActionData(value);
    }
    setViewModal(!viewModal);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell?: any) => any;
  }
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      isSortable: true,
      rowSpan: 2,
    },

    {
      Header: "Buy",
      colSpan: 2,
      style: {
        textAlign: "center",
      },

      columns: [
        {
          Header: "Trade Placed",
          accessor: "buy_trade_placed",
          background: true,
          isSortable: true,
        },
        {
          Header: "Trade Executed",
          accessor: "buy_trade_executed",
          background: true,
          isSortable: true,
        },
      ],
    },
    {
      Header: "Sell",
      isSortable: false,
      colSpan: 2,
      style: {
        textAlign: "center",
      },
      columns: [
        {
          Header: "Trade Placed",
          accessor: "sell_trade_placed",
          background: true,
          isSortable: true,
        },
        {
          Header: "Trade Executed",
          accessor: "sell_trade_executed",
          background: true,
          isSortable: true,
        },
      ],
    },
    {
      Header: "Profit/Loss",
      accessor: "profilt_loss",
      isSortable: true,
      rowSpan: 2,

      Cell: (cell: any) => {
        return (
          <div>
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.profilt_loss ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.profilt_loss}
            </span>
          </div>
        );
      },
    },
    {
      Header: "View Sell Order",
      rowSpan: 2,
      accessor: "action",
      Filter: false,
      isSortable: false,
      Cell: (cell?: any) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <>
              {cell.row.original?.sell_orders?.length > 0 && (
                <li className="list-inline-item">
                  <button
                    className="btn btn-soft-secondary btn-sm d-inline-block"
                    type="button"
                    title="View Order"
                    onClick={() => {
                      showViewModal(cell.row.original?.sell_orders);
                    }}
                  >
                    <i className="las la-eye  align-middle  la-lg "></i>
                  </button>
                </li>
              )}
            </>
          </ul>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Reports" title="Daily Report " />
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className="border-0 align-items-center d-flex gap-2">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Profit / Loss Activity
                  </h4>
                  <p className="mb-0 text-muted me-2">
                    Total Profit / Loss{" "}
                    <small
                      className={`badge ${
                        getMinusPlus(
                          profitLossData?.data?.total_profit_loss ?? 0
                        ) === false
                          ? "bg-danger-subtle text-danger"
                          : "bg-primary-subtle text-primary"
                      }   fs-14 mb-1 mx-2`}
                    >
                      {profitLossData?.data?.total_profit_loss ?? 0}
                    </small>
                  </p>

                  <DatePicker
                    selected={startDate}
                    // minDate={minDate}
                    maxDate={maxDate}
                    showMonthYearPicker
                    dateFormat="MMMM,yyyy"
                    onChange={(date?: any) => getMonthYear(date)}
                    className="shadow shadow-lg shadow-primary border border-primary"
                  />
                </Card.Header>
                <Card.Body className="py-2">
                  {graphTable?.length > 0 && (
                    <GraphData
                      className="apex-charts"
                      dataColors='["--in-primary", "--in-danger"]'
                      graphTable={graphTable}
                      startDate={startDate}
                    />
                  )}
                  <div className="mt-4">
                    {graphTable?.length > 0 && (
                      <p className="fw-bold">Graph Table</p>
                    )}
                    <div className="p-3">
                      {graphTable?.length > 0 ? (
                        <MultiHeadTable
                          isPagination={false}
                          columns={columns}
                          data={graphTable || []}
                          customPageSize={30}
                          divClassName="table-card table-responsive"
                          tableClass="table-hover table-nowrap align-middle mb-0 border table-striped"
                          isBordered={false}
                          PaginationClass="align-items-center mt-4 gy-3"
                        />
                      ) : (
                        <NoSearchResult
                          title={`${
                            profitLossData?.message ?? "No Graph Data Found"
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ViewReportModal
            isShow={viewModal}
            handleClose={showViewModal}
            actionData={actionData}
            setActionDate={setActionData}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DailyReport;
