import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TestSmtpModal from "./TestSmtp";
import { createListOfObject } from "../../helpers/util";
import {
  getSetting,
  upDateSetting,
} from "../../slices/settingSlice/SettingSlice";

const SmtpSetting = () => {
  document.title = "SMTP Setting | Crypto-Trade-App";

  const dispatch: any = useDispatch();
  const smtpSettingList = useSelector((state: any) => state?.getSetting?.data);
  const updateLoading = useSelector(
    (state: any) => state?.getSetting?.updateLoading
  );

  const [isShow, setIsShow] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

  const hideUserModal = () => {
    setIsShow(!isShow);
  };

  // convert array of object to object of object
  const object = smtpSettingList?.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [item.key]: item.value,
    }),
    {}
  );

  useEffect(() => {
    dispatch(getSetting({}));
  }, [updateLoading]);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      mail_from_name: object?.mail_from_name ? object?.mail_from_name : "",
      mail_from_address: object?.mail_from_address
        ? object?.mail_from_address
        : "",
      mail_mailers_smtp_host: object?.mail_mailers_smtp_host
        ? object?.mail_mailers_smtp_host
        : "",
      mail_mailers_smtp_username: object?.mail_mailers_smtp_username
        ? object?.mail_mailers_smtp_username
        : "",
      mail_mailers_smtp_password: object?.mail_mailers_smtp_password
        ? object?.mail_mailers_smtp_password
        : "",
      mail_mailers_smtp_port: object?.mail_mailers_smtp_port
        ? object?.mail_mailers_smtp_port
        : "",
      mail_mailers_smtp_encryption: object?.mail_mailers_smtp_encryption
        ? object?.mail_mailers_smtp_encryption
        : "",
    },
    validationSchema: Yup.object({
      mail_from_name: Yup.string().required("Please Enter SMTP from Name"),
      mail_from_address: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter SMTP from Email"),
      mail_mailers_smtp_host: Yup.string().required("Please Enter SMTP Host"),
      mail_mailers_smtp_username: Yup.string().required(
        "Please Enter SMTP Username"
      ),
      mail_mailers_smtp_password: Yup.string().required(
        "Please Enter SMTP Password"
      ),
      mail_mailers_smtp_port: Yup.string().required("Please Enter SMTP Port"),
      mail_mailers_smtp_encryption: Yup.string().required(
        "Please Enter SMTP Secure"
      ),
    }),
    onSubmit: (values: any) => {
      const list = createListOfObject(values);
      dispatch(upDateSetting(list));
    },
  });
  //check if any formik value is blank or not
  const keysWithBlankValues = Object.keys(validation?.values).filter(
    (key) => !validation?.values[key].trim()
  );

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Setting" title="SMTP Setting" />
          <Row>
            <Col xl={12}>
              <div className="card">
                <div className="card-body">
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="mt-3">
                        <Col lg={6}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_from_name">
                              SMTP From Name
                            </Form.Label>
                            <Form.Control
                              id="mail_from_name"
                              autoComplete="off"
                              name="mail_from_name"
                              placeholder="Enter SMTP From Name"
                              type="text"
                              value={validation.values.mail_from_name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              isInvalid={
                                validation.errors.mail_from_name &&
                                validation.touched.mail_from_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mail_from_name &&
                            validation.errors.mail_from_name ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_from_name}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_from_address">
                              SMTP From Email
                            </Form.Label>
                            <Form.Control
                              id="mail_from_address"
                              name="mail_from_address"
                              autoComplete="off"
                              placeholder="Enter SMTP From Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation?.values?.mail_from_address || ""
                              }
                              isInvalid={
                                validation.errors.mail_from_address &&
                                validation.touched.mail_from_address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mail_from_address &&
                            validation.errors.mail_from_address ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_from_address}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Form.Label
                              htmlFor="mail_mailers_smtp_host"
                              className="form-label"
                            >
                              SMTP Host
                            </Form.Label>
                            <Form.Control
                              id="mail_mailers_smtp_host"
                              name="mail_mailers_smtp_host"
                              placeholder="Enter SMTP Host"
                              autoComplete="off"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation?.values?.mail_mailers_smtp_host}
                              isInvalid={
                                validation.errors.mail_mailers_smtp_host &&
                                validation.touched.mail_mailers_smtp_host
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mail_mailers_smtp_host &&
                            validation.errors.mail_mailers_smtp_host ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_mailers_smtp_host}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_mailers_smtp_username">
                              SMTP Username
                            </Form.Label>
                            <Form.Control
                              id="mail_mailers_smtp_username"
                              name="mail_mailers_smtp_username"
                              placeholder="Enter SMTP Username"
                              autoComplete="off"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation?.values?.mail_mailers_smtp_username
                              }
                              isInvalid={
                                validation.errors.mail_mailers_smtp_username &&
                                validation.touched.mail_mailers_smtp_username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mail_mailers_smtp_username &&
                            validation.errors.mail_mailers_smtp_username ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_mailers_smtp_username}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={4}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_mailers_smtp_password">
                              SMTP Password
                            </Form.Label>
                            <div className="mb-3 position-relative">
                              <Form.Control
                                id="mail_mailers_smtp_password"
                                name="mail_mailers_smtp_password"
                                placeholder="Enter SMTP Password"
                                autoComplete="off"
                                type={showSecretKey ? "text" : "password"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation?.values?.mail_mailers_smtp_password
                                }
                                isInvalid={
                                  !!validation.errors.mail_mailers_smtp_password
                                }
                              />

                              {validation.touched.mail_mailers_smtp_password &&
                              validation.errors.mail_mailers_smtp_password ? (
                                <Form.Control.Feedback type="invalid">
                                  {validation.errors.mail_mailers_smtp_password}
                                </Form.Control.Feedback>
                              ) : null}
                              <button
                                className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                                type="button"
                                id="password-addon"
                                onClick={() => setShowSecretKey(!showSecretKey)}
                                style={{
                                  top: "0px",
                                  right: "15px",
                                }}
                              >
                                <i
                                  className={`${
                                    !showSecretKey
                                      ? `ri-eye-fill`
                                      : `ri-eye-off-line`
                                  }  align-middle`}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_mailers_smtp_port">
                              SMTP Port
                            </Form.Label>
                            <Form.Control
                              id="mail_mailers_smtp_port"
                              name="mail_mailers_smtp_port"
                              placeholder="Enter SMTP Port"
                              autoComplete="off"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation?.values?.mail_mailers_smtp_port}
                              isInvalid={
                                validation.errors.mail_mailers_smtp_port &&
                                validation.touched.mail_mailers_smtp_port
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mail_mailers_smtp_port &&
                            validation.errors.mail_mailers_smtp_port ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_mailers_smtp_port}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Form.Label htmlFor="mail_mailers_smtp_encryption">
                              SMTP Secure
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              data-trigger
                              name="mail_mailers_smtp_encryption"
                              id="mail_mailers_smtp_encryption"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation?.values?.mail_mailers_smtp_encryption
                              }
                              isInvalid={
                                validation.errors
                                  .mail_mailers_smtp_encryption &&
                                validation.touched.mail_mailers_smtp_encryption
                                  ? true
                                  : false
                              }
                            >
                              <option value="SSL">SSL</option>
                              <option value="TLS">TLS</option>
                            </Form.Select>
                            {validation.touched.mail_mailers_smtp_encryption &&
                            validation.errors.mail_mailers_smtp_encryption ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.mail_mailers_smtp_encryption}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="hstack gap-4  mt-4">
                        <div className=" hstack gap-2">
                          <button
                            type="submit"
                            className={`btn  ${
                              keysWithBlankValues?.length
                                ? "btn-soft-primary"
                                : "btn-primary"
                            }`}
                            disabled={updateLoading}
                          >
                            {updateLoading && <Spinner size="sm" />} Save
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={hideUserModal}
                            className={`btn btn-secondary `}
                          >
                            Test SMTP Mail Setting
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <TestSmtpModal isShow={isShow} handleClose={hideUserModal} />
      </div>
    </div>
  );
};

export default SmtpSetting;
