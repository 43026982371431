import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  profileUserData: [],
  profileUserLoading: false,
  error: null,
  deleteUserLoading: false,
  updateStatusLoading: false,
  changePasswordLoading: false,
  changeUserNameLoading: false,
};
// add user
export const addUser = createAsyncThunk(
  "add-user",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/users`, data);

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// get total users
export const getUser = createAsyncThunk(
  "get-user",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.get(`api/users`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// change user name
export const changeUserName = createAsyncThunk(
  "change-user-name",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(`api/user/${data?.id}`, data);

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//get profile user
export const profileUser = createAsyncThunk(
  "profile-user",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/user`, data);

      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//delete user
export const deleteUser = createAsyncThunk(
  "delete-user",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.delete(
        `api/users/${data?.id}`,
        null
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// update status
export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/users/status/${data?.id}`,
        data
      );

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// user password
export const changePassword = createAsyncThunk(
  "change-user-password",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(
        `api/user/password/change`,
        data
      );

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get user
    builder.addCase(getUser.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getUser.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(getUser.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    // create user
    builder.addCase(addUser.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(addUser.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(addUser.fulfilled, (state: any, action: any) => {
      state.loading = false;
    });

    // change user name
    builder.addCase(changeUserName.pending, (state: any, action: any) => {
      state.changeUserNameLoading = true;
    });
    builder.addCase(changeUserName.rejected, (state: any, action: any) => {
      state.changeUserNameLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(changeUserName.fulfilled, (state: any, action: any) => {
      state.changeUserNameLoading = false;
    });
    // profile user
    builder.addCase(profileUser.pending, (state: any, action: any) => {
      state.profileUserLoading = true;
    });
    builder.addCase(profileUser.rejected, (state: any, action: any) => {
      state.profileUserLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(profileUser.fulfilled, (state: any, action: any) => {
      state.profileUserLoading = false;
      state.profileUserData = action.payload;
    });

    // delete user
    builder.addCase(deleteUser.pending, (state: any, action: any) => {
      state.deleteUserLoading = true;
    });
    builder.addCase(deleteUser.rejected, (state: any, action: any) => {
      state.deleteUserLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(deleteUser.fulfilled, (state: any, action: any) => {
      state.profileUserLoading = false;
      state.profileUserData = action.payload;
    });

    // update status
    builder.addCase(updateStatus.pending, (state: any, action: any) => {
      state.updateStatusLoading = true;
    });
    builder.addCase(updateStatus.rejected, (state: any, action: any) => {
      state.updateStatusLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(updateStatus.fulfilled, (state: any, action: any) => {
      state.updateStatusLoading = false;
    });

    // change user password
    builder.addCase(changePassword.pending, (state: any, action: any) => {
      state.changePasswordLoading = true;
    });
    builder.addCase(changePassword.rejected, (state: any, action: any) => {
      state.changePasswordLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(changePassword.fulfilled, (state: any, action: any) => {
      state.changePasswordLoading = false;
    });
  },
});

export default userSlice.reducer;
