import React, { ReactNode, useEffect } from "react";

//import actions
import { changeLayout, changeLeftsidebarSizeType } from "../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
// import TopBar from './TopBar';

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import withRouter from "../Common/withRouter";
import { createSelector } from "reselect";

interface LayoutProps {
  children: ReactNode;
}
const Layout = (props: LayoutProps) => {
  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout: any) => ({
      layoutType: layout.layoutType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
    })
  );
  const { layoutType, leftsidbarSizeType } = useSelector(
    selectLayoutProperties
  );

  /*
    layout settings
    */
  useEffect(() => {
    if (layoutType || leftsidbarSizeType) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeLayout(layoutType));
    }
  }, [layoutType, leftsidbarSizeType, dispatch]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Sidebar layoutType={layoutType} />
        <Header />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
