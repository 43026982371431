import { useCallback, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  cancelScheduleOrder,
  getScheduleOrders,
} from "../../slices/scheduleOrder/scheduleOrderSlice";
import { formatedDateAndTime } from "../../helpers/util";
import TableContainer from "../../Common/Tabledata/TableContainer";
import NoSearchResult from "../../Common/Tabledata/NoSearchResult";
import { DeleteModal } from "../../Common/DeleteModal";
import ScheduleEditModal from "./ScheduleEditModal";
import { ReasonModal } from "./ReasonModal";
import { ViewOrderModal } from "./ViewOrderModal";
import { debounce } from "lodash";
import Loader from "../../Common/Loader/Loader";

interface OrderProps {
  dropDownList?: any;
  selectedOption?: any;
}
const ScheduleOrderTable = ({ dropDownList, selectedOption }: OrderProps) => {
  const dispatch = useDispatch();
  const scheduleOrderList = useSelector(
    (state: any) => state?.scheduleOrder?.data ?? []
  );
  const scheduleOrderLoading = useSelector(
    (state: any) => state?.scheduleOrder?.loading ?? false
  );
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 10,
    search: "",
  });

  const [columnFilter, setColumnFilter] = useState<any>({
    index: 0,
    order: false,
    name: "market",
  });
  const editScheduleLoading = useSelector(
    (state: any) => state?.scheduleOrder?.editScheduleLoading
  );
  const cancelScheduleLoading = useSelector(
    (state: any) => state?.scheduleOrder?.cancelScheduleLoading
  );
  const [scheduleOrder, setScheduleOrder] = useState<any>([]);

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const [isShow, setIsShow] = useState(false);
  const [reason, setReason] = useState(false);
  const [viewOrder, setViewOrder] = useState(false);

  const [actionData, setActionData] = useState<any>();
  const [cancelData, setCancelData] = useState<any>();

  const columnChnageFilter = (value: any) => {
    let data = columns[value?.index].accessor;

    setColumnFilter({
      ...columnFilter,
      index: value?.index,
      order: value?.order,
      name: data,
    });
  };
  // Leave from group  modal
  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );
  const handleDeleteId = () => {
    dispatch(cancelScheduleOrder({ id: deletid })).then((res: any) => {
      setDelet(false);
    });
  };
  const hideUserModal = (value?: any) => {
    if (isShow === true) {
      setActionData("");
    } else {
      setActionData(value);
    }
    setIsShow(!isShow);
  };
  const hideReasonModal = (value?: any) => {
    if (reason === true) {
      setCancelData("");
    } else {
      setCancelData(value);
    }
    setReason(!reason);
  };
  const hideViewOrderModal = (value?: any) => {
    if (viewOrder === true) {
      setCancelData("");
    } else {
      setCancelData(value);
    }
    setViewOrder(!viewOrder);
  };
  const handleDebounceSearch = debounce((value: string) => {
    setSelected({ ...selected, search: value });
    // Perform search operation here
  }, 500);
  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;
    handleDebounceSearch(item);
  };
  const options = [
    { label: "All Orders", value: "All Orders" },
    { label: "placed", value: "placed" },
    { label: "rejected", value: "rejected" },
    { label: "cancelled", value: "cancelled" },
  ];

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }
  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "MARKET ",
        accessor: "market",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              {/* <span className="text-dark">{cell.row.original.market}</span> */}
              <div className="text-body align-middle fw-medium d-flex gap-2 ">
                {cell.row.original.market}{" "}
                {cell.row.original?.note && (
                  <OverlayTrigger
                    trigger={["click"]}
                    placement="right"
                    overlay={
                      <Popover className="border border-primary">
                        <Popover.Header
                          as="h3"
                          className="border-b border-primary"
                        >
                          Note
                        </Popover.Header>
                        <Popover.Body className="">
                          {cell.row.original?.note ?? "No Note Found"}
                        </Popover.Body>
                      </Popover>
                    }
                    rootClose={true}
                  >
                    <div className="mt-1">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={<Tooltip>Click to view note</Tooltip>}
                        rootClose={true}
                      >
                        <span>
                          <i className="ri-information-fill ri-lg text-info cursor-pointer">
                            {" "}
                          </i>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "SIDE",
        accessor: "side",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.side) {
            case "buy":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.side}
                </span>
              );
            case "sell":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.side}
                </span>
              );
            default:
              return cell.row.original.side;
          }
        },
      },

      {
        Header: "TRIGGER PRICE",
        accessor: "trigger_price",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.trigger_price}
              </span>
            </div>
          );
        },
      },
      {
        Header: "TOTAL QUANTITY",
        accessor: "total_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.total_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "PRICE PER UNIT",
        accessor: "price_per_unit",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.price_per_unit}
              </span>
            </div>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "scheduled":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.status}
                </span>
              );
            case "placed":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case "in_process":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status?.split("_")?.join(" ")}
                </span>
              );
            case "rejected":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            case "cancelled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );

            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },

      {
        Header: "CREATED AT",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.created_at);
        },
      },

      {
        Header: "UPDATED AT",
        accessor: "updated_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.updated_at);
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "scheduled":
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-bottom"> Edit </Tooltip>}
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-secondary btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            hideUserModal(cell.row.original);
                          }}
                        >
                          <i className="ri-edit-2-fill me-1 align-middle  ri-lg"></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom"> Cancel Order </Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          type="button"
                          className="btn btn-soft-danger btn-sm d-inline-block"
                          onClick={() => {
                            handleDeleteModal(cell.row.original.id);
                          }}
                        >
                          <i className="ri-close-circle-fill  ri-lg align-middle"></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </>
                </ul>
              );
            case "placed":
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">View Order</Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-secondary btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            hideViewOrderModal(cell.row.original);
                          }}
                        >
                          <i className="ri-pages-fill  align-middle  ri-lg"></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </>
                </ul>
              );
            case "rejected":
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">View Reason</Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-secondary btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            let data = {
                              status: cell.row.original?.status,
                              description: cell.row.original?.description,
                            };
                            hideReasonModal(data);
                          }}
                        >
                          <i className="las la-eye  align-middle  la-lg "></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </>
                </ul>
              );
            case "cancelled":
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <>
                    <li className="list-inline-item">
                      <OverlayTrigger
                        trigger={["click", "hover"]}
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">View Reason</Tooltip>
                        }
                        rootClose={true}
                      >
                        <button
                          className="btn btn-soft-secondary btn-sm d-inline-block"
                          type="button"
                          onClick={() => {
                            let data = {
                              status: cell.row.original?.status,
                              description: cell.row.original?.description,
                            };
                            hideReasonModal(data);
                          }}
                        >
                          <i className="las la-eye fs-18 align-middle me-1 "></i>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </>
                </ul>
              );

            default:
              return;
          }
        },
      },
    ],
    []
  );
  let data =
    dropDownList === "active_order"
      ? "active"
      : dropDownList === "completed_order"
      ? "completed"
      : "active";
  useEffect(() => {
    let orderData = {
      page: selected?.page,
      perPage: selected?.perPage,
      search: selected?.search,
      sort: columnFilter?.name,
      direction: columnFilter?.order === true ? "desc" : "asc",
      status: selectedOption?.value ?? "",
      filter: data,
    };
    dispatch(getScheduleOrders(orderData));
  }, [
    cancelScheduleLoading,
    editScheduleLoading,
    selected,
    columnFilter,
    selectedOption,
  ]);
  useEffect(() => {
    setScheduleOrder(scheduleOrderList?.data);
  }, [scheduleOrderList]);

  return (
    <div>
      <Row>
        <div>
          {scheduleOrderLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div>
              {scheduleOrder?.length > 0 ? (
                <div className="mt-3">
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={scheduleOrder || []}
                    customPageSize={selected?.perPage ?? 50}
                    divClassName="table-card table-responsive"
                    tableClass="table-hover table-nowrap align-middle mb-0"
                    isBordered={false}
                    PaginationClass="align-items-center mt-4 gy-3"
                    selected={selected}
                    setSelected={setSelected}
                    totalPage={scheduleOrderList?.totalPages}
                    columnFilter={columnFilter}
                    setColumnFilter={setColumnFilter}
                    columnChnageFilter={columnChnageFilter}
                  />
                </div>
              ) : (
                <NoSearchResult
                  title={`${
                    scheduleOrderList?.message ??
                    ` No ${dropDownList?.split("_").join(" Schedule ")}
              found`
                  }`}
                />
              )}
            </div>
          )}
        </div>

        <DeleteModal
          title={{ text: "cancel schedule order", btnText: "cancel" }}
          show={delet}
          handleClose={handleDeleteModal}
          deleteModalFunction={handleDeleteId}
        />

        <ScheduleEditModal
          isShow={isShow}
          handleClose={hideUserModal}
          actionData={actionData}
        />
        <ReasonModal
          isShow={reason}
          handleClose={hideReasonModal}
          cancelData={cancelData}
        />
        <ViewOrderModal
          isShow={viewOrder}
          handleClose={hideViewOrderModal}
          cancelData={cancelData}
        />
      </Row>
    </div>
  );
};

export default ScheduleOrderTable;
