import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import TableContainer from "../../../Common/Tabledata/TableContainer";

interface userProps {
  isShow?: boolean;
  handleClose?: any;
  actionData?: any;
  setActionDate?: any;
}

const ViewReportModal = ({
  isShow,
  handleClose,
  actionData,
  setActionDate,
}: userProps) => {

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }
  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "SIDE",
        accessor: "side",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.side) {
            case "buy":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.side}
                </span>
              );
            case "sell":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.side}
                </span>
              );
            default:
              return cell.row.original.side;
          }
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "filled":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.status}
                </span>
              );
            case "partially_filled":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case "open":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "cancelled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            case "rejected":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "GROUP NAME",
        accessor: "group_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER TYPE",
        accessor: "order_type",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return cell.row.original.order_type?.split("_").join(" ");
        },
      },

      {
        Header: "TOTAL QTY",
        accessor: "total_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.total_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "REMAINING QTY",
        accessor: "remaining_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.remaining_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "FILLED QTY",
        accessor: "filled_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.filled_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "ORDER PRICE",
        accessor: "price_per_unit",
        Filter: false,
        isSortable: true,
      },

      {
        Header: "AVERAGE PRICE",
        accessor: "avg_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER PLACE PRICE",
        accessor: "order_placed_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER EXECUTED PRICE",
        accessor: "order_executed_price",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.order_executed_price}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div style={{ width: "80%" }}>
        <Modal
          centered
          show={isShow}
          style={{ display: "block" }}
          tabIndex={-1}
          className="modal-xl"
        >
          <div className="modal-content">
            <Modal.Header className="pb-0">
              <Modal.Title as="h5" className="text-capitalize">
                <div>
                  <p>orders</p>
                  <p> Date: {actionData?.[0]?.created_at?.split(" ")?.[0]}</p>
                </div>
              </Modal.Title>
              <button
                type="button"
                className="btn-close d-flex align-items-start"
                onClick={() => {
                  handleClose();
                }}
                style={{ marginTop: "-20px" }}
              ></button>
            </Modal.Header>
            <Modal.Body className="p-4">
              {actionData?.length > 0 && (
                <div className="mt-4">
                  <TableContainer
                    isPagination={false}
                    columns={columns}
                    data={actionData || []}
                    customPageSize={actionData?.length}
                    divClassName="table-card table-responsive"
                    tableClass="table-hover table-nowrap align-middle mb-0"
                    isBordered={false}
                    PaginationClass="align-items-center mt-4 gy-3"
                  />
                </div>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ViewReportModal;
