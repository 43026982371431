import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import SecretKeyTable from "./SecretKeyTable";

const AddSecretKey = () => {
  document.title = "Add Secret Key | Crypto-Trade-App";
  const [isShow, setIsShow] = useState(false);

  const [editData, setEditData] = useState<any>();
  const hideUserModal = () => {
    isShow === true && setEditData("");
    setIsShow(!isShow);
  };

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Setting" title="Secret Key" />
          <Row>
            <Col>
              <div className="gap-2 d-flex mb-2">
                <button className="btn btn-primary" onClick={hideUserModal}>
                  Add Key
                </button>
              </div>
            </Col>
          </Row>
          <SecretKeyTable
            isShow={isShow}
            handleClose={hideUserModal}
            editData={editData}
            setEditData={setEditData}
          />
        </Container>
      </div>
    </div>
  );
};

export default AddSecretKey;
