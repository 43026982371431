import { Slide, toast } from "react-toastify";
export const showSuccessMsg = (msg = "", isAlert = false) => {
    if (isAlert) {
      toast.success(msg, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: 10000,
        closeOnClick: false,
        transition: Slide,
      });
    } else {
      toast.success(msg);
    }
  };
  
  export const showErrorMsg = (msg = "Something went wrong.") => {
    toast.error(msg);
  };