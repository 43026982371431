import React from "react";
import { Col, Container, Row, Card, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "../../Common/withRouter";
import { changeUserName } from "../../slices/user/userSlice";

const UserProfile = () => {
  document.title = "User Profile | Crypto-Trade-App";
  const dispatch: any = useDispatch();
  const userData = useSelector(
    (state?: any) => state?.user?.profileUserData ?? []
  );
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.name ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values: any) => {
      dispatch(changeUserName({ ...values, id: userData?.id }));
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="auth-full-page-content d-flex  py-sm-5 py-4 ">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5 mt-5">
                      <img
                        width="25"
                        height="25"
                        src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                        alt="logo"
                      />
                      <small className="text-muted fs-18">
                        Crypto Trade App
                      </small>
                    </div>

                    <Row>
                      <Col lg="12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex">
                              <div className="mx-3">
                                <img
                                  src="https://img.icons8.com/officel/16/user.png"
                                  alt="user"
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              </div>
                              <div className="flex-grow-1 align-self-center">
                                <div className="text-muted">
                                  <h5>{userData?.name || ""}</h5>
                                  <p className="mb-1">
                                    {userData?.email || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <h4 className="card-title mb-4">Change User Name</h4>

                    <Card>
                      <Card.Body>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="form-group">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                              name="name"
                              className="form-control"
                              placeholder="Please Enter User Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              isInvalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.name}
                              </Form.Control.Feedback>
                            ) : null}
                            <Form.Control name="idx" value={""} type="hidden" />
                          </div>
                          <div className="text-center mt-4">
                            <Button type="submit" variant="secondary">
                              Update User Name
                            </Button>
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>

                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        &copy; {new Date().getFullYear()} Crypto Trade App
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
