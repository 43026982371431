enum LAYOUT_TYPES {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

enum LEFT_SIDEBAR_SIZE_TYPES {
  DEFAULT = "lg",
  COMPACT = "md",
  SMALLICON = "sm",
  SMALLHOVER = "sm-hover",
}

export { LAYOUT_TYPES, LEFT_SIDEBAR_SIZE_TYPES };
