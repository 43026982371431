import axios from "axios";
import { getAuthToken } from "./util";
import { showErrorMsg } from "../Common/Toast/Toast";
class APIClient {
  get = async (url: any, params: any) => {
    let response;
    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map((key) => {
        params[key] && paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });
      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = await axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = async (url: any, data: any) => {
    let response = await axios.post(url, data ?? "");
    return response;
  };

  filePost = async (url: any, data: any, header: any) => {
    let axiosConfig = {
      headers: header,
    };
    let response = await axios.post(url, data, axiosConfig);
    return response;
  };
  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    return axios.patch(url, data);
  };

  put = async (url: any, data: any) => {
    let response = await axios.put(url, data);
    return response;
  };
  /**
   * Delete
   */
  delete = (url: any, config: any) => {
    return axios.delete(url, { ...config });
  };
}

const apiClient = new APIClient();
axios.defaults.baseURL = process.env?.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors

axios.interceptors.response.use(
  (response) => {
    return response?.data ?? response;
  },
  function (error) {
    let message;

    switch (error?.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = error?.response?.data?.message ?? error?.message;
        window.location.href = "/login";
        localStorage?.clear();
        showErrorMsg(message);
        break;
      case 400:
        message = error?.response?.data?.message ?? error?.message;
        break;
      case 404:
        message = error?.response?.data?.message ?? error?.message;
        break;
      default:
        message = error?.response?.data?.message || error?.message;
        break;
    }

    return { message: message };
  }
);

axios.interceptors.request.use(
  (config: any) => {
    const token = getAuthToken() ? getAuthToken() : null;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error?: any) => {
    return Promise.reject(error);
  }
);

//  @param {*} token

export { apiClient };
