import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createOrder } from "../../../slices/orders/orderSlice";
import { useSelector } from "react-redux";
import { getMinusPlus, setAfterDecimal } from "../../../helpers/util";

interface userProps {
  isShow: boolean;
  handleClose: any;
  actionData: any;
}

const MarketModal = ({ isShow, handleClose, actionData }: userProps) => {
  const [orderType, setOrderType] = useState("limit_order");
  const orderInfoData = useSelector(
    (state: any) => state?.market?.orderInfo ?? []
  );

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: "",
      price: orderInfoData?.data?.current_price ?? "",
      payable_amount: "",
      note: "",
    },
    validationSchema: Yup.object({
      quantity: Yup.string().required("Please Enter Quantity"),
      price: Yup.string().required("Please Enter Price Per Unit "),
    }),

    onSubmit: (values: any) => {
      let data: any = {
        side: actionData?.type?.toLowerCase(),
        order_type: orderType,
        market: actionData?.coindcx_name,
        total_quantity: Number(values?.quantity),
        note: values?.note,
      };
      if (orderType !== "market_order") {
        data = {
          ...data,
          price_per_unit: Number(values?.price),
        };
      }
      dispatch(createOrder(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  const handleOrderTypeChange = (event: any) => {
    setOrderType(event.target.value);
    formik?.setFieldValue("quantity", "");
    formik?.setFieldValue("payable_amount", "");
    formik?.setFieldTouched("quantity", false);
    formik?.setFieldTouched("payable_amount", false);
  };
  const handlePricePerUnitChange = (event: any) => {
    formik?.setFieldValue(
      "price",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("price", false);
    calculatePayableAmount(
      formik?.values?.quantity,
      event?.target?.value >= 0 && event.target.value
    );
  };

  const calculatePayableAmount = (quantity: any, pricePerUnit: any) => {
    const amount = quantity * pricePerUnit;
    formik?.setFieldValue("payable_amount", amount == 0 ? "" : amount);
  };
  const handleQuantityChange = (event: any) => {
    formik?.setFieldValue(
      "quantity",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("quantity", false);
    calculatePayableAmount(
      event?.target?.value >= 0 && event?.target?.value,
      formik?.values?.price
    );
  };
  const handleAmountChange = (event: any) => {
    formik?.setFieldValue(
      "payable_amount",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("payable_amount", false);
    let updateQuantity = event?.target?.value / formik?.values?.price;
    formik?.setFieldValue(
      "quantity",
      updateQuantity === 0 ? "" : updateQuantity
    );
  };

  return (
    <React.Fragment>
      <div style={{ width: "80%" }}>
        <Modal
          centered
          show={isShow}
          style={{ display: "block" }}
          tabIndex={-1}
          className="modal-lg"
        >
          <div className="modal-content">
            <Modal.Header className="p-4 pb-0">
              <Modal.Title as="h5" className="text-capitalize">
                <p
                  className={` ${
                    actionData?.side
                      ? actionData?.side
                      : actionData?.type === "Buy"
                      ? "text-primary"
                      : "text-danger"
                  }
                     `}
                >
                  {actionData?.side ? actionData?.side : actionData?.type}
                </p>
              </Modal.Title>
              <button
                type="button"
                className="btn-close d-flex align-items-start"
                onClick={() => {
                  handleClose();
                  setOrderType("limit_order");
                  formik.resetForm();
                }}
                style={{ marginTop: "-20px" }}
              ></button>
            </Modal.Header>
            <Modal.Body className="p-4">
              {actionData && !actionData?.coindcx_name && (
                <h4 className="d-flex justify-content-center fw-bold text-muted">
                  No Market Available
                </h4>
              )}
              <div className="d-flex gap-4">
                <p className="fs-16">
                  Market :{" "}
                  {actionData?.coindcx_name
                    ? actionData?.coindcx_name
                    : "No Market Available"}
                </p>
                <p className="fs-16">
                  Currency : {orderInfoData?.data?.sell_currency_name}
                </p>

                <p className="fs-16">
                  Balance{": "}
                  {actionData?.type === "Buy"
                    ? setAfterDecimal(
                        Number(orderInfoData?.data?.buy_currency_balance ?? 0),
                        actionData?.base_currency_precision
                      )
                    : setAfterDecimal(
                        Number(orderInfoData?.data?.sell_currency_balance ?? 0),
                        actionData?.base_currency_precision
                      )}
                  <span className="mx-2">
                    (
                    {actionData?.type === "Buy"
                      ? orderInfoData?.data?.buy_currency_name
                      : orderInfoData?.data?.sell_currency_name}
                    )
                  </span>
                </p>
              </div>
              <div className="d-flex gap-4">
                <p className="fs-16">
                  Current Price{":"} {orderInfoData?.data?.current_price ?? 0}
                </p>
                <p className="fs-16">
                  Changes in 24 Hours{":"}{" "}
                  <small
                    className={`badge ${
                      getMinusPlus(
                        orderInfoData?.data?.change_24_hour ?? 0
                      ) === false
                        ? "bg-danger-subtle text-danger"
                        : "bg-primary-subtle text-primary"
                    }   fs-14  mx-2`}
                  >
                    {orderInfoData?.data?.change_24_hour ?? 0}
                  </small>
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label">Order Type:</label>
                <div className="d-flex gap-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="orderType"
                      value="limit_order"
                      checked={orderType === "limit_order"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className="form-check-label">Limit Order</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="orderType"
                      value="market_order"
                      checked={orderType === "market_order"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className="form-check-label">Market Order</label>
                  </div>
                </div>
              </div>
              <Form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Row className="mb-3">
                  <Col lg={4} className="mb-3">
                    <Form.Label htmlFor="price">Price Per Unit</Form.Label>
                    <Form.Control
                      id="price"
                      name="price"
                      placeholder="Enter Price Per Unit"
                      style={{
                        cursor: `${
                          orderType === "market_order" ? "not-allowed" : ""
                        }`,
                      }}
                      type="number"
                      step={0.1}
                      value={formik?.values?.price || ""}
                      onChange={handlePricePerUnitChange}
                      disabled={orderType === "market_order"}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.price && formik.touched.price}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.price}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>

                  <Col lg={4} className="mb-3">
                    <Form.Label htmlFor="quantity">Total Quantity</Form.Label>
                    <Form.Control
                      id="quantity"
                      name="quantity"
                      placeholder="Enter Total Quantity"
                      type="number"
                      onChange={handleQuantityChange}
                      value={formik?.values?.quantity || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.quantity && formik.touched.quantity
                      }
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.quantity}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>

                  <Col lg={4} className="mb-3">
                    <Form.Label htmlFor="payable_amount">
                      Payable Amount
                    </Form.Label>
                    <Form.Control
                      id="payable_amount"
                      name="payable_amount"
                      placeholder="Enter Payable Amount"
                      type="number"
                      onChange={handleAmountChange}
                      value={formik?.values?.payable_amount || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.payable_amount &&
                        formik.touched.payable_amount
                      }
                    />
                    {formik.touched.payable_amount &&
                    formik.errors.payable_amount ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.payable_amount}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <div className="mb-3">
                    <Form.Label htmlFor="note">Note</Form.Label>
                    <textarea
                      className={`form-control ${
                        formik.touched.note && formik.errors.note
                          ? "is-invalid"
                          : ""
                      }`}
                      rows={3}
                      id="note"
                      name="note"
                      placeholder="Enter Your Note"
                      onChange={formik?.handleChange}
                      value={formik?.values?.note || ""}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.note}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Row>

                <div className="hstack gap-2 justify-content-end">
                  <Button
                    type="submit"
                    className={`btn text-capitalize ${
                      actionData?.type === "Buy" ? "btn-primary" : "btn-danger"
                    } `}
                    disabled={!actionData?.coindcx_name}
                  >
                    {actionData?.type}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default MarketModal;
