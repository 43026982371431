import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";
import { addUser, getUser } from "../../../slices/user/userSlice";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
}

const AddUsers = ({ isShow, handleClose, handleShow }: userProps) => {
  const [passwordField, setPasswordField] = useState<any>({
    current_password: false,
    password: false,
    password_confirmation: false,
  });

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      send_email: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter User Name"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter User Email"),
      password: Yup.string().required("Please Enter Password"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
        .required("Please Enter Confirm Password"),
    }),

    onSubmit: (values: any) => {
      dispatch(addUser(values)).then((res: any) => {
        if (res?.payload?.type === "success") {
          dispatch(getUser({}));
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  return (
    <React.Fragment>
      <Modal centered show={isShow} style={{ display: "block" }} tabIndex={-1}>
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Add User</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleClose();
                formik.resetForm();
                setPasswordField(false);
              }}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users"> Name</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter User Name"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name && !!formik.touched.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  placeholder="Enter User Email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && !!formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3 ">
                <Form.Label htmlFor="password">Password</Form.Label>
                <span className="text-danger">*</span>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Form.Control
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    className="position-relative"
                    type={passwordField?.password ? "text" : "password"}
                    value={formik.values.password || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.errors.password && formik.touched.password
                        ? true
                        : false
                    }
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password}
                    </Form.Control.Feedback>
                  ) : null}
                  <button
                    className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                    onClick={() =>
                      setPasswordField({
                        ...passwordField,
                        password: !passwordField?.password,
                      })
                    }
                    style={{
                      top: "0px",
                      right: "15px",
                    }}
                  >
                    <i
                      className={`${
                        !passwordField?.password
                          ? `ri-eye-fill`
                          : `ri-eye-off-line`
                      }  align-middle`}
                    ></i>
                  </button>
                </div>
              </div>
              <div className="mb-3 ">
                <Form.Label htmlFor="password_confirmation">
                  Confirm Password
                </Form.Label>
                <span className="text-danger">*</span>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Form.Control
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Enter Confirm Password"
                    className="position-relative"
                    type={
                      passwordField?.password_confirmation ? "text" : "password"
                    }
                    value={formik.values.password_confirmation || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.errors.password_confirmation &&
                      formik.touched.password_confirmation
                        ? true
                        : false
                    }
                  />
                  {formik.touched.password_confirmation &&
                  formik.errors.password_confirmation ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.password_confirmation}
                    </Form.Control.Feedback>
                  ) : null}
                  <button
                    className="btn btn-link position-absolute text-decoration-none text-muted password-addon"
                    type="button"
                    id="password-addon"
                    onClick={() =>
                      setPasswordField({
                        ...passwordField,
                        password_confirmation:
                          !passwordField?.password_confirmation,
                      })
                    }
                    style={{
                      top: "0px",
                      right: "15px",
                    }}
                  >
                    <i
                      className={`${
                        !passwordField?.password_confirmation
                          ? `ri-eye-fill`
                          : `ri-eye-off-line`
                      }  align-middle`}
                    ></i>
                  </button>
                </div>
              </div>
              <div className="mb-3 d-flex gap-2 ">
                <Form.Check
                  type="checkbox"
                  id="send_email"
                  name="send_email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik?.values?.send_email || false}
                  isInvalid={
                    formik.errors.send_email && formik.touched.send_email
                  }
                />
                <Form.Label className="form-check-label" htmlFor="send_email">
                  Send credentials to the user
                </Form.Label>
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button type="submit" className="btn btn-secondary">
                  Add User
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddUsers;
