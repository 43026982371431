import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  fetchOrderData: [],
  editPriceData: [],
  editPriceLoading: false,
  createOrderLoading: false,
  cancelOrderLoading: false,
  fetchOrderLoading: false,
  noteLoading: false,
};
//get order
export const getOrders = createAsyncThunk(
  "get-orders",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/orders`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// create order
export const createOrder = createAsyncThunk(
  "create-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/orders/create`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// cancel order
export const cancelOrder = createAsyncThunk(
  "cancel-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(
        `api/orders/cancel/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// edit price
export const editPrice = createAsyncThunk(
  "edit-price",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(
        `api/orders/edit-price/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// fetch order
export const fetchOrders = createAsyncThunk(
  "fetch-orders",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.get(`api/fetch-orders`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// add & update note
export const orderNote = createAsyncThunk(
  "order-note",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(`api/orders/${data?.id}`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const orderSlice = createSlice({
  name: "orderslice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get orders
    builder.addCase(getOrders.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getOrders.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(getOrders.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });

    // fetch orders
    builder.addCase(fetchOrders.pending, (state: any, action: any) => {
      state.fetchOrderLoading = true;
    });
    builder.addCase(fetchOrders.rejected, (state: any, action: any) => {
      state.fetchOrderLoading = false;
      state.fetchOrderData = [];
      state.error = action.payload || null;
    });
    builder.addCase(fetchOrders.fulfilled, (state: any, action: any) => {
      state.fetchOrderLoading = false;
    });

    // edit price
    builder.addCase(editPrice.pending, (state: any, action: any) => {
      state.editPriceLoading = true;
    });
    builder.addCase(editPrice.rejected, (state: any, action: any) => {
      state.editPriceLoading = false;
      state.editPriceData = [];
      state.error = action.payload || null;
    });
    builder.addCase(editPrice.fulfilled, (state: any, action: any) => {
      state.editPriceLoading = false;
      state.editPriceData = action.payload;
    });

    // create order
    builder.addCase(createOrder.pending, (state: any, action: any) => {
      state.createOrderLoading = true;
    });
    builder.addCase(createOrder.rejected, (state: any, action: any) => {
      state.createOrderLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(createOrder.fulfilled, (state: any, action: any) => {
      state.createOrderLoading = false;
    });
    // cancel order
    builder.addCase(cancelOrder.pending, (state: any, action: any) => {
      state.cancelOrderLoading = true;
    });
    builder.addCase(cancelOrder.rejected, (state: any, action: any) => {
      state.cancelOrderLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(cancelOrder.fulfilled, (state: any, action: any) => {
      state.cancelOrderLoading = false;
    });

    // add & update note
    builder.addCase(orderNote.pending, (state: any, action: any) => {
      state.noteLoading = true;
    });
    builder.addCase(orderNote.rejected, (state: any, action: any) => {
      state.noteLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(orderNote.fulfilled, (state: any, action: any) => {
      state.noteLoading = false;
    });
  },
});

export default orderSlice.reducer;
