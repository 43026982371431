import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  createGroupOrderLoading: false,
  removeGroupOrderLoading: false,
  groupReportData: [],
  groupOrderData: [],
  groupOrderByIdLoading: false,
  reportLoading: false,
};
//get group order
export const getGroupOrders = createAsyncThunk(
  "get-group-orders",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/group/list`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// getGroupOrdersById
export const getGroupOrdersById = createAsyncThunk(
  "getGroupOrdersById",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(
        `api/group/orders/${data?.id}`,
        null
      );
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//get group order report
export const getGroupOrderReport = createAsyncThunk(
  "get-group-order-report",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/group/${data?.id}`, null);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// create group order
export const createGroupOrder = createAsyncThunk(
  "create-group-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/group/add/order`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// remove group order
export const removeGroupOrder = createAsyncThunk(
  "remove-group-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/group/remove/order/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const groupOrderSlice = createSlice({
  name: "groupOrderslice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get group orders
    builder.addCase(getGroupOrders.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getGroupOrders.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(getGroupOrders.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });

    //get group orders By Id
    builder.addCase(getGroupOrdersById.pending, (state: any, action: any) => {
      state.groupOrderByIdLoading = true;
    });
    builder.addCase(getGroupOrdersById.rejected, (state: any, action: any) => {
      state.groupOrderByIdLoading = false;
      state.groupOrderData = [];
      state.error = action.error.message || null;
    });
    builder.addCase(getGroupOrdersById.fulfilled, (state: any, action: any) => {
      state.groupOrderByIdLoading = false;
      state.groupOrderData = action.payload;
    });

    //get group order report
    builder.addCase(getGroupOrderReport.pending, (state: any, action: any) => {
      state.reportLoading = true;
    });
    builder.addCase(getGroupOrderReport.rejected, (state: any, action: any) => {
      state.reportLoading = false;
      state.groupReportData = [];
      state.error = action.error.message || null;
    });
    builder.addCase(
      getGroupOrderReport.fulfilled,
      (state: any, action: any) => {
        state.reportLoading = false;
        state.groupReportData = action.payload;
      }
    );
    //create group orders
    builder.addCase(createGroupOrder.pending, (state: any, action: any) => {
      state.createGroupOrderLoading = true;
    });
    builder.addCase(createGroupOrder.rejected, (state: any, action: any) => {
      state.createGroupOrderLoading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(createGroupOrder.fulfilled, (state: any, action: any) => {
      state.createGroupOrderLoading = false;
    });

    //remove group orders
    builder.addCase(removeGroupOrder.pending, (state: any, action: any) => {
      state.removeGroupOrderLoading = true;
    });
    builder.addCase(removeGroupOrder.rejected, (state: any, action: any) => {
      state.removeGroupOrderLoading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(removeGroupOrder.fulfilled, (state: any, action: any) => {
      state.removeGroupOrderLoading = false;
    });
  },
});

export default groupOrderSlice.reducer;
