import withRouter from "../Common/withRouter";

import { Navigate } from "react-router-dom";
import { getAuthToken } from "../helpers/util";

const NonAuthLayout = ({ children, name }: any) => {
  const token = getAuthToken();

  if (token) {
    return <Navigate to={{ pathname: "/dashboard" }} />;
  }

  return <>{children}</>;
};

export default withRouter(NonAuthLayout);
