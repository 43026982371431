import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";
//User
import userReducer from "./user/userSlice";

// SMTP setting
import getSettingReducer from "./settingSlice/SettingSlice";
// User setting
import userSettingReducer from "./settingSlice/UserSettingSlice";
// Currency Slice
import currencyReducer from "./settingSlice/CurrencySlice";
// Login
import LoginSliceReducer from "./login/loginSlice";
//logout
import logoutReducer from "./logout/logoutSlice";
// order
import orderReducer from "./orders/orderSlice";
// group order
import groupOrderReducer from "./orders/groupOrderSlice";

// Market detail
import marketReducer from "./market/MarketSlice";
// dashboard detail
import dashboardReducer from "./dashboard/dashboardSlice";
// schedule order
import scheduleOrderReducer from "./scheduleOrder/scheduleOrderSlice";
// report page
import reportReducer from "./Reports/ReportSlice";
// notification
import notificationReducer from "./Notification/NotificationSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  getSetting: getSettingReducer,
  user: userReducer,
  login: LoginSliceReducer,
  logOut: logoutReducer,
  userSetting: userSettingReducer,
  currency: currencyReducer,
  order: orderReducer,
  market: marketReducer,
  groupOrder: groupOrderReducer,
  dashboard: dashboardReducer,
  scheduleOrder: scheduleOrderReducer,
  report: reportReducer,
  notification: notificationReducer,
});

export default rootReducer;
