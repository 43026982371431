import React, { useCallback, useEffect, useState } from "react";
import { Button, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deleteKey,
  getSecretKey,
  setDefaultKey,
} from "../../slices/settingSlice/UserSettingSlice";
import AddSecretKeyModal from "./addSecretKeyModal";
import NoSearchResult from "../../Common/Tabledata/NoSearchResult";
import { DeleteModal } from "../../Common/DeleteModal";
interface userProps {
  isShow: boolean;
  handleClose: any;
  editData: any;
  setEditData: any;
}
const SecretKeyTable = ({
  isShow,
  handleClose,
  editData,
  setEditData,
}: userProps) => {
  const dispatch: any = useDispatch();
  const secretKey = useSelector((state: any) => state?.userSetting?.secretData);
  const defaultLoading = useSelector(
    (state: any) => state?.userSetting?.defaultLoading
  );
  const updateStatusLoading = useSelector(
    (state: any) => state?.userSetting?.updateStatusLoading
  );

  const [users, setUsers] = useState<any>([]);
  const [deletid, setDeletid] = useState<any>();
  const [removeId, setremoveId] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getSecretKey({}));
  }, [defaultLoading, updateStatusLoading]);
  useEffect(() => {
    setUsers(secretKey?.data);
  }, [secretKey]);

  const deleteKeyModal = useCallback(
    (data: any) => {
      if (removeId === true) {
        setDeletid("");
      } else {
        setDeletid(data);
      }
      setremoveId(!removeId);
    },
    [removeId]
  );

  const deleteKeyId = () => {
    dispatch(deleteKey({ id: deletid })).then((res: any) => {
      setremoveId(false);
    });
  };

  return (
    <div className="mt-2">
      <ListGroup className="m-2 bg-white">
        {users?.length > 0 ? (
          users?.map((secret: any, index: number) => (
            <ListGroup.Item className="bg-white" key={index}>
              <div className="float-end d-flex gap-2">
                <div>
                  {secret?.is_default !== 1 && (
                    <Button
                      className="btn btn-sm fw-bold"
                      type="button"
                      onClick={() => {
                        dispatch(setDefaultKey({ id: secret?.id }));
                      }}
                    >
                      Set Default
                    </Button>
                  )}
                </div>
                <div>
                  <OverlayTrigger
                    trigger={["click", "hover"]}
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-bottom">Edit Key</Tooltip>}
                    rootClose={true}
                  >
                    <Button
                      className="btn btn-sm btn-secondary"
                      type="button"
                      onClick={() => {
                        setEditData(secret);
                        handleClose();
                      }}
                    >
                      <i className="ri-edit-2-fill  align-middle  ri-lg"></i>
                    </Button>
                  </OverlayTrigger>
                </div>
                <div>
                  {" "}
                  <OverlayTrigger
                    trigger={["click", "hover"]}
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-bottom">Delete key</Tooltip>}
                    rootClose={true}
                  >
                    <Button
                      className="btn btn-sm btn-danger"
                      type="button"
                      onClick={() => {
                        deleteKeyModal(secret?.id);
                      }}
                    >
                      <i className="ri-delete-bin-5-fill  align-middle  ri-lg"></i>
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="flex-grow-1 align-items-center d-flex gap-4 m-0">
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <h5 className="list-title fs-md  text-capitalize">
                    {secret?.name}
                  </h5>
                </div>
                <div>
                  <h5 className="badge bg-secondary my-1">
                    {secret?.platform?.split("_")?.join(" ")}
                  </h5>
                </div>
                {secret?.is_default === 1 && (
                  <div>
                    <p className="badge bg-primary my-1">default</p>
                  </div>
                )}
              </div>
            </ListGroup.Item>
          ))
        ) : (
          <NoSearchResult title={"Please add a key to fetch orders"} />
        )}
      </ListGroup>
      <AddSecretKeyModal
        isShow={isShow}
        handleClose={handleClose}
        editData={editData}
        setEditData={setEditData}
      />
      <DeleteModal
        title={{
          text: "delete your secret key ,By deleting your secret key, all associated orders , schedule orders and group orders will be permanently erased from the system. This action cannot be undone.Are you sure you want to proceed",
          btnText: "Deleted",
        }}
        show={removeId}
        handleClose={deleteKeyModal}
        deleteModalFunction={deleteKeyId}
      />
    </div>
  );
};

export default SecretKeyTable;
