import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getMinusPlus } from "../../helpers/util";
import { useSelector } from "react-redux";
import NoSearchResult from "../../Common/Tabledata/NoSearchResult";
import { getTradeDetail } from "../../slices/dashboard/dashboardSlice";
import MultiHeadTable from "../../Common/Tabledata/MultiHeadTable";

const TradeList = () => {
  const dispatch = useDispatch();
  const [tradeList, setTradeList] = useState<any>();

  const tradeData = useSelector(
    (state: any) => state?.dashboard?.tradeData ?? []
  );
  useEffect(() => {
    dispatch(getTradeDetail(""));
  }, []);
  useEffect(() => {
    setTradeList(tradeData?.data);
  }, [tradeData]);

  const columns = [
    {
      Header: "Market",
      accessor: "market_name",
      isSortable: true,
      rowSpan: 2,
    },
    {
      Header: "Currency",
      accessor: "currency",
      isSortable: true,
      rowSpan: 2,
    },
    {
      Header: "Trade Currency",
      accessor: "trade_currency",
      isSortable: true,
      rowSpan: 2,
    },

    {
      Header: "Buy",
      colSpan: 3,
      style: {
        textAlign: "center",
      },

      columns: [
        {
          Header: "Filled Quantity",
          accessor: "filled-buy",
          background: true,
          isSortable: true,

          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.buy?.total_filled_quantity}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Average Price ",
          accessor: "buy_avg_price",
          isSortable: true,

          background: true,
          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.buy?.avg_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Price ",
          accessor: "buy_total_price",
          isSortable: true,

          background: true,
          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.buy?.total_price}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "Sell",
      colSpan: 3,

      style: {
        textAlign: "center",
      },
      columns: [
        {
          Header: "Filled Quantity",
          accessor: "filled-sell",
          background: true,
          isSortable: true,

          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.sell?.total_filled_quantity}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Average Price ",
          accessor: "sell_avg_price",
          background: true,
          isSortable: true,

          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.sell?.avg_price}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Total Price ",
          accessor: "sell_total_price",
          background: true,
          isSortable: true,

          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.sell?.total_price}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "Profit/Loss",
      accessor: "profit_loss",
      isSortable: true,

      Cell: (cell: any) => {
        return (
          <div>
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.profit_loss ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.profit_loss}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Available Quantity",
      colSpan: 3,

      style: {
        textAlign: "center",
      },
      columns: [
        {
          Header: "Quantity",
          accessor: "filled",
          background: true,
          isSortable: true,

          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.available_quantity}
                </span>
              </div>
            );
          },
        },
        {
          Header: "Buy Price ",
          accessor: "buy_price",
          isSortable: true,

          background: true,
        },
        {
          Header: "Current Price ",
          accessor: "current_price",
          isSortable: true,

          background: true,
          Cell: (cell: any) => {
            return (
              <div>
                <span className="text-dark">
                  {cell.row.original?.current_price}
                </span>
              </div>
            );
          },
        },
      ],
    },
    {
      Header: "Current Status",
      accessor: "status",
      isSortable: true,
      Filter: false,
      Cell: (cell: any) => {
        return (
          <div>
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.status ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.status}
            </span>
          </div>
        );
      },
    },
  ];
  const currencyColumn = [
    {
      Header: "Currency",
      accessor: "trade_currency",
      isSortable: true,
      rowSpan: 2,
      // Cell: (cell: any) => {
      //   return (
      //     <div>
      //       <span className="text-dark">
      //         {cell.row.original?.trade_currency}
      //       </span>
      //     </div>
      //   );
      // },
    },
    {
      Header: "Buy Price",
      accessor: "total_buy_price",
      isSortable: true,
      rowSpan: 2,
      // Cell: (cell: any) => {
      //   return (
      //     <div>
      //       <span className="text-dark">
      //         {cell.row.original?.total_buy_price}
      //       </span>
      //     </div>
      //   );
      // },
    },
    {
      Header: "Current Price",
      accessor: "total_current_price",
      isSortable: true,
      rowSpan: 2,
    },
    {
      Header: "Profit/Loss",
      accessor: "profit_loss",
      isSortable: true,
      Cell: (cell: any) => {
        return (
          <div>
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.profit_loss ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.profit_loss ?? 0}
            </span>
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "status",
      isSortable: true,
      Cell: (cell: any) => {
        return (
          <div>
            <span
              className={`badge ${
                getMinusPlus(cell.row.original?.status ?? 0) === false
                  ? "bg-danger-subtle text-danger"
                  : "bg-primary-subtle text-primary"
              }   fs-14 mb-1 mx-2`}
            >
              {cell.row.original?.status ?? 0}
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <div className="">
      <Row className="justify-content-center">
        <Col xxl={12}>
          <Card id="demo">
            <Card.Body>
              {tradeList && (
                <Row className="mb-4">
                  <Row className="d-flex flex-wrap ">
                    <Col lg={3} className="mb-2 align-middle ">
                      <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                        <p className="fs-18 text-muted mt-2">Total Trade</p>
                        <small className="badge bg-success-subtle text-success fs-18 ">
                          {tradeList?.total_trades ?? 0}
                        </small>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-2 ">
                      <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                        <p className="font-bold fs-18 text-muted mt-2">
                          Total Buy
                        </p>
                        <small className="badge bg-primary-subtle text-primary fs-18">
                          {tradeList?.total_buy_trades ?? 0}
                        </small>
                      </div>
                    </Col>

                    <Col lg={3} className="mb-2 ">
                      <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                        <p className="font-bold fs-18 text-muted mt-2">
                          Total Sell
                        </p>
                        <small className="badge bg-danger-subtle text-danger fs-18">
                          {tradeList?.total_sell_trades ?? 0}
                        </small>
                      </div>
                    </Col>

                    <Col lg={3} className="mb-2">
                      <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                        <p className="font-bold fs-18 text-muted mt-2">
                          Total TDS INR
                        </p>
                        <small
                          className={`badge ${
                            getMinusPlus(tradeList?.total_tds_inr ?? 0) ===
                            false
                              ? "bg-danger-subtle text-danger"
                              : "bg-secondary-subtle text-secondary"
                          }   fs-18 mb-1 mx-2`}
                        >
                          {tradeList?.total_tds_inr ?? 0}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </Row>
              )}

              <div>
                {tradeList && tradeList?.trades.length > 0 ? (
                  <div>
                    <p className="my-2 fw-bold me-2">Currency Wise Profit</p>
                    <div className="p-4">
                      <div>
                        <MultiHeadTable
                          isPagination={false}
                          columns={currencyColumn}
                          data={tradeList?.currency_status || []}
                          customPageSize={
                            tradeList?.currency_status?.length ?? 50
                          }
                          divClassName="table-card table-responsive"
                          tableClass="table-hover table-nowrap align-middle mb-0 border table table-striped "
                          isBordered={false}
                          PaginationClass="align-items-center mt-4 gy-3"
                        />
                      </div>
                    </div>
                    <div className="p-4 my-2">
                      <MultiHeadTable
                        isPagination={false}
                        columns={columns}
                        data={tradeList?.trades || []}
                        customPageSize={tradeList?.trades?.length}
                        divClassName="table-card table-responsive"
                        tableClass="table-hover table-nowrap align-middle mb-0 border table table-striped "
                        isBordered={false}
                        PaginationClass="align-items-center mt-4 gy-3"
                      />
                    </div>
                  </div>
                ) : (
                  <NoSearchResult
                    title={`${tradeData?.message ?? "No Trade Details Found"}`}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TradeList;
