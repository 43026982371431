import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//Import Components
import VerticalLayout from "./VerticalLayouts/index";
import { Container } from "react-bootstrap";
import HorizontalLayout from "./HorizontalLayout";

const Sidebar = ({ layoutType }: any) => {
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu  position-fixed">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                width="25"
                height="25"
                src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                alt="peercoin"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light ">
            <div className="logo-lg">
              <img
                width="25"
                height="25"
                src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                alt="peercoin"
              />
              <span className="mt-2"> Crypto Trade App</span>
            </div>
          </Link>
        </div>

        {layoutType === "horizontal" ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100 ">
              <Container fluid>
                <ul className="navbar-nav " id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
          </React.Fragment>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
