import React, { useState } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotificationTable from "./NotificationTable";

const Notification = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab: any = searchParams.get("tab");
  const totalTabs = ["active_notification", "completed_notification"];
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState<any>(
    totalTabs?.includes(currentActiveTab)
      ? currentActiveTab
      : "active_notification"
  );

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          pageTitle="Market Management"
          title={dropDownList?.split("_")?.join(" ")}
        />
        <Card>
          <Card.Body>
            <Tab.Container defaultActiveKey={dropDownList}>
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary mb-2"
              >
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="active_notification"
                    onClick={() => {
                      setDropDownList("active_notification");
                      navigate("/notification?tab=active_notification");
                    }}
                  >
                    Active Notification
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="completed_notification"
                    onClick={() => {
                      setDropDownList("completed_notification");
                      navigate("/notification?tab=completed_notification");
                    }}
                  >
                    Completed Notification
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="text-primary">
                <Tab.Pane eventKey="active_notification">
                  {dropDownList === "active_notification" && (
                    <NotificationTable dropDownList={dropDownList} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="completed_notification">
                  {dropDownList === "completed_notification" && (
                    <NotificationTable dropDownList={dropDownList} />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Notification;
