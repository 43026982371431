import { useState } from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import ScheduleOrderTable from "./ScheduleOrderTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { dropDownStyle } from "../../helpers/util";

const ScheduleOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab: any = searchParams.get("tab");
  const totalTabs = ["active_order", "completed_order"];
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState<any>(
    totalTabs?.includes(currentActiveTab) ? currentActiveTab : "active_order"
  );
  const [selectedOption, setSelectedOption] = useState<any>("All Orders");
  const options = [
    { label: "All Orders", value: "all" },
    { label: "placed", value: "placed" },
    { label: "rejected", value: "rejected" },
    { label: "cancelled", value: "cancelled" },
  ];
  const changeFilter = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          pageTitle="Market Management"
          title={dropDownList?.split("_")?.join(" Schedule ")}
        />
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <div
                  className={`d-flex justify-content-end ${
                    dropDownList === "completed_order" ? "visible" : "invisible"
                  }`}
                >
                  <Select
                    value={{ label: selectedOption?.label ?? "All Orders" }}
                    onChange={changeFilter}
                    placeholder={<div>Type to search</div>}
                    options={options ?? []}
                    styles={dropDownStyle()}
                  />
                </div>
              </Col>
            </Row>
            <Tab.Container defaultActiveKey={dropDownList}>
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary mb-2"
              >
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="active_order"
                    onClick={() => {
                      setDropDownList("active_order");
                      setSelectedOption({ label: "All Orders", value: "all" });
                      navigate("/schedule_order?tab=active_order");
                    }}
                  >
                    Active Schedule Orders
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey="completed_order"
                    onClick={() => {
                      setDropDownList("completed_order");
                      setSelectedOption({ label: "All Orders", value: "all" });
                      navigate("/schedule_order?tab=completed_order");
                    }}
                  >
                    Completed Schedule Orders
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="text-primary">
                <Tab.Pane eventKey="active_order">
                  {dropDownList === "active_order" && (
                    <ScheduleOrderTable
                      dropDownList={dropDownList}
                      selectedOption={selectedOption}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="completed_order">
                  {dropDownList === "completed_order" && (
                    <ScheduleOrderTable
                      dropDownList={dropDownList}
                      selectedOption={selectedOption}
                    />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ScheduleOrder;
