import React, { useState } from "react";
import withRouter from "../../Common/withRouter";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { login } from "../../slices/login/loginSlice";

const Login = () => {
  document.title = "Login |Crypto-Trade-App";
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const loginLoading = useSelector(
    (state: any) => state?.login?.loginLoading ?? false
  );

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(login(values)).then((res: any) => {
        if (res?.payload?.type === "success") {
          navigate("/dashboard");
        }
      });
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5">
                      <Link to="/">
                        <span className="logo-lg">
                          <img
                            width="25"
                            height="25"
                            src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                            alt="logo"
                          />
                          <small className="text-muted fs-18">
                            Crypto Trade App
                          </small>
                        </span>
                      </Link>
                    </div>

                    <Card className="my-auto overflow-hidden">
                      <Row className="g-0">
                        <Col lg={12}>
                          <Card.Body className="p-lg-5 p-4">
                            <div className="text-center">
                              <h5 className="mb-0">Welcome Back !</h5>
                              <p className="text-muted mt-2">
                                Sign in to continue to Crypto Trade App.
                              </p>
                            </div>

                            <div className="mt-4">
                              <Form
                                action="#"
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}
                              >
                                <Form.Group
                                  className="mb-3"
                                  controlId="username"
                                >
                                  <Form.Label>Email</Form.Label>
                                  <div className="position-relative">
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      autoComplete="off"
                                      className="form-control bg-light border-light password-input"
                                      placeholder="Please Enter Email"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.email || ""}
                                      isInvalid={
                                        validation.touched.email &&
                                        validation.errors.email
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.email &&
                                    validation.errors.email ? (
                                      <Form.Control.Feedback type="invalid">
                                        {validation.errors.email}
                                      </Form.Control.Feedback>
                                    ) : null}
                                  </div>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="password-input"
                                >
                                  
                                  <Form.Label>
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Form.Control
                                      type={passwordShow ? "text" : "password"}
                                      name="password"
                                      autoComplete="off"
                                      className="form-control bg-light border-light pe-5 password-input"
                                      placeholder="Please Enter password"
                                      value={validation.values.password || ""}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      isInvalid={
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.password &&
                                    validation.errors.password ? (
                                      <Form.Control.Feedback type="invalid">
                                        {validation.errors.password}
                                      </Form.Control.Feedback>
                                    ) : null}
                                    <button
                                      className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                                      type="button"
                                      id="password-addon"
                                      onClick={() =>
                                        setPasswordShow(!passwordShow)
                                      }
                                      style={{
                                        top: "0px",
                                        right: "15px",
                                      }}
                                    >
                                      <i
                                        className={`${
                                          !passwordShow
                                            ? `ri-eye-fill`
                                            : `ri-eye-off-line`
                                        }  align-middle`}
                                      ></i>
                                    </button>
                                  </div>
                                </Form.Group>

                                <Form.Check
                                  className="mb-3"
                                  id="auth-remember-check"
                                >
                                  <Form.Check.Input
                                    type="checkbox"
                                    defaultChecked={true}
                                  />
                                  <Form.Check.Label>
                                    Remember me
                                  </Form.Check.Label>
                                </Form.Check>

                                <div className="mt-2">
                                  <Button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    disabled={loginLoading}
                                  >
                                    {loginLoading && <Spinner size="sm" />} Sign
                                    In
                                  </Button>
                                </div>
                                <div className="float-end">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                              </Form>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        &copy; {new Date().getFullYear()} Crypto Trade App
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
