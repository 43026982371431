import Chart from "react-apexcharts";
import getChartColorsArray from "../../../Common/ChartsDynamicColor";
import moment from "moment";

const GraphData = ({ dataColors, graphTable, startDate }: any) => {
  const getDate = (type?: string) => {
    const filteredData = graphTable?.filter((value: any) => {
      return value.hasOwnProperty(type);
    });

    // Extract type values from filtered data using map
    const data = filteredData?.map((value: any) => {
      return type ? value[type] : undefined;
    });

    return data;
  };
  const getProfit = (type?: string, label?: string) => {
    const filteredData = graphTable?.filter((value: any) => {
      return value.hasOwnProperty(type);
    });

    // Extract type values from filtered data using map
    const data = filteredData?.map((value: any) => {
      if (label === "Profit") {
        return type && value[type] >= 0 ? type && Number(value[type]) : 0; // Set to positive value or 0
      } else if (label === "Loss") {
        return type && value[type] <= 0
          ? type && Number(Math?.abs(value[type]))
          : 0; // Set to negative value or 0
      }
      return 0;
    });

    return data;
  };

  let startMonth = moment(startDate).format("MM");
  const getDefault = () => {
    const currentMonth = moment().month();
    const currentYear = moment().year();

    // Get the number of days in the current month
    const daysInMonth = moment(
      `${currentYear}-${currentMonth + 1}`,
      "YYYY-MM"
    ).daysInMonth();

    // Array to store all dates in the current month
    const datesInMonth = Array.from({ length: daysInMonth }, (_, index) =>
      moment(
        `${currentYear}-${currentMonth + 1}-${index + 1}`,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD")
    );
    return datesInMonth;
  };
  // Get the date 30 days in current month
  let defaultData = getDefault();
  var chartbarBasicColors = getChartColorsArray(dataColors);

  const series: any = [
    {
      name: "Profit",
      data: getProfit("profilt_loss", "Profit") ?? [],
    },
    {
      name: "Loss",
      data: getProfit("profilt_loss", "Loss") ?? [],
    },
  ];

  var options: any = {
    chart: {
      height: 362,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    xaxis: {
      categories:
        startMonth === moment().format("MM")
          ? defaultData
          : getDate("date") ?? [],
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: chartbarBasicColors,
    legend: {
      position: "right",
      horizontalAlign: "top",
    },

    fill: {
      opacity: 1,
    },
  };
  return (
    <div className="border mb-0 rounded ">
      <Chart
        dir="ltr"
        className="apex-charts"
        series={series}
        options={options}
        type="bar"
        height={362}
      />
    </div>
  );
};

export default GraphData;
