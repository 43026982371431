import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  formatNumber,
  formatedDate,
  formatedDateAndTime,
  getMinusPlus,
} from "../../../helpers/util";
import { useDispatch } from "react-redux";
import {
  getGroupOrders,
  getGroupOrdersById,
  removeGroupOrder,
} from "../../../slices/orders/groupOrderSlice";
import { useSelector } from "react-redux";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../../Common/DeleteModal";
import PriceModal from "../Order/PriceModal";
import GroupSellModal from "./GroupSellModal";
import {
  getMarketDetail,
  getOrderInfo,
} from "../../../slices/market/MarketSlice";
import copy from "copy-to-clipboard";
import { showSuccessMsg } from "../../../Common/Toast/Toast";
import { cancelOrder } from "../../../slices/orders/orderSlice";
import Loader from "../../../Common/Loader/Loader";
import GroupOrderModal from "./GroupOrderModal";
interface GroupProps {
  dropDownList?: any;
  tab?: any;
  setTab?: any;
  setMarket?: any;
  perPage?: any;
}
const GroupOrderCard = ({
  dropDownList,
  tab,
  setMarket,
  perPage,
}: GroupProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupOrderList = useSelector(
    (state: any) => state?.groupOrder?.data ?? []
  );
  const groupOrderListLoading = useSelector(
    (state: any) => state?.groupOrder?.loading ?? false
  );
  const groupOrderByIdLoading = useSelector(
    (state: any) => state?.groupOrder?.groupOrderByIdLoading ?? false
  );

  const removeGroupOrderLoading = useSelector(
    (state: any) => state?.groupOrder?.removeGroupOrderLoading ?? false
  );
  const cancelOrderLoading = useSelector(
    (state: any) => state?.order?.cancelOrderLoading ?? false
  );
  const createOrderLoading = useSelector(
    (state: any) => state?.order?.createOrderLoading
  );
  const editPriceLoading = useSelector(
    (state: any) => state?.order?.editPriceLoading
  );
  const [grouOrder, setGroupOrder] = useState<any>([]);
  const [groupOrderData, setGroupOrderData] = useState<any>([]);
  const [groupId, setGroupId] = useState<any>();

  const [openCards, setOpenCards] = useState<any>({});
  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const [calcelOrderModal, setCancelOrderModal] = useState<boolean>(false);
  const [calcelOrderId, setCancelOrderId] = useState<any>();
  const [isShow, setIsShow] = useState(false);
  const [showGroupOrder, setShowGroupOrder] = useState(false);

  const [actionData, setActionData] = useState<any>();
  const [sellModal, setSellModal] = useState(false);

  const handleToggle = (index: any, id?: any) => {
    setGroupId({ groupIndex: index, groupId: id });
    setOpenCards((prevOpenCards: any) => ({
      [index]: !prevOpenCards[index],
    }));
  };

  const handleReport = (id: any) => {
    navigate("/group_report", { state: id });
  };

  // Leave from group  order
  const handleDeleteModal = useCallback(
    (id: any) => {
      if (delet === true) {
        setDeletid("");
      } else {
        setDeletid(id);
      }
      setDelet(!delet);
    },
    [delet]
  );

  const handleDeleteId = () => {
    dispatch(removeGroupOrder({ id: deletid })).then((res?: any) => {
      if (res?.payload?.type === "success") {
        setDelet(false);
      }
    });
  };

  // cancel order  from group order
  const handleCancelModal = useCallback(
    (id: any) => {
      if (calcelOrderModal === true) {
        setCancelOrderId("");
      } else {
        setCancelOrderId(id);
      }
      setCancelOrderModal(!calcelOrderModal);
    },
    [calcelOrderModal]
  );

  const handleCancelId = () => {
    dispatch(cancelOrder({ id: calcelOrderId })).then((res?: any) => {
      if (res?.payload?.type === "success") {
        setCancelOrderModal(false);
      }
    });
  };
  const hideUserModal = () => {
    setIsShow(!isShow);
  };
  const hideGroupOrderModal = () => {
    setShowGroupOrder(!showGroupOrder);
  };

  const getMarketDetails = (value: any) => {
    dispatch(getMarketDetail({ market_name: value?.market })).then(
      (res: any) => {
        if (res?.payload?.type === "success") {
          let data = {
            base_currency_short_name:
              res?.payload?.data?.base_currency_short_name,
            target_currency_short_name:
              res?.payload?.data?.target_currency_short_name,
            base_currency_precision:
              res?.payload?.data?.base_currency_precision,
            target_currency_precision:
              res?.payload?.data?.target_currency_precision,
            coindcx_name: res?.payload?.data?.coindcx_name,
          };
          setActionData({
            ...value,
            coindcx_name: res?.payload?.data?.coindcx_name,
          });

          dispatch(getOrderInfo(data));
        }
      }
    );
  };

  //Group Sell modal
  const showSellModal = (value?: any) => {
    !sellModal && getMarketDetails(value);
    setSellModal(!sellModal);
    if (sellModal === true) {
      setActionData("");
    }
  };

  let data =
    dropDownList === "active_group_order"
      ? "active"
      : dropDownList === "completed_group_order"
      ? "completed"
      : "";
  let message = `No ${data ?? ""} Group Orders Found`;

  let tabData = tab === "All Market" ? "all" : tab;
  useEffect(() => {
    dispatch(
      getGroupOrders({
        filter: data,
        market: tabData,
        perPage: perPage ?? 100,
      })
    ).then((res: any) => {
      if (res?.payload?.type === "success") {
        setGroupOrder(res?.payload?.data?.group_orders);
        const initialOpenCardsState = Array.from(
          { length: res?.payload?.data?.group_orders?.length },
          (_, index) => [index, false]
        );
        const initialOpenCards = Object.fromEntries(initialOpenCardsState);
        setOpenCards(initialOpenCards);
      }
    });
  }, [
    tab,
    removeGroupOrderLoading,
    cancelOrderLoading,
    perPage,
    editPriceLoading,
    createOrderLoading,
  ]);
  useEffect(() => {
    if (groupOrderList?.data?.group_orders) {
      let updatedPairs = ["All Market", ...groupOrderList?.data?.markets];
      setMarket(updatedPairs);
    }
  }, [groupOrderList?.data, tab]);
  useEffect(() => {
    if (openCards[groupId?.groupIndex] === true) {
      dispatch(getGroupOrdersById({ id: groupId?.groupId })).then(
        (res: any) => {
          if (res?.payload?.type === "success") {
            setGroupOrderData(res?.payload?.data);
          }
        }
      );
    } else {
      setGroupOrderData([]);
    }
  }, [groupId]);

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }
  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "SIDE",
        accessor: "side",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.side) {
            case "buy":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.side}
                </span>
              );
            case "sell":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.side}
                </span>
              );
            default:
              return cell.row.original.side;
          }
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case "filled":
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  {cell.row.original.status}
                </span>
              );
            case "partially_filled":
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            case "open":
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
            case "cancelled":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            case "rejected":
              return (
                <span className="badge bg-danger-subtle text-danger p-2">
                  {cell.row.original.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },
      {
        Header: "TOTAL QTY",
        accessor: "total_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.total_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "REMAINING QTY",
        accessor: "remaining_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.remaining_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "FILLED QTY",
        accessor: "filled_quantity",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.filled_quantity}
              </span>
            </div>
          );
        },
      },
      {
        Header: "ORDER PRICE",
        accessor: "price_per_unit",
        Filter: false,
        isSortable: true,
      },

      {
        Header: "AVERAGE PRICE",
        accessor: "avg_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER PLACE PRICE",
        accessor: "order_placed_price",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ORDER EXECUTED PRICE",
        accessor: "order_executed_price",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.order_executed_price}
              </span>
            </div>
          );
        },
      },
      {
        Header: "ORDER TYPE",
        accessor: "order_type",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return cell.row.original.order_type?.split("_").join(" ");
        },
      },

      {
        Header: "CREATED AT",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return formatedDateAndTime(cell.row.original.created_at);
        },
      },
      {
        Header: "UPDATED AT",
        accessor: "updated_at",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return formatedDateAndTime(cell.row.original.updated_at);
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            {cell.row.original?.status !== "filled" && (
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-bottom"> Edit </Tooltip>}
                  rootClose={true}
                >
                  <button
                    className="btn btn-soft-secondary btn-sm d-inline-block"
                    type="button"
                    onClick={() => {
                      hideUserModal();
                      setActionData(cell.row.original);
                    }}
                  >
                    <i className="ri-edit-2-fill me-1 align-middle  ri-lg"></i>
                  </button>
                </OverlayTrigger>
              </li>
            )}
            <li className="list-inline-item ">
              <OverlayTrigger
                trigger={["click", "hover"]}
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-bottom"> Remove from group </Tooltip>
                }
                rootClose={true}
              >
                <button
                  type="button"
                  className="btn btn-soft-danger btn-sm d-inline-block"
                  onClick={() => {
                    handleDeleteModal(cell.row.original.id);
                  }}
                >
                  <i className="ri-user-unfollow-fill me-1 align-middle ri-lg"></i>
                </button>
              </OverlayTrigger>
            </li>
            {!["cancelled", "rejected", "filled"]?.includes(
              cell.row.original.status
            ) && (
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-bottom"> Cancel Order </Tooltip>
                  }
                  rootClose={true}
                >
                  <button
                    className="btn btn-soft-danger btn-sm d-inline-block"
                    type="button"
                    onClick={() => {
                      handleCancelModal(cell.row.original.id);
                    }}
                  >
                    <i className="ri-close-circle-fill me-1 align-middle  ri-lg"></i>
                  </button>
                </OverlayTrigger>
              </li>
            )}
          </ul>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Row>
        <div>
          {groupOrderListLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div>
              {grouOrder?.length > 0 ? (
                grouOrder?.map((groupList: any, index: number) => (
                  <Col lg={12} id="card-none1" key={index}>
                    <Card className="shadow-lg">
                      <Card.Header
                        className={`m-0 p-1 border-top border-2 
                  
                  `}
                        style={{
                          backgroundColor: ` ${
                            groupList?.buy_filled_quantity !==
                              groupList?.sell_quantity &&
                            groupList?.buy_filled_quantity > 0 &&
                            Number(groupList?.buy_filled_quantity ?? 0) -
                              Number(groupList?.sell_quantity ?? 0) >
                              0
                              ? "#eff6ff"
                              : "white"
                          }`,
                        }}
                      >
                        <Row className="mt-1">
                          <Col xl={4}>
                            <table className="w-100">
                              <tbody className="p-4">
                                <tr>
                                  <td>
                                    <p className="card-title mb-0 badge bg-secondary-subtle text-secondary fs-14">
                                      {groupList?.trade_currency}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="card-title mb-0 badge bg-secondary-subtle text-secondary fs-14">
                                      {groupList?.market}
                                    </p>
                                  </td>

                                  <td className="flex-grow-1 m-0 p-0">
                                    {groupList?.buy_filled_quantity !==
                                      groupList?.sell_quantity &&
                                      groupList?.buy_filled_quantity > 0 &&
                                      Number(
                                        groupList?.buy_filled_quantity ?? 0
                                      ) -
                                        Number(groupList?.sell_quantity ?? 0) >
                                        0 && (
                                        <button
                                          onClick={() => {
                                            showSellModal(groupList);
                                          }}
                                          className="btn btn-soft-danger border border-danger-subtle btn-sm fs-12 py-1 px-2 fw-bold"
                                        >
                                          Sell
                                        </button>
                                      )}
                                  </td>

                                  <td className="flex-grow-1 m-0 p-0">
                                    <button
                                      onClick={() =>
                                        handleReport(groupList?.id)
                                      }
                                      className="btn btn-soft-primary btn-sm fs-14 fw-bold"
                                    >
                                      Show Report
                                    </button>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan={2}>
                                    <OverlayTrigger
                                      trigger={"hover"}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip-bottom">
                                          {" "}
                                          Click to copy{" "}
                                        </Tooltip>
                                      }
                                      rootClose={true}
                                    >
                                      <p
                                        className="fs-xl text-muted my-1 cursor-pointer"
                                        onClick={() => {
                                          const isCopied = copy(
                                            `${groupList?.name}`
                                          );
                                          if (isCopied) {
                                            showSuccessMsg(
                                              "Copied to clipboard!"
                                            );
                                          }
                                        }}
                                      >
                                        {groupList?.name}
                                      </p>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-start">
                                      <p className="font-bold  text-muted m-0">
                                        Buy Orders
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <small className="badge bg-primary-subtle text-primary fs-14">
                                      {groupList?.buy_orders ?? 0}
                                    </small>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <p className="fs-xl text-muted my-1">
                                      {formatedDate(groupList?.updated_at)}
                                    </p>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-start ">
                                      <p className="font-bold  text-muted m-0">
                                        Sell Orders
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <small className="badge bg-danger-subtle text-danger fs-14">
                                      {groupList?.sell_orders ?? 0}
                                    </small>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col xl={5}>
                            <table
                              className="w-100 mb-3 group-table"
                              id="customers"
                            >
                              <thead className="text-center">
                                <tr className="text-muted fw-bold">
                                  <td>Side</td>
                                  <td>Order Quantity</td>
                                  <td>Filled Quantity</td>
                                  <td>Average Price</td>
                                  <td>Total Price </td>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                <tr>
                                  <td>Buy</td>
                                  <td>{groupList?.buy_quantity}</td>
                                  <td>{groupList?.buy_filled_quantity}</td>

                                  <td>
                                    {formatNumber(groupList?.avg_buy_price)}
                                  </td>
                                  <td>
                                    {" "}
                                    {formatNumber(
                                      groupList?.buy_quantity *
                                        groupList?.avg_buy_price
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Sell</td>
                                  <td>{groupList?.sell_quantity}</td>
                                  <td>{groupList?.sell_filled_quantity}</td>
                                  <td>{groupList?.avg_sell_price}</td>
                                  <td>
                                    {formatNumber(
                                      groupList?.sell_quantity *
                                        groupList?.avg_sell_price
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col xl={2}>
                            <table className="w-100 mb-3 ">
                              <thead className="text-muted fw-bold"></thead>
                              <tbody className="">
                                <tr>
                                  <td>Fee Amount</td>
                                  <td className="m-0 mx-1 badge bg-primary-subtle text-primary  fs-13 mb-1">
                                    {formatNumber(groupList?.total_fees)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Profit/Loss</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <p
                                        className={`badge ${
                                          groupList?.avg_sell_price > 0 &&
                                          getMinusPlus(
                                            groupList?.sell_filled_quantity *
                                              (groupList?.avg_sell_price -
                                                groupList?.avg_buy_price) ?? 0
                                          ) === false
                                            ? "bg-danger-subtle text-danger"
                                            : "bg-primary-subtle text-primary"
                                        }   fs-13 mb-1`}
                                      >
                                        {groupList?.avg_sell_price > 0
                                          ? formatNumber(
                                              groupList?.sell_filled_quantity *
                                                (groupList?.avg_sell_price -
                                                  groupList?.avg_buy_price)
                                            )
                                          : 0}
                                      </p>
                                      <p
                                        className={`badge ${
                                          getMinusPlus(
                                            groupList?.avg_sell_price > 0 &&
                                              groupList?.avg_buy_price > 0
                                              ? ((groupList?.avg_sell_price -
                                                  groupList?.avg_buy_price) *
                                                  100) /
                                                  groupList?.avg_buy_price
                                              : 0
                                          ) === false
                                            ? "bg-danger-subtle text-danger"
                                            : "bg-primary-subtle text-primary"
                                        }   fs-13 mb-1`}
                                      >
                                        {formatNumber(
                                          groupList?.avg_sell_price > 0 &&
                                            groupList?.avg_buy_price > 0
                                            ? ((groupList?.avg_sell_price -
                                                groupList?.avg_buy_price) *
                                                100) /
                                                groupList?.avg_buy_price
                                            : 0
                                        )}
                                        <span className="m-1">%</span>
                                      </p>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Available to sell qty</td>
                                  <td>
                                    <p className="m-0 mx-1 badge bg-primary-subtle text-primary  fs-13 mb-1">
                                      {groupList?.buy_filled_quantity > 0
                                        ? formatNumber(
                                            Number(
                                              groupList?.buy_filled_quantity ??
                                                0
                                            ) -
                                              Number(
                                                groupList?.sell_quantity ?? 0
                                              )
                                          )
                                        : 0}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col
                            xl={1}
                            className="list-inline-item align-middle minimize-card  d-flex justify-content-end"
                          >
                            <i
                              onClick={() => handleToggle(index, groupList?.id)}
                              className={`${
                                openCards[index]
                                  ? "ri-arrow-up-s-line"
                                  : "ri-arrow-down-s-line"
                              } align-middle fs-4 text-primary cursor-pointer`}
                            ></i>
                          </Col>
                        </Row>
                      </Card.Header>

                      <Collapse in={openCards[index]} className="card-body ">
                        <div
                          id="example-collapse-text "
                          style={{ maxHeight: "300px", overflow: "auto" }}
                        >
                          <div className="mt-4">
                            {groupOrderByIdLoading ? (
                              <div>
                                <Loader />
                              </div>
                            ) : groupOrderData?.length > 0 ? (
                              <TableContainer
                                isPagination={false}
                                columns={columns}
                                data={groupOrderData || []}
                                customPageSize={groupOrderData?.length ?? 50}
                                divClassName="table-card table-responsive"
                                tableClass="table-hover table-nowrap align-middle mb-0"
                                isBordered={false}
                                PaginationClass="align-items-center mt-4 gy-3"
                              />
                            ) : (
                              <NoSearchResult
                                title={`${"No Group Data Found"}`}
                              />
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </Card>
                  </Col>
                ))
              ) : (
                <NoSearchResult
                  title={` ${groupOrderList?.message ?? message}`}
                />
              )}
            </div>
          )}
        </div>

        <DeleteModal
          title={{ text: "left from group", btnText: "Left Group" }}
          show={delet}
          handleClose={handleDeleteModal}
          deleteModalFunction={handleDeleteId}
        />

        <DeleteModal
          title={{ text: "remove order from group", btnText: "remove order" }}
          show={calcelOrderModal}
          handleClose={handleCancelModal}
          deleteModalFunction={handleCancelId}
        />
        <GroupSellModal
          isShow={sellModal}
          handleClose={showSellModal}
          actionData={actionData}
        />
        <PriceModal
          isShow={isShow}
          handleClose={hideUserModal}
          actionData={actionData}
        />
        <GroupOrderModal
          isShow={showGroupOrder}
          handleClose={hideGroupOrderModal}
          actionData={groupOrderData}
          setActionData={setActionData}
        />
      </Row>
    </div>
  );
};

export default GroupOrderCard;
