import React, { useEffect, useState } from "react";

const Navdata = () => {
  //state data
  const [isMarketManagement, setIsMarketManagement] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isReport, setIsReport] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id: any = item.getAttribute("sub-items");
        var menusId = document.getElementById(id);
        if (menusId) {
          (menusId.parentElement as HTMLElement).classList.remove("show");
        }
      });
      e.target.classList.add("active");
    }
  }
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "marketManagement") {
      setIsMarketManagement(false);
    }

    if (iscurrentState !== "Authentication") {
      setIsAuthentication(false);
    }
    if (iscurrentState !== "Report") {
      setIsReport(false);
    }
  }, [iscurrentState, isMarketManagement, isAuthentication, isReport]);

  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "las la-house-damage",
      link: "/dashboard",
      role: ["admin", "user"],
    },
    {
      label: "Pages",
      isHeader: true,
    },
    {
      label: "Users",
      link: "/user",
      icon: " ri-user-3-line",
      role: ["admin"],
    },

    {
      id: "marketManagement",
      label: "Market Management ",
      icon: "las la-file-invoice",
      click: function (e: any) {
        e.preventDefault();
        setIsMarketManagement(!isMarketManagement);
        setIscurrentState("marketManagement");
        updateIconSidebar(e);
      },
      stateVariables: isMarketManagement,
      subItems: [
        {
          label: "Market",
          link: "/market",
          parentId: "marketManagement",
          role: ["user", "admin"],
        },
        {
          label: "Orders",
          link: "/orders",
          parentId: "marketManagement",
          role: ["user", "admin"],
        },

        {
          label: "Group Order",
          link: "/group_order",
          parentId: "marketManagement",
          role: ["user", "admin"],
        },

        {
          label: "Trade Detail",
          link: "/trade_detail",
          parentId: "marketManagement",
          role: ["admin", "user"],
        },

        {
          label: "Schedule Orders",
          link: "/schedule_order",
          parentId: "marketManagement",
          role: ["user", "admin"],
        },
        {
          label: "Notification",
          link: "/notification",
          parentId: "marketManagement",
          role: ["user", "admin"],
        },
      ],
    },
    {
      id: "report",
      label: "Reports",
      icon: "ri-numbers-line",
      click: function (e: any) {
        e.preventDefault();
        setIsReport(!isReport);
        setIscurrentState("Report");
        updateIconSidebar(e);
      },
      stateVariables: isReport,
      subItems: [
        {
          label: "Daily Report",
          link: "/daily_report",
          parentId: "report",
          role: ["user", "admin"],
        },
      ],
    },

    {
      id: "setting",
      label: "Setting",
      icon: "las la-cog",
      click: function (e: any) {
        e.preventDefault();
        setIsAuthentication(!isAuthentication);
        setIscurrentState("Authentication");
        updateIconSidebar(e);
      },
      stateVariables: isAuthentication,
      subItems: [
        {
          label: "SMTP Setting",
          link: "/smtp_setting",
          parentId: "setting",
          role: ["admin"],
        },
        {
          label: "Currency",
          link: "/currency",
          parentId: "setting",
          role: ["admin"],
        },

        {
          label: "Secret Key",
          link: "/secret",
          parentId: "setting",
          role: ["user", "admin"],
        },
        {
          label: "Import Orders",
          link: "/import",
          parentId: "setting",
          role: ["user", "admin"],
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
