import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { formatedDateAndTime } from "../../helpers/util";
import TableContainer from "../../Common/Tabledata/TableContainer";
import NoSearchResult from "../../Common/Tabledata/NoSearchResult";
import { DeleteModal } from "../../Common/DeleteModal";
import { debounce } from "lodash";

import {
  deletenotification,
  getNotification,
} from "../../slices/Notification/NotificationSlice";
import NotifyModal from "../MarketManagement/Market/NotifyModal";
import { getMarketDetail, getOrderInfo } from "../../slices/market/MarketSlice";
import Loader from "../../Common/Loader/Loader";
interface OrderProps {
  dropDownList?: any;
}
const NotificationTable = ({ dropDownList }: OrderProps) => {
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state: any) => state?.notification?.data ?? []
  );
  const notificationLoading = useSelector(
    (state: any) => state?.notification?.loading ?? false
  );
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 10,
    search: "",
  });

  const [columnFilter, setColumnFilter] = useState<any>({
    index: 4,
    order: true,
    name: "updated_at",
  });
  const updateNotificationLoading = useSelector(
    (state: any) => state?.notification?.updateNotificationLoading
  );
  const deleteNotificationLoading = useSelector(
    (state: any) => state?.notification?.deleteNotificationLoading
  );
  const [scheduleOrder, setScheduleOrder] = useState<any>([]);

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const [isShow, setIsShow] = useState(false);
  const [reason, setReason] = useState(false);
  const [viewOrder, setViewOrder] = useState(false);

  const [actionData, setActionData] = useState<any>();
  const [cancelData, setCancelData] = useState<any>();

  const columnChnageFilter = (value: any) => {
    let data = columns[value?.index].accessor;

    setColumnFilter({
      ...columnFilter,
      index: value?.index,
      order: value?.order,
      name: data,
    });
  };
  // Leave from group  modal
  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );
  const handleDeleteId = () => {
    dispatch(deletenotification({ id: deletid })).then((res: any) => {
      setDelet(false);
    });
  };

  const getMarketDetails = (value: any) => {
    dispatch(getMarketDetail({ market_name: value?.market })).then(
      (res: any) => {
        if (res?.payload?.type === "success") {
          let data = {
            base_currency_short_name:
              res?.payload?.data?.base_currency_short_name,
            target_currency_short_name:
              res?.payload?.data?.target_currency_short_name,
            base_currency_precision:
              res?.payload?.data?.base_currency_precision,
            target_currency_precision:
              res?.payload?.data?.target_currency_precision,
            coindcx_name: res?.payload?.data?.coindcx_name,
          };
          setActionData({
            ...value,
            coindcx_name: res?.payload?.data?.coindcx_name,
          });

          dispatch(getOrderInfo(data));
        }
      }
    );
  };
  const hideUserModal = (value?: any) => {
    if (isShow === true) {
      setActionData("");
    } else {
      getMarketDetails(value);
    }

    setIsShow(!isShow);
  };
  const hideReasonModal = (value?: any) => {
    if (reason === true) {
      setCancelData("");
    } else {
      setCancelData(value);
    }
    setReason(!reason);
  };
  const hideViewOrderModal = (value?: any) => {
    if (viewOrder === true) {
      setCancelData("");
    } else {
      setCancelData(value);
    }
    setViewOrder(!viewOrder);
  };
  const handleDebounceSearch = debounce((value: string) => {
    setSelected({ ...selected, search: value });
    // Perform search operation here
  }, 500);
  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;
    handleDebounceSearch(item);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }
  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "MARKET ",
        accessor: "market",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div className="">
              <div className="text-body align-middle fw-medium d-flex gap-2 ">
                {cell.row.original.market}{" "}
                {cell.row.original?.note && (
                  <OverlayTrigger
                    trigger={["click"]}
                    placement="right"
                    overlay={
                      <Popover className="border border-primary">
                        <Popover.Header
                          as="h3"
                          className="border-b border-primary"
                        >
                          Note
                        </Popover.Header>
                        <Popover.Body className="">
                          {cell.row.original?.note ?? "No Note Found"}
                        </Popover.Body>
                      </Popover>
                    }
                    rootClose={true}
                  >
                    <div className="mt-1">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="right"
                        overlay={<Tooltip>Click to view note</Tooltip>}
                        rootClose={true}
                      >
                        <span>
                          <i className="ri-information-fill ri-lg text-info cursor-pointer">
                            {" "}
                          </i>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
      },

      {
        Header: "TRIGGER PRICE",
        accessor: "trigger_price",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.trigger_price}
              </span>
            </div>
          );
        },
      },
      {
        Header: "CONDITION",
        accessor: "condition",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div>
              <span className="text-dark">
                {cell.row.original.condition === "lteq" ? "<=" : ">="}
              </span>
            </div>
          );
        },
      },

      {
        Header: "CREATED AT",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.created_at);
        },
      },

      {
        Header: "UPDATED AT",
        accessor: "updated_at",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return formatedDateAndTime(cell.row.original.updated_at);
        },
      },
      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original?.is_notified === 0 && (
                <ul className="list-inline hstack gap-2 mb-0">
                  <li className="list-inline-item">
                    <OverlayTrigger
                      trigger={["click", "hover"]}
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-bottom">Edit</Tooltip>}
                      rootClose={true}
                    >
                      <button
                        className="btn btn-soft-secondary btn-sm d-inline-block"
                        type="button"
                        onClick={() => {
                          hideUserModal(cell.row.original);
                        }}
                      >
                        <i className="ri-edit-2-fill me-1 align-middle  ri-lg"></i>
                      </button>
                    </OverlayTrigger>
                  </li>
                  <li className="list-inline-item edit">
                    <OverlayTrigger
                      trigger={["click", "hover"]}
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-bottom">
                          Delete Notification
                        </Tooltip>
                      }
                      rootClose={true}
                    >
                      <button
                        type="button"
                        className="btn btn-soft-danger btn-sm d-inline-block"
                        onClick={() => {
                          handleDeleteModal(cell.row.original.id);
                        }}
                      >
                        <i className="ri-close-circle-fill  ri-lg align-middle"></i>
                      </button>
                    </OverlayTrigger>
                  </li>
                </ul>
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  let data =
    dropDownList === "active_notification"
      ? "active"
      : dropDownList === "completed_notification"
      ? "completed"
      : "active";
  useEffect(() => {
    let orderData = {
      page: selected?.page,
      perPage: selected?.perPage,
      search: selected?.search,
      sort: columnFilter?.name,
      direction: columnFilter?.order === true ? "desc" : "asc",

      filter: data,
    };
    dispatch(getNotification(orderData));
  }, [
    deleteNotificationLoading,
    updateNotificationLoading,
    selected,
    columnFilter,
  ]);
  useEffect(() => {
    setScheduleOrder(notificationList?.data);
  }, [notificationList]);

  return (
    <div>
      <Row>
        <div>
          {notificationLoading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div>
              {scheduleOrder?.length > 0 ? (
                <div className="mt-3">
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    data={scheduleOrder || []}
                    customPageSize={selected?.perPage ?? 50}
                    divClassName="table-card table-responsive"
                    tableClass="table-hover table-nowrap align-middle mb-0"
                    isBordered={false}
                    PaginationClass="align-items-center mt-4 gy-3"
                    selected={selected}
                    setSelected={setSelected}
                    totalPage={notificationList?.totalPages}
                    columnFilter={columnFilter}
                    setColumnFilter={setColumnFilter}
                    columnChnageFilter={columnChnageFilter}
                  />
                </div>
              ) : (
                <NoSearchResult
                  title={
                    notificationList?.message ??
                    ` No ${dropDownList?.split("_").join(" ")}
              Found`
                  }
                />
              )}
            </div>
          )}
        </div>

        <DeleteModal
          title={{ text: "delete notification", btnText: "delete" }}
          show={delet}
          handleClose={handleDeleteModal}
          deleteModalFunction={handleDeleteId}
        />

        <NotifyModal
          isShow={isShow}
          handleClose={hideUserModal}
          actionData={actionData}
        />
      </Row>
    </div>
  );
};

export default NotificationTable;
