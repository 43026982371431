import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { editPrice } from "../../../slices/orders/orderSlice";

interface userProps {
  isShow: boolean;
  handleClose: any;
  actionData: any;
}

const PriceModal = ({ isShow, handleClose, actionData }: userProps) => {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: actionData?.price_per_unit ?? "",
    },
    validationSchema: Yup.object({
      price: Yup.string().required("Please Enter Price Per Unit"),
    }),

    onSubmit: (values: any) => {
      let data = {
        price_per_unit: values?.price,
        id: actionData?.id,
      };

      dispatch(editPrice(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  return (
    <React.Fragment>
      <Modal centered show={isShow} style={{ display: "block" }} tabIndex={-1}>
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5" className="text-capitalize">
              Update Price
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row className="mt-3">
                  <Col lg={12}>
                    <div className="mb-3">
                      <Form.Label htmlFor="price">Price Per Unit</Form.Label>
                      <Form.Control
                        id="price"
                        name="price"
                        placeholder="Enter Price Per Unit"
                        type="number"
                        step={0.1}
                        value={formik.values.price || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.price && formik.touched.price
                            ? true
                            : false
                        }
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.price}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="submit"
                  className={`btn text-capitalize btn-secondary `}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PriceModal;
