import React from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import DashboardList from "./DashboardList";

const Dashboard = () => {
  document.title = "Dashboard | Crypto-Trade-App";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Dashboard" title="Dashboard" />
          <DashboardList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
