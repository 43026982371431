import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";
import { setAuthToken, setUserToken } from "../../helpers/util";

const initialState = {
  loading: false,
  data: [],
  loginData: [],
  error: null,
  resetLoading: false,
  loginLoading: false,
  changePasswordLoading: false,
};

export const login = createAsyncThunk(
  "login",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/login`, data);
      if (response?.type === "success") {
        setAuthToken(response?.data?.token);
        setUserToken(response?.data?.is_admin);
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        showErrorMsg(error);
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetSendEmail = createAsyncThunk(
  "reset-send-Email",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/password/email`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const resetPassword = createAsyncThunk(
  "reset-password",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/password/reset`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const changePassword = createAsyncThunk(
  "change-password",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(`api/password/change`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // login
    builder.addCase(login.pending, (state: any, action: any) => {
      state.loginLoading = true;
    });
    builder.addCase(login.rejected, (state: any, action: any) => {
      state.loginLoading = false;
      state.loginData = [];
      state.error = action.payload || null;
    });
    builder.addCase(login.fulfilled, (state: any, action: any) => {
      state.loginLoading = false;
      state.loginData = action?.payload?.data;
    });
    // send email
    builder.addCase(resetSendEmail.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(resetSendEmail.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(resetSendEmail.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    // reset password
    builder.addCase(resetPassword.pending, (state: any, action: any) => {
      state.resetLoading = true;
    });
    builder.addCase(resetPassword.rejected, (state: any, action: any) => {
      state.resetLoading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(resetPassword.fulfilled, (state: any, action: any) => {
      state.resetLoading = false;
    });
    // change password
    builder.addCase(changePassword.pending, (state: any, action: any) => {
      state.changePasswordLoading = true;
    });
    builder.addCase(changePassword.rejected, (state: any, action: any) => {
      state.changePasswordLoading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(changePassword.fulfilled, (state: any, action: any) => {
      state.changePasswordLoading = false;
    });
  },
});

export default loginSlice.reducer;
