import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getMinusPlus, setAfterDecimal } from "../../../helpers/util";
import {
  addNotification,
  updateNotification,
} from "../../../slices/Notification/NotificationSlice";

interface userProps {
  isShow?: boolean;
  handleClose?: any;
  actionData?: any;
}

const NotifyModal = ({ isShow, handleClose, actionData }: userProps) => {
  const orderInfoData = useSelector(
    (state: any) => state?.market?.orderInfo ?? []
  );
  const addNotificationLoading = useSelector(
    (state: any) => state?.notification?.addNotificationLoading ?? false
  );
  const updateNotificationLoading = useSelector(
    (state: any) => state?.notification?.updateNotificationLoading ?? false
  );

  const dispatch = useDispatch();
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      trigger_price: actionData?.id ? actionData?.trigger_price : "",
      condition: actionData?.id ? actionData?.condition : "lteq",
      note: actionData?.id ? actionData?.note : "",
      current_price: orderInfoData?.data?.current_price,
    },
    validationSchema: Yup.object({
      condition: Yup.string(),
      current_price: Yup.string(),
      trigger_price: Yup.string().when(["condition", "current_price"], {
        is: (condition: any, current_price: any) => {
          if (condition === "lteq" || condition === "gteq") {
            return true;
          }
          return false;
        },
        then: () =>
          Yup.string().test({
            name: "trigger-price-validation",
            test: function (value: any, { parent }: any) {
              const { condition, current_price } = parent;
              if (!value) {
                return this.createError({
                  message: "Please Enter Trigger Price",
                });
              }
              if (condition === "gteq" && value <= Number(current_price)) {
                return this.createError({
                  message:
                    "Trigger price should be greater than or equal to (>=) current price.",
                });
              } else if (
                condition === "lteq" &&
                value >= Number(current_price)
              ) {
                return this.createError({
                  message:
                    "Trigger price should be  less than or equal to (<=) current price.",
                });
              }
              return true;
            },
          }),
      }),
      // note: Yup.string().max(255, "Can't add more than 255 characters"),
    }),
    validateOnChange: true,
    onSubmit: (values: any) => {
      let data: any = {
        ...values,
        market: actionData?.coindcx_name,
      };

      if (actionData?.id) {
        dispatch(updateNotification({ ...data, id: actionData?.id })).then(
          (res: any) => {
            if (res?.payload?.type === "success") {
              handleClose();
              formik.resetForm();
            }
          }
        );
      } else {
        dispatch(addNotification(data)).then((res: any) => {
          if (res?.payload?.type === "success") {
            handleClose();
            formik.resetForm();
          }
        });
      }
    },
  });
  const handleTriggerPriceChange = (event?: any) => {
    formik?.setFieldValue(
      "trigger_price",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("trigger_price", false);
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          centered
          show={isShow}
          style={{ display: "block" }}
          tabIndex={-1}
          className="modal-lg"
        >
          <div className="modal-content">
            <Modal.Header className="pb-0">
              <Modal.Title as="h5" className="text-capitalize">
                <p className={` text-primary`}>Notification</p>
              </Modal.Title>
              <button
                type="button"
                className="btn-close d-flex align-items-start"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
                style={{ marginTop: "-20px" }}
              ></button>
            </Modal.Header>
            <Modal.Body className="p-4">
              {actionData && !actionData?.coindcx_name && (
                <h4 className="d-flex justify-content-center fw-bold text-muted">
                  No Market Available
                </h4>
              )}

              <div className="d-flex gap-4">
                <p className="fs-16">
                  Market :{" "}
                  {actionData?.coindcx_name
                    ? actionData?.coindcx_name
                    : "No Market Available"}
                </p>
                <p className="fs-16">
                  Currency : {orderInfoData?.data?.sell_currency_name}
                </p>

                <p className="fs-16">
                  Balance{": "}
                  {setAfterDecimal(
                    Number(orderInfoData?.data?.sell_currency_balance ?? 0),
                    actionData?.base_currency_precision
                  )}
                  <span className="mx-2">
                    ({orderInfoData?.data?.sell_currency_name})
                  </span>
                </p>
              </div>
              <div className="d-flex gap-4">
                <p className="fs-16">
                  Current Price{":"} {orderInfoData?.data?.current_price ?? 0}
                </p>
                <p className="fs-16">
                  Changes in 24 Hours{":"}{" "}
                  <small
                    className={`badge ${
                      getMinusPlus(orderInfoData?.data?.change_24_hour ?? 0) ===
                      false
                        ? "bg-danger-subtle text-danger"
                        : "bg-primary-subtle text-primary"
                    }   fs-14  mx-2`}
                  >
                    {orderInfoData?.data?.change_24_hour ?? 0}
                  </small>
                </p>
              </div>

              <Form autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Condition </label>
                  <div className="d-flex gap-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="condition"
                        value={"lteq"}
                        checked={formik?.values?.condition === "lteq"}
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                      />
                      <label className="form-check-label">{`<=`}</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="condition"
                        value={"gteq"}
                        checked={formik?.values?.condition === "gteq"}
                        onChange={formik?.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="form-check-label">{`>=`}</label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {/* field for radio  */}

                  <div className="mb-3">
                    <Form.Label htmlFor="trigger_price">
                      Trigger Price
                      <span>
                        ({" "}
                        {formik?.values?.condition === "lteq"
                          ? "  Current Price <= Trigger Price "
                          : "  Current Price >= Trigger Price "}
                        )
                      </span>
                    </Form.Label>
                    <Form.Control
                      id="trigger_price"
                      name="trigger_price"
                      placeholder="Enter Trigger Price"
                      type="number"
                      onChange={handleTriggerPriceChange}
                      value={formik?.values?.trigger_price || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.trigger_price &&
                        formik.touched.trigger_price
                      }
                    />
                    {formik.touched.trigger_price &&
                    formik.errors.trigger_price ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.trigger_price}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="note">Note</Form.Label>
                  <textarea
                    className={`form-control ${
                      formik.touched.note && formik.errors.note
                        ? "is-invalid"
                        : ""
                    }`}
                    rows={3}
                    id="note"
                    name="note"
                    placeholder="Enter Your Note"
                    onChange={formik?.handleChange}
                    value={formik?.values?.note || ""}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.note && formik.errors.note ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.note}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    type="submit"
                    className={`btn text-capitalize btn-secondary `}
                    disabled={
                      addNotificationLoading || updateNotificationLoading
                    }
                  >
                    {(addNotificationLoading || updateNotificationLoading) && (
                      <Spinner size="sm" />
                    )}{" "}
                    {actionData?.id ? "Update" : " Create"}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default NotifyModal;
