import React from "react";
import {  Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import TradeList from "./tradeList";

const TradeDetail = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Market Management" title="Trade Details " />
          <TradeList />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TradeDetail;
