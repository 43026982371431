import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

//profitLossReport
export const profitLossReport = createAsyncThunk(
  "profitLossReport",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/report/graph`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // profit loss report
    builder.addCase(profitLossReport.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(profitLossReport.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(profitLossReport.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

export default reportSlice.reducer;
