import React from "react";
import { Button, Modal } from "react-bootstrap";
import { formatedDateAndTime } from "../../helpers/util";
interface ReasonProps {
  isShow?: boolean;
  handleClose?: any;
  cancelData?: any;
}
export const ViewOrderModal = ({
  isShow,
  handleClose,
  cancelData,
}: ReasonProps) => {

  return (
    <div>
      <Modal show={isShow} centered>
        <div className="modal-content">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5" className="text-capitalize">
              {cancelData?.status} Order
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-5">
            <div>
              {cancelData?.description && (
                <p className="text-muted mb-3">{cancelData?.description}</p>
              )}
              <h5 className="text-muted fw-bold mb-4">Order Detail</h5>
              <div className="d-flex justify-content-between">
                <div className="text-muted fw-bold text-start">
                  <p>Market</p>
                  <p>Currency</p>
                  <p>Trade Currency</p>
                  <p>Filled Quantity </p>
                  <p>Remaining Quantity</p>
                  <p>Total Price</p>
                  <p>Order Type</p>
                  {cancelData?.order?.group_name && <p>Group Name</p>}
                  <p>Side</p>
                  <p>Status</p>
                  <p>Updated At</p>
                </div>
                <div className="text-start">
                  <p>{cancelData?.order?.market}</p>
                  <p>{cancelData?.order?.currency}</p>
                  <p>{cancelData?.order?.trade_currency}</p>
                  <p>{cancelData?.order?.filled_quantity} </p>
                  <p>{cancelData?.order?.remaining_quantity}</p>
                  <p>{cancelData?.order?.total_price}</p>
                  <p>{cancelData?.order?.order_type?.split("_")?.join(" ")}</p>
                  {cancelData?.order?.group_name && (
                    <p>{cancelData?.order?.group_name}</p>
                  )}
                  <p>{cancelData?.order?.side}</p>
                  <p>{cancelData?.order?.status}</p>
                  <p>{formatedDateAndTime(cancelData?.order?.updated_at)}</p>
                </div>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <Button className="btn btn-secondary" onClick={handleClose}>
                  Ok
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};
