import { createSlice } from "@reduxjs/toolkit";
import {
  LAYOUT_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
} from "../../Common/constants/layout";

export interface LayoutState {
  layoutType: LAYOUT_TYPES.HORIZONTAL | LAYOUT_TYPES.VERTICAL;
  leftsidbarSizeType:
    | LEFT_SIDEBAR_SIZE_TYPES.DEFAULT
    | LEFT_SIDEBAR_SIZE_TYPES.COMPACT
    | LEFT_SIDEBAR_SIZE_TYPES.SMALLICON
    | LEFT_SIDEBAR_SIZE_TYPES.SMALLHOVER;
}

export const initialState: LayoutState = {
  layoutType: LAYOUT_TYPES.VERTICAL,
  leftsidbarSizeType: LEFT_SIDEBAR_SIZE_TYPES.DEFAULT,
};

const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    changeLayoutAction(state: any, action: any) {
      state.layoutType = action.payload;
    },

    changeLeftsidebarSizeTypeAction(state: any, action: any) {
      state.leftsidbarSizeType = action.payload;
    },
  },
});

export const { changeLayoutAction, changeLeftsidebarSizeTypeAction } =
  LayoutSlice.actions;

export default LayoutSlice.reducer;
