import React from "react";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch} from "react-redux";
import { resetSendEmail } from "../../slices/login/loginSlice";

const ForgotPassword = (props: any) => {
  const dispatch = useDispatch();

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values: any) => {
      dispatch(resetSendEmail(values));
    },
  });

  document.title = "Forgot Password | Crypto-Trade-App";

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5">
                      <Link to="/">
                        <span className="logo-lg">
                          <img
                            width="25"
                            height="25"
                            src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                            alt="logo"
                          />
                          <small className="text-muted fs-18">
                            Crypto Trade App
                          </small>
                        </span>
                      </Link>
                    </div>

                    <Card className="my-auto overflow-hidden">
                      <Row className="g-0">
                        <Col lg={12}>
                          <div className="p-lg-5 p-4">
                            <div className="text-center">
                              <h5 className="mb-0">Forgot Password?</h5>
                            </div>

                            <div className="text-center py-4">
                              <div
                                className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                                role="alert"
                              >
                                Enter your email and instructions will be sent
                                to you!
                              </div>
                            </div>

                            <div className="mt-4">
                              <Form
                                action=""
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}
                              >
                                <Form.Group className="mb-3">
                                  <Form.Label>Email </Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    className="form-control bg-light border-light password-input"
                                    id="email"
                                    autoComplete="off"
                                    placeholder="Please Enter Email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    isInvalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <Form.Control.Feedback type="invalid">
                                      {validation.errors.email}
                                    </Form.Control.Feedback>
                                  ) : null}
                                </Form.Group>

                                <div className="mt-2">
                                  <button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                  >
                                    Send Link
                                  </button>
                                </div>

                                <div className="mt-4 text-center">
                                  <p className="mb-0">
                                    Wait, I remember my password...{" "}
                                    <Link
                                      to="/login"
                                      className="fw-medium text-primary text-decoration-underline"
                                    >
                                      {" "}
                                      Click here{" "}
                                    </Link>{" "}
                                  </p>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Card>

                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        &copy; {new Date().getFullYear()} Crypto Trade App
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
