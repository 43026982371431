import React, { useEffect, useState } from "react";
// import logoDark from "../../assets/images/logo-dark.png";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../slices/login/loginSlice";

const ResetPassword = (props: any) => {
  document.title = "Forgot Password | Crypto-Trade-App";
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams?.get(`email`);
  const paramToken = searchParams?.get(`token`);
  const [passwordField, setPasswordField] = useState<any>({
    password: false,
    password_confirmation: false,
  });

  const navigate = useNavigate();
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: paramEmail ? paramEmail : "",
      password: "",
      confimPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter  Email"),
      password: Yup.string().required("Please Enter Password"),
      confimPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
        .required("Please Enter Confirm Password"),
    }),
    onSubmit: (values: any) => {
      const data = {
        email: values?.email,
        password: values?.password,
        password_confirmation: values?.confimPassword,
        token: paramToken,
      };

      dispatch(resetPassword(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          navigate("/login");
        }
      });
    },
  });
  useEffect(() => {
    if (!paramEmail || !paramToken) {
      navigate("/login");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5">
                      <Link to="/">
                        <span className="logo-lg">
                          <img
                            width="25"
                            height="25"
                            src="https://img.icons8.com/fluency/48/000000/peercoin.png"
                            alt="logo"
                          />
                          <small className="text-muted fs-18">
                            Crypto Trade App
                          </small>
                        </span>
                      </Link>
                    </div>

                    <Card className="my-auto overflow-hidden">
                      <Row className="g-0">
                        <Col lg={12}>
                          <div className="p-lg-5 p-4">
                            <div className="text-center">
                              <h5 className="mb-0">Reset Password?</h5>
                              <p className="text-muted mt-2">
                                Reset password with Crypto Trade App
                              </p>
                            </div>

                            <div className="mt-4">
                              <Form
                                action=""
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  formik.handleSubmit();
                                  return false;
                                }}
                              >
                                <Form.Group className="mb-3">
                                  <div className="mb-3">
                                    <Form.Label htmlFor="Email-input">
                                      Email
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="email"
                                      className="form-control bg-light border-light"
                                      placeholder="Please Enter email"
                                      value={formik.values.email || ""}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      isInvalid={
                                        !!formik.errors.email &&
                                        !!formik.touched.email
                                      }
                                      readOnly
                                    />
                                    {formik.errors.email &&
                                    formik.touched.email ? (
                                      <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                      </Form.Control.Feedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                    <Form.Label htmlFor="Email-input">
                                      Password
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                      <Form.Control
                                        type={
                                          passwordField?.password
                                            ? "text"
                                            : "password"
                                        }
                                        name="password"
                                        placeholder="Please Enter password"
                                        className="form-control bg-light border-light pe-5 password-input"
                                        value={formik.values.password || ""}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                          !!formik.errors.password &&
                                          !!formik.touched.password
                                        }
                                      />
                                      {formik.errors.password &&
                                      formik.touched.password ? (
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.password}
                                        </Form.Control.Feedback>
                                      ) : null}
                                      <button
                                        className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                                        type="button"
                                        id="password-addon"
                                        style={{
                                          top: "0px",
                                          right: "15px",
                                        }}
                                      >
                                        <i
                                          onClick={() =>
                                            setPasswordField({
                                              ...passwordField,
                                              password:
                                                !passwordField?.password,
                                            })
                                          }
                                          className={`${
                                            !passwordField?.password
                                              ? `ri-eye-fill`
                                              : `ri-eye-off-line`
                                          }  align-middle`}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div className=" mb-3">
                                    <Form.Label htmlFor="Email-input">
                                      Confirm Password
                                      <span className="text-danger">*</span>
                                    </Form.Label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                      <Form.Control
                                        type={
                                          passwordField?.password_confirmation
                                            ? "text"
                                            : "password"
                                        }
                                        name="confimPassword"
                                        className="form-control bg-light border-light pe-5 password-input"
                                        placeholder="Please Enter confim Password"
                                        value={
                                          formik.values.confimPassword || ""
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isInvalid={
                                          !!formik.errors.confimPassword &&
                                          !!formik.touched.confimPassword
                                        }
                                      />
                                      {formik.errors.confimPassword &&
                                      formik.touched.confimPassword ? (
                                        <Form.Control.Feedback type="invalid">
                                          {formik.errors.confimPassword}
                                        </Form.Control.Feedback>
                                      ) : null}
                                      <button
                                        className="btn btn-link position-absolute text-decoration-none text-muted password-addon"
                                        type="button"
                                        id="password-addon"
                                        style={{
                                          top: "0px",
                                          right: "15px",
                                        }}
                                      >
                                        <i
                                          onClick={() =>
                                            setPasswordField({
                                              ...passwordField,
                                              password_confirmation:
                                                !passwordField?.password_confirmation,
                                            })
                                          }
                                          className={`${
                                            !passwordField?.password_confirmation
                                              ? `ri-eye-fill`
                                              : `ri-eye-off-line`
                                          }  align-middle `}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </Form.Group>

                                <div className="mt-2">
                                  <button
                                    className="btn btn-primary w-100"
                                    type="submit"
                                  >
                                    Reset Password
                                  </button>
                                </div>

                                <div className="mt-4 text-center">
                                  <p className="mb-0">
                                    Wait, I remember my password...{" "}
                                    <Link
                                      to="/login"
                                      className="fw-medium text-primary text-decoration-underline"
                                    >
                                      {" "}
                                      Click here{" "}
                                    </Link>{" "}
                                  </p>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>

                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        &copy; {new Date().getFullYear()} Crypto Trade App
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
