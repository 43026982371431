import React from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Container } from "react-bootstrap";
import CurrencyTable from "./CurrencyTable";

const Currency = () => {
  document.title = "Currency | Crypto-Trade-App";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Setting" title="Currency" />
          <CurrencyTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Currency;
