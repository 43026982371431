import React, { useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Card, Container, Form, Nav, Tab } from "react-bootstrap";
import GroupOrderCard from "./GroupOrderCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dropDownStyle } from "../../../helpers/util";
import { useSelector } from "react-redux";
import Select from "react-select";

const GroupOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab: any = searchParams.get("tab");
  const groupOrderList = useSelector(
    (state: any) => state?.groupOrder?.data ?? []
  );
  const totalTabs = [
    "group_order",
    "active_group_order",
    "completed_group_order",
  ];
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState<any>(
    totalTabs?.includes(currentActiveTab)
      ? currentActiveTab
      : "active_group_order"
  );
  const [market, setMarket] = useState<any>([]);
  const [perPage, setPerPage] = useState<any>(100);

  const [selectedOption, setSelectedOption] = useState<any>("All Market");
  const changeFilter = (value: any) => {
    setSelectedOption(value?.value);
  };
  const handlePageShow = (event?: any) => {
    setPerPage(event?.target?.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle="Market Management"
            title={`${dropDownList?.split("_").join(" ")}s`}
          />
          <Card>
            <Card.Body>
              <div>
                <div className="mb-2 d-flex justify-content-end gap-4 ">
                  <div>
                    <div className="shadow shadow-lg shadow-primary">
                      <Select
                        value={{ label: selectedOption }}
                        onChange={changeFilter}
                        placeholder={<div>Type to search</div>}
                        options={market?.map((marketData: any) => {
                          return {
                            label: marketData,
                            value: marketData,
                          };
                        })}
                        styles={dropDownStyle()}
                      />
                    </div>
                  </div>
                  <div>
                    <Form.Select
                      className="shadow shadow-lg shadow-primary"
                      defaultValue={1}
                      onChange={handlePageShow}
                      style={{ border: "1px solid #159443" }}
                    >
                      {[100, 200, 300, 400, 500]?.map(
                        (selectTab: any, selectIndex: number) => (
                          <option value={selectTab} key={selectIndex}>
                            {selectTab}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </div>
                </div>
                <div className="d-flex justify-content-end m-0">
                  <p className="m-0">
                    Total Groups :{" "}
                    <span>{groupOrderList?.data?.total_group_orders ?? 0}</span>
                  </p>
                </div>
              </div>
              <Tab.Container defaultActiveKey={dropDownList}>
                <Nav
                  as="ul"
                  variant="tabs"
                  className="nav-tabs-custom nav-primary  mb-3"
                >
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="group_order"
                      onClick={() => {
                        setDropDownList("group_order");
                        navigate(`/group_order?tab=group_order`);
                        setSelectedOption("All Market");
                      }}
                    >
                      All Group Orders
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="active_group_order"
                      onClick={() => {
                        setDropDownList("active_group_order");
                        navigate(`/group_order?tab=active_group_order`);
                        setSelectedOption("All Market");
                      }}
                    >
                      Active Group Orders
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="completed_group_order"
                      onClick={() => {
                        setDropDownList("completed_group_order");
                        navigate(`/group_order?tab=completed_group_order`);
                        setSelectedOption("All Market");
                      }}
                    >
                      Completed Group Orders
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="text-primary ">
                  <Tab.Pane eventKey="group_order">
                    {dropDownList === "group_order" && (
                      <GroupOrderCard
                        tab={selectedOption}
                        setTab={setSelectedOption}
                        setMarket={setMarket}
                        perPage={perPage}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="active_group_order">
                    {dropDownList === "active_group_order" && (
                      <GroupOrderCard
                        dropDownList={dropDownList}
                        tab={selectedOption}
                        setTab={setSelectedOption}
                        setMarket={setMarket}
                        perPage={perPage}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="completed_group_order">
                    {dropDownList === "completed_group_order" && (
                      <GroupOrderCard
                        dropDownList={dropDownList}
                        tab={selectedOption}
                        setTab={setSelectedOption}
                        setMarket={setMarket}
                        perPage={perPage}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupOrder;
