import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDashboard } from "../../slices/dashboard/dashboardSlice";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DashboardList = () => {
  const dispatch = useDispatch();
  const [dashboardList, setDashboardList] = useState<any>();
  // get cuurent logged in user
  const userData = useSelector(
    (state?: any) => state?.user?.profileUserData ?? []
  );

  const dashboardData = useSelector(
    (state: any) => state?.dashboard?.data ?? []
  );
  useEffect(() => {
    dispatch(getDashboard({}));
  }, []);

  useEffect(() => {
    setDashboardList(dashboardData?.data?.order_data);
  }, [dashboardData]);
  return (
    <div>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Card id="demo">
            <Card.Body>
              <Row>
                <Col md={4} className="mb-2">
                  <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                    <div>
                      <p className="font-bold fs-18 text-muted mt-2">
                        Total Keys
                      </p>
                    </div>
                    <div>
                      <small className="badge bg-secondary-subtle text-secondary fs-18">
                        {dashboardData?.data?.total_keys ?? 0}
                      </small>
                      {dashboardData?.data?.total_keys === 0 && (
                        <Link to="/secret">
                          <small className="mx-2 badge bg-secondary-subtle text-secondary fs-18 ">
                            Add Key
                          </small>
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
                {userData?.is_admin === 1 && (
                  <Col md={4} className="mb-2">
                    <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                      <p className="font-bold fs-18 text-muted mt-2">
                        Total Users
                      </p>
                      <small className="badge bg-secondary-subtle text-secondary fs-18">
                        {dashboardData?.data?.total_users ?? 0}
                      </small>
                    </div>
                  </Col>
                )}
              </Row>
              {dashboardList?.length > 0 &&
                dashboardList?.map((dashboard: any, index: number) => (
                  <div key={index} className="mb-2">
                    <Row>
                      <Col md={12}>
                        <div className="d-flex gap-2">
                          <p className="font-bold fs-18 text-capitalize text-muted mt-2 mb-0">
                            Key Name {`>`}
                          </p>
                          <p className="font-bold fs-18 text-uppercase text-muted mt-2">
                            {dashboard?.key_name}
                          </p>
                        </div>
                      </Col>
                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="fs-18 text-muted mt-2">Total Orders</p>
                          <small className="badge bg-success-subtle text-success fs-18">
                            {dashboard?.total_order ?? 0}
                          </small>
                        </div>
                      </Col>
                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Active Order
                          </p>
                          <small className="badge bg-primary-subtle text-primary fs-18">
                            {dashboard?.active_order ?? 0}
                          </small>
                        </div>
                      </Col>

                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Group
                          </p>
                          <small className="badge bg-secondary-subtle text-secondary fs-18">
                            {dashboard?.total_groups ?? 0}
                          </small>
                        </div>
                      </Col>

                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="fs-18 text-muted mt-2">
                            Total Buy Orders
                          </p>
                          <small className="badge bg-primary-subtle text-primary fs-18">
                            {dashboard?.total_buy_order ?? 0}
                          </small>
                        </div>
                      </Col>
                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Sell Orders
                          </p>
                          <small className="badge bg-danger-subtle text-danger fs-18">
                            {dashboard?.total_sell_order ?? 0}
                          </small>
                        </div>
                      </Col>
                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Active Schedule Order
                          </p>
                          <small className="badge bg-secondary-subtle text-secondary fs-18">
                            {dashboard?.total_schedule_orders ?? 0}
                          </small>
                        </div>
                      </Col>

                      <Col md={4} className="mb-2">
                        <div className="rounded  d-flex align-items-center justify-content-between gap-2 bg-light-subtle shadow-lg px-2">
                          <p className="font-bold fs-18 text-muted mt-2">
                            Total Active Notification
                          </p>
                          <small className="badge bg-secondary-subtle text-secondary fs-18">
                            {dashboard?.total_active_market_notification ?? 0}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardList;
