import { Navigate } from "react-router-dom";

import { getAuthToken } from "../helpers/util";

const AuthProtected = (props: any) => {
  const auth = getAuthToken() ? getAuthToken() : null;
  if (!auth) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <>{props.children}</>;
};

export { AuthProtected };
