import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { testSetting } from "../../slices/settingSlice/SettingSlice";
import { useSelector } from "react-redux";

interface userProps {
  isShow: boolean;
  handleClose: any;
}

const TestSmtpModal = ({ isShow, handleClose }: userProps) => {
  const testSettingLoading = useSelector(
    (state: any) => state?.getSetting?.testSettingLoading ?? false
  );

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
    }),

    onSubmit: (values: any) => {
      const testMail = {
        email: values?.email ?? "",
      };

      dispatch(testSetting(testMail)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  return (
    <React.Fragment>
      <Modal centered show={isShow} style={{ display: "block" }} tabIndex={-1}>
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Test SMTP Mail Setting</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  placeholder="Enter Your email"
                  autoComplete="off"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="submit"
                  className="btn btn-secondary"
                  disabled={testSettingLoading}
                >
                  {testSettingLoading && <Spinner size="sm" />}Send Email
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TestSmtpModal;
