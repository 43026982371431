import React, { useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import OrderTable from "./OrderTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { dropDownStyle } from "../../../helpers/util";

const Order = () => {
  document.title = "Orders | Crypto-Trade-App";
  const [searchParams, setSearchParams] = useSearchParams();
  const currentActiveTab: any = searchParams.get("tab");
  const totalTabs = ["all_order", "active_order"];
  const navigate = useNavigate();
  const [dropDownList, setDropDownList] = useState<any>(
    totalTabs?.includes(currentActiveTab) ? currentActiveTab : "active_order"
  );
  const [groupList, setGroupList] = useState<any>([]);

  const [selectedOption, setSelectedOption] = useState<any>({
    key: "All Orders",
    value: "all",
  });
  const [groupSelect, setGroupSelect] = useState<any>("All Group");

  const changeFilter = (type: string, value: any) => {
    setSelectedOption({ key: type, value: value });
  };
  const changeGroupFilter = (value: any) => {
    setGroupSelect(value);
  };
  const options = [
    { label: "All Orders", value: "all" },
    { label: "Linked Orders", value: "linked" },
    { label: "Unlinked Orders", value: "unlinked" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle="Market Management"
            title={`${dropDownList?.split("_").join(" ")}s`}
          />

          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col>
                        <div className="d-flex justify-content-end gap-4 m-0">
                          <div>
                            {" "}
                            <div className="shadow shadow-lg shadow-primary">
                              <Select
                                placeholder={"Filter Group List"}
                                value={{
                                  label: groupSelect?.value ?? "All Group",
                                }}
                                onChange={changeGroupFilter}
                                options={groupList?.map((groupData: any) => {
                                  return {
                                    label: groupData?.name,
                                    value: groupData?.name,
                                    ...groupData,
                                  };
                                })}
                                styles={dropDownStyle()}
                              />
                            </div>
                          </div>

                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                as="button"
                                className="btn bg-white fw-bold fs-14 p-2 shadow shadow-lg shadow-primary"
                                style={{ border: "1px solid #159443" }}
                              >
                                {selectedOption?.key ?? "All Orders"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-end dropdownmenu-primary">
                                {options?.map(
                                  (selectTab: any, selectIndex: number) => (
                                    <li key={selectIndex}>
                                      <Dropdown.Item
                                        onClick={() => {
                                          changeFilter(
                                            selectTab?.label,
                                            selectTab?.value
                                          );
                                        }}
                                      >
                                        {selectTab?.label}
                                      </Dropdown.Item>
                                    </li>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Tab.Container defaultActiveKey={dropDownList}>
                      <Nav
                        as="ul"
                        variant="tabs"
                        className="nav-tabs-custom nav-primary mb-2"
                      >
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey="all_order"
                            onClick={() => {
                              setDropDownList("all_order");
                              navigate("/orders?tab=all_order");
                              setSelectedOption({
                                key: "All Orders",
                                value: "all",
                              });
                              setGroupSelect("All Group");
                            }}
                          >
                            All Orders
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey="active_order"
                            onClick={() => {
                              setDropDownList("active_order");
                              navigate("/orders?tab=active_order");
                              setSelectedOption({
                                key: "All Orders",
                                value: "all",
                              });
                              setGroupSelect("All Group");
                            }}
                          >
                            Active Orders
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="text-primary">
                        <Tab.Pane eventKey="all_order">
                          {dropDownList === "all_order" && (
                            <OrderTable
                              dropDownList={dropDownList}
                              selectedOption={selectedOption}
                              setGroupList={setGroupList}
                              groupSelect={groupSelect}
                              setSelectedOption={setSelectedOption}
                              setGroupSelect={setGroupSelect}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="active_order">
                          {dropDownList === "active_order" && (
                            <OrderTable
                              dropDownList={dropDownList}
                              selectedOption={selectedOption}
                              setGroupList={setGroupList}
                              groupSelect={groupSelect}
                              setSelectedOption={setSelectedOption}
                              setGroupSelect={setGroupSelect}
                            />
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Order;
