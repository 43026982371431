import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createOrder } from "../../../slices/orders/orderSlice";
import { getMinusPlus, setAfterDecimal } from "../../../helpers/util";

interface userProps {
  isShow?: boolean;
  handleClose?: any;
  actionData?: any;
}

const ScheduleModal = ({ isShow, handleClose, actionData }: userProps) => {
  const [orderType, setOrderType] = useState("buy");
  const orderInfoData = useSelector(
    (state: any) => state?.market?.orderInfo ?? []
  );

  const dispatch = useDispatch();
  const buyValue =
    Number(orderInfoData?.data?.current_price) -
    (Number(orderInfoData?.data?.current_price) * 5) / 100;
  const sellValue =
    Number(orderInfoData?.data?.current_price) +
    (Number(orderInfoData?.data?.current_price) * 5) / 100;

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: "",
      price_per_unit:
        orderType === "buy"
          ? setAfterDecimal(buyValue, actionData?.base_currency_precision)
          : orderType === "sell"
          ? setAfterDecimal(sellValue, actionData?.base_currency_precision)
          : "",
      trigger_price:
        orderType === "buy" ? buyValue : orderType === "sell" ? sellValue : "",
      orderType: orderType,
      current_price: orderInfoData?.data?.current_price,
      payable_amount: "",
      min_notional: actionData?.min_notional ?? "",
      note: "",
    },
    validationSchema: Yup.object({
      orderType: Yup.string(),
      current_price: Yup.string(),
      quantity: Yup.number().required("Please Enter Quantity"),
      price_per_unit: Yup.string().when(["orderType", "trigger_price"], {
        is: (orderType: any, trigger_price: any) => {
          // Check if trigger price validation should be applied based on order type
          if (orderType === "buy" || orderType === "sell") {
            return true;
          }
          return false;
        },
        then: () =>
          Yup.string().test({
            name: "trigger-price-validation",
            test: function (value: any, { parent }: any) {
              const { orderType, trigger_price } = parent;
              if (!value) {
                return this.createError({
                  message: "Please Enter Price Per Unit",
                });
              }
              if (orderType === "sell" && value < trigger_price) {
                return this.createError({
                  message:
                    "Price per unit must be greater than trigger price for sell orders",
                });
              } else if (orderType === "buy" && value > trigger_price) {
                return this.createError({
                  message:
                    "Price per unit must be less than trigger price for buy orders",
                });
              }
              return true;
            },
          }),
      }),
      trigger_price: Yup.string().when(["orderType", "current_price"], {
        is: (orderType: any, current_price: any) => {
          if (orderType === "buy" || orderType === "sell") {
            return true;
          }
          return false;
        },
        then: () =>
          Yup.string().test({
            name: "trigger-price-validation",
            test: function (value: any, { parent }: any) {
              const { orderType, current_price } = parent;
              if (!value) {
                return this.createError({
                  message: "Please Enter Trigger Price",
                });
              }
              if (orderType === "sell" && value <= current_price) {
                return this.createError({
                  message:
                    "Trigger price must be greater than  to current price for sell orders",
                });
              } else if (orderType === "buy" && value >= current_price) {
                return this.createError({
                  message:
                    "Trigger price must be less than  to current price for buy orders",
                });
              }
              return true;
            },
          }),
      }),
      payable_amount: Yup.string().when(["orderType", "min_notional"], {
        is: (orderType: any, min_notional: any) => {
          if (orderType === "buy" || orderType === "sell") {
            return true;
          }
          return false;
        },
        then: () =>
          Yup.string().test({
            name: "payable-amount-validation",
            test: function (value: any, { parent }: any) {
              const { orderType, min_notional } = parent;

              if (orderType === "buy" && value < min_notional) {
                return this.createError({
                  message: `Payable Amount must be greater than or equal to ${min_notional}`,
                });
              }
              return true;
            },
          }),
      }),
    }),
    validateOnChange: true,
    onSubmit: (values: any) => {
      let data: any = {
        side: orderType,
        order_type: "schedule_order",
        market: actionData?.coindcx_name,
        price_per_unit: Number(values?.price_per_unit),
        total_quantity: Number(values?.quantity),
        trigger_price: Number(values?.trigger_price),
        base_currency_precision: orderInfoData?.data?.base_currency_precision,
        current_price: Number(orderInfoData?.data?.current_price),
      };

      dispatch(createOrder(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });

  const handleOrderTypeChange = (event: any) => {
    setOrderType(event.target.value);
    formik?.setFieldValue("quantity", "");
    formik?.setFieldTouched("quantity", false);
    formik?.setFieldValue("trigger_price", "");
    formik?.setFieldTouched("trigger_price", false);
    formik?.setFieldValue("price_per_unit", orderInfoData?.data?.current_price);
    formik?.setFieldTouched("price_per_unit", false);
  };

  const calculatePayableAmount = (quantity?: any, pricePerUnit?: any) => {
    const amount = quantity * pricePerUnit;
    formik?.setFieldValue("payable_amount", amount == 0 ? "" : amount);
  };
  const handlePriceChange = (event?: any) => {
    formik?.setFieldValue(
      "price_per_unit",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("price_per_unit", false);
    calculatePayableAmount(formik?.values?.quantity, event?.target?.value);
  };

  const handleQuantityChange = (event?: any) => {
    formik?.setFieldValue(
      "quantity",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("quantity", false);
    calculatePayableAmount(
      event?.target?.value >= 0 && event?.target?.value,
      formik?.values?.price_per_unit
    );
  };
  const handleAmountChange = (event?: any) => {
    formik?.setFieldValue(
      "payable_amount",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("payable_amount", false);
    let updateQuantity =
      event?.target?.value >= 0 &&
      event?.target?.value / formik?.values?.price_per_unit;
    formik?.setFieldValue(
      "quantity",
      updateQuantity === 0 ? "" : updateQuantity
    );
  };
  const handleTriggerPriceChange = (event?: any) => {
    formik?.setFieldValue(
      "trigger_price",
      event?.target?.value >= 0 && event?.target?.value
    );
    formik?.setFieldTouched("trigger_price", false);
  };

  return (
    <React.Fragment>
      <div style={{ width: "80%" }}>
        <Modal
          centered
          show={isShow}
          style={{ display: "block" }}
          tabIndex={-1}
          className="modal-lg"
        >
          <div className="modal-content">
            <Modal.Header className="pb-0">
              <Modal.Title as="h5" className="text-capitalize">
                <p>
                  {/* {orderType === "buy" ? "Buy" : "Sell"} */}
                  Create Schedule Order
                </p>
              </Modal.Title>
              <button
                type="button"
                className="btn-close d-flex align-items-start"
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                  setOrderType("buy");
                }}
                style={{ marginTop: "-20px" }}
              ></button>
            </Modal.Header>
            <Modal.Body className="p-4">
              {actionData && !actionData?.coindcx_name && (
                <h4 className="d-flex justify-content-center fw-bold text-muted">
                  No Market Available
                </h4>
              )}

              <div className="d-flex gap-4">
                <p className="fs-16">
                  Market :{" "}
                  {actionData?.coindcx_name
                    ? actionData?.coindcx_name
                    : "No Market Available"}
                </p>
                <p className="fs-16">
                  Currency : {orderInfoData?.data?.sell_currency_name}
                </p>

                <p className="fs-16">
                  Balance{": "}
                  {orderType === "buy"
                    ? setAfterDecimal(
                        Number(orderInfoData?.data?.buy_currency_balance ?? 0),
                        actionData?.base_currency_precision
                      )
                    : setAfterDecimal(
                        Number(orderInfoData?.data?.sell_currency_balance ?? 0),
                        actionData?.base_currency_precision
                      )}
                  <span className="mx-2">
                    (
                    {orderType === "buy"
                      ? orderInfoData?.data?.buy_currency_name
                      : orderInfoData?.data?.sell_currency_name}
                    )
                  </span>
                </p>
              </div>
              <div className="d-flex gap-4">
                <p className="fs-16">
                  Current Price{":"} {orderInfoData?.data?.current_price ?? 0}
                </p>
                <p className="fs-16">
                  Changes in 24 Hours{":"}{" "}
                  <small
                    className={`badge ${
                      getMinusPlus(orderInfoData?.data?.change_24_hour ?? 0) ===
                      false
                        ? "bg-danger-subtle text-danger"
                        : "bg-primary-subtle text-primary"
                    }   fs-14  mx-2`}
                  >
                    {orderInfoData?.data?.change_24_hour ?? 0}
                  </small>
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label">Side </label>
                <div className="d-flex gap-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="orderType"
                      value="buy"
                      checked={orderType === "buy"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className="form-check-label">Buy</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="orderType"
                      value="sell"
                      checked={orderType === "sell"}
                      onChange={handleOrderTypeChange}
                    />
                    <label className="form-check-label">Sell</label>
                  </div>
                </div>
              </div>
              <Form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Row className="mb-3">
                  {/* field for radio  */}
                  <Col lg={6} className="mb-3">
                    <Form.Label htmlFor="price_per_unit">
                      Price Per Unit
                    </Form.Label>
                    <Form.Control
                      id="price_per_unit"
                      name="price_per_unit"
                      placeholder="Enter Price Per Unit"
                      style={{
                        cursor: `${
                          orderType === "market" ? "not-allowed" : ""
                        }`,
                      }}
                      type="number"
                      value={formik?.values?.price_per_unit || ""}
                      onChange={handlePriceChange}
                      disabled={orderType === "market"}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.price_per_unit &&
                        formik.touched.price_per_unit
                      }
                    />
                    {formik.touched.price_per_unit &&
                    formik.errors.price_per_unit ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.price_per_unit}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Form.Label htmlFor="trigger_price">
                      Trigger Price{" "}
                      <span>
                        ({" "}
                        {orderType === "buy"
                          ? " <= Current Price "
                          : " >= Current Price "}
                        )
                      </span>
                    </Form.Label>
                    <Form.Control
                      id="trigger_price"
                      name="trigger_price"
                      placeholder="Enter Trigger Price"
                      type="number"
                      onChange={(e) => {
                        handleTriggerPriceChange(e);
                      }}
                      value={formik?.values?.trigger_price || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.trigger_price &&
                        formik.touched.trigger_price
                      }
                    />
                    {formik.touched.trigger_price &&
                    formik.errors.trigger_price ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.trigger_price}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <Form.Label htmlFor="quantity">Total Quantity</Form.Label>
                    <Form.Control
                      id="quantity"
                      name="quantity"
                      placeholder="Enter Total Quantity"
                      type="number"
                      onChange={handleQuantityChange}
                      value={formik?.values?.quantity || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.quantity && formik.touched.quantity
                      }
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.quantity}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Form.Label htmlFor="payable_amount">
                      Payable Amount
                    </Form.Label>
                    <Form.Control
                      id="payable_amount"
                      name="payable_amount"
                      placeholder="Enter Payable Amount"
                      type="number"
                      onChange={handleAmountChange}
                      value={formik?.values?.payable_amount || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.payable_amount &&
                        formik.touched.payable_amount
                      }
                    />
                    {formik.touched.payable_amount &&
                    formik.errors.payable_amount ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.payable_amount}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col lg={12} className="mb-3">
                    <Form.Label htmlFor="note">Note</Form.Label>
                    <textarea
                      className={`form-control ${
                        formik.touched.note && formik.errors.note
                          ? "is-invalid"
                          : ""
                      }`}
                      rows={3}
                      id="note"
                      name="note"
                      placeholder="Enter Your Note"
                      onChange={formik?.handleChange}
                      value={formik?.values?.note || ""}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.note}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                </Row>

                <div className="hstack gap-2 justify-content-end">
                  <Button
                    type="submit"
                    className={`btn text-capitalize ${
                      orderType === "buy" ? "btn-primary" : "btn-danger"
                    } `}
                    disabled={!actionData?.coindcx_name}
                  >
                    {orderType}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ScheduleModal;
