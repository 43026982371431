import { changeHTMLAttribute } from "./utils";
import { changeLayoutAction, changeLeftsidebarSizeTypeAction } from "./reducer";

/**
 * Changes the layout type
 * @param {*} param0
 */
export const changeLayout = (layout: any) => async (dispatch: any) => {
  try {
    if (layout === "horizontal") {
      document.documentElement.removeAttribute("data-sidebar-size");
    }
    changeHTMLAttribute("data-layout", layout);
    dispatch(changeLayoutAction(layout));
  } catch (error) {}
};

/**
 * Changes the left sidebar size
 * @param {*} param0
 */
export const changeLeftsidebarSizeType =
  (leftsidebarSizetype: any) => async (dispatch: any) => {
    try {
      switch (leftsidebarSizetype) {
        case "lg":
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case "md":
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case "sm":
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        case "sm-hover":
          changeHTMLAttribute("data-sidebar-size", "sm-hover");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
      dispatch(changeLeftsidebarSizeTypeAction(leftsidebarSizetype));
    } catch (error) {
      return error;
    }
  };
