import { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { dropDownStyle } from "../../../helpers/util";
import BreadCrumb from "../../../Common/BreadCrumb";
import Select from "react-select";
import { getSecretKey } from "../../../slices/settingSlice/UserSettingSlice";
import { ImportFile } from "../../../slices/scheduleOrder/scheduleOrderSlice";
import { ImportCsvModal } from "./ImportCsvModal";
import { showErrorMsg } from "../../../Common/Toast/Toast";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";

const ImportCsv = () => {
  document.title = "Import Order | Crypto-Trade-App";

  const dispatch: any = useDispatch();
  const [selectedFiles, setselectedFiles] = useState([]);

  const [selectKey, setSelectKey] = useState<any>("");
  const secretKey = useSelector((state: any) => state?.userSetting?.secretData);
  const importLoading = useSelector(
    (state: any) => state?.scheduleOrder?.importLoading
  );

  const [importModalShow, setImportModalShow] = useState<boolean>(false);
  const [actionData, setActionData] = useState<any>("");

  const [users, setUsers] = useState<any>([]);
  const fileInputRef = useRef<any>(null);
  useEffect(() => {
    dispatch(getSecretKey({}));
  }, []);
  useEffect(() => {
    setUsers(secretKey?.data);
  }, [secretKey]);

  function handleAcceptedFiles(event?: any, value?: any) {
    const files = Array.from(event.target.files);
    const formattedFiles: any = files.map((file?: any) => ({
      ...file,
      preview: value ?? URL.createObjectURL(file),
      formattedSize: formatBytes(file.size), // Assuming formatBytes is a function that formats file size
    }));
    setselectedFiles(value ? formattedFiles : []);
  }
  const handleFileUpload = (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      const fileType = file?.name
        .slice(file?.name.lastIndexOf(".") + 1)
        .toLowerCase();
      if (["csv", "xls", "xlxs"]?.includes(fileType)) {
        let fileImg = "https://img.icons8.com/officel/30/document.png";
        formik?.setFieldValue("excel_file", event?.target?.files[0]);
        handleAcceptedFiles(event, fileImg);
      } else {
        showErrorMsg("Please Choose only .xls , .xlsx , .csv Files.");
        handleAcceptedFiles(event);
        event.target.value = ""; // Clear the file input
        formik?.setFieldValue("excel_file", "");
      }
    }
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const formik: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      key_id: "",
      create_group: false,
      group_column_name: "",
      fetch_tds_only: true,
      excel_file: "",
    },
    validationSchema: Yup.object({
      key_id: Yup.string().required("Please Select Key "),
      create_group: Yup.boolean(),
      fetch_tds_only: Yup.boolean(),
      anotherField: Yup.string().when(["create_group", "fetch_tds_only"], {
        is: (create_group: any, fetch_tds_only: any) =>
          create_group === false && fetch_tds_only === false,
        then: () => Yup.boolean().required("Checked at least one checkbox"),
      }),
      group_column_name: Yup.string().when("create_group", {
        is: true,
        then: () => Yup.string().required("Please Enter Group Column Name"),
      }),
      excel_file: Yup.mixed().required("Please Choose a file"),
    }),

    onSubmit: (values: any) => {
      if (values?.excel_file) {
        const formData: any = new FormData();
        formData.append("key_id", values?.key_id);
        formData.append("create_group", values?.create_group ? 1 : 0);
        formData.append("group_column_name", values?.group_column_name);
        formData.append("fetch_tds_only", values?.fetch_tds_only ? 1 : 0);
        formData.append("excel_file", values?.excel_file);

        dispatch(ImportFile(formData)).then((res: any) => {
          if (res?.payload?.type === "success") {
            setActionData(res?.payload?.data);
            setImportModalShow(true);
            formik.resetForm();
            setSelectKey("");
            setselectedFiles([]);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        });
      }
    },
  });

  const changeGroupFilter = (value: any) => {
    setSelectKey(value);
    formik?.setFieldValue("key_id", value?.id);
    formik?.setFieldTouched("key_id", true);
  };
  const handleCreateGroupChange = (value: boolean) => {
    if (value === false) {
      formik?.setFieldValue("group_column_name", "");
    }

    formik?.setFieldValue("create_group", value);
    formik?.setFieldValue("fetch_tds_only", value);
  };
  const handleImportModal = () => {
    setImportModalShow(!importModalShow);
  };

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Market Management" title="Import Orders" />
          <Row>
            <Col xl={12}>
              <div className="card">
                <div className="card-body">
                  <div className="p-2">
                    {users?.length > 0 ? (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mt-3">
                          <Col lg={4}>
                            <div className="mb-3">
                              <Form.Label htmlFor="mail_from_name">
                                Secret Key
                              </Form.Label>
                              <div>
                                <Select
                                  placeholder={"Select Key"}
                                  name="key_id"
                                  value={{
                                    label: selectKey?.label ?? "Select Key",
                                  }}
                                  onChange={changeGroupFilter}
                                  onBlur={formik.handleBlur}
                                  options={users?.map((groupData: any) => {
                                    return {
                                      label: groupData?.name,
                                      value: groupData?.id,
                                      ...groupData,
                                    };
                                  })}
                                  styles={dropDownStyle()}
                                />

                                {formik.touched.key_id &&
                                formik.errors.key_id ? (
                                  <div className="text-danger mt-1">
                                    {formik.errors.key_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="">
                                <Form.Label
                                  className="form-check-label"
                                  htmlFor="create_group"
                                >
                                  Create Group
                                </Form.Label>
                                <Form.Check
                                  type="checkbox"
                                  id="create_group"
                                  name="create_group"
                                  onChange={(e) => {
                                    handleCreateGroupChange(e?.target?.checked);
                                  }}
                                  onBlur={formik.handleBlur}
                                  checked={
                                    formik?.values?.create_group || false
                                  }
                                  isInvalid={
                                    formik.errors.create_group &&
                                    formik.touched.create_group
                                  }
                                />
                              </div>
                              <div className="">
                                <Form.Label
                                  htmlFor="fetch_tds_only"
                                  className="form-check-label"
                                >
                                  Fetch TDS Only
                                </Form.Label>
                                <Form.Check
                                  type="checkbox"
                                  id="fetch_tds_only"
                                  className=""
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  checked={formik?.values?.fetch_tds_only}
                                  isInvalid={
                                    formik.errors.fetch_tds_only &&
                                    formik.touched.fetch_tds_only
                                  }
                                  disabled={formik?.values?.create_group}
                                />
                              </div>
                            </div>
                            {formik?.values?.create_group && (
                              <p className="mt-1 d-flex gap-1 align-items-center text-muted">
                                <i className="ri-information-fill ri-lg text-info">
                                  {" "}
                                </i>
                                This action will impact existing order groups.
                              </p>
                            )}
                            {(formik.touched.fetch_tds_only ||
                              formik.touched.create_group) &&
                            formik.errors.anotherField ? (
                              <div className="text-danger mb-2">
                                {formik.errors.anotherField}
                              </div>
                            ) : null}
                          </Col>
                        </div>

                        {formik?.values?.create_group && (
                          <Col lg={4}>
                            <div className="mb-3">
                              <Form.Label htmlFor="group_column_name">
                                Group Column Name
                              </Form.Label>

                              <Form.Control
                                id="group_column_name"
                                name="group_column_name"
                                autoComplete="off"
                                placeholder="Enter Group Column Name"
                                type="text"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.group_column_name || ""}
                                isInvalid={
                                  formik.errors.group_column_name &&
                                  formik.touched.group_column_name
                                }
                                disabled={!formik?.values?.create_group}
                                style={{
                                  cursor: `${
                                    !formik?.values?.create_group
                                      ? "not-allowed"
                                      : ""
                                  }`,
                                }}
                              />
                              {formik.touched.group_column_name &&
                              formik.errors.group_column_name ? (
                                <div className="text-danger">
                                  {formik.errors.group_column_name}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        )}
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="">
                              <Form.Label
                                htmlFor="formFileMultiple"
                                className="form-label"
                              >
                                Upload File
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="file"
                                accept=".csv,.xl"
                                name="excel_file"
                                id="formFileMultiple"
                                multiple
                                onChange={(e) => {
                                  handleFileUpload(e);
                                }}
                                onBlur={formik.handleBlur}
                                isInvalid={
                                  formik.errors.excel_file &&
                                  formik.touched.excel_file
                                }
                                ref={fileInputRef}
                              />
                            </div>

                            <div
                              className="list-unstyled mb-0"
                              id="file-previews"
                            >
                              {selectedFiles.map((f: any, i: number) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="40"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={
                                              f.preview ??
                                              "https://img.icons8.com/officel/30/document.png"
                                            }
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                            {formik.touched.excel_file &&
                            formik.errors.excel_file ? (
                              <div className="text-danger">
                                {formik.errors.excel_file}
                              </div>
                            ) : null}
                          </div>
                          <p className="d-flex gap-1 align-items-center text-muted">
                            <i className="ri-information-fill ri-lg text-info">
                              {" "}
                            </i>
                            Maximum upload size is 20 MB.
                          </p>
                          
                        </Col>

                        <div className="hstack gap-2 justify-content-between mt-4">
                          <div className=" hstack gap-2">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={importLoading}
                            >
                              {importLoading && <Spinner size="sm" />} Import
                            </button>
                          </div>
                        </div>
                      </Form>
                    ) : (
                      <NoSearchResult title={`${secretKey?.message}`} />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <ImportCsvModal
            isShow={importModalShow}
            handleClose={handleImportModal}
            actionData={actionData}
          />
        </Container>
      </div>
    </div>
  );
};

export default ImportCsv;
