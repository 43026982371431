import { Navigate } from "react-router-dom";

// Dashboard

import Dashboard from "../pages/Dashboard/Index";
import Users from "../pages/MarketManagement/Users";
import Login from "../pages/Authentication/Login";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import UserProfile from "../pages/Authentication/UserProfile";
import SmtpSetting from "../pages/Setting/SmtpSetting";
import ResetPassword from "../pages/Authentication/ResetPassword";
import AddSecretKey from "../pages/Setting/AddSecretKey";
import Currency from "../pages/Setting/Currency/Currency";
import Order from "../pages/MarketManagement/Order";
import Trade from "../pages/MarketManagement/Market";
import ChangePassword from "../pages/Authentication/ChangePassword";
import GroupOrder from "../pages/MarketManagement/GroupOrder";
import GroupOrderReport from "../pages/MarketManagement/GroupOrder/GroupOrderReport";
import TradeDetail from "../pages/TradeDetail/tradeDetail";
import ImportCsv from "../pages/Setting/ImportCsv/ImportCsv";
import ScheduleOrder from "../pages/ScheduleOrder/ScheduleOrder";
import DailyReport from "../pages/MarketManagement/DailyReport/dailyReport";
import Notification from "../pages/Notification/Nofification";

interface RouteObject {
  path: string;
  component: any;
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/index", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  { path: "/user", component: <Users /> },

  //  Profile
  { path: "/user-profile", component: <UserProfile /> },
  // SMTP setting
  { path: "/smtp_setting", component: <SmtpSetting /> },
  // secret-key
  { path: "/secret", component: <AddSecretKey /> },
  // currency
  { path: "/currency", component: <Currency /> },
  // Oerder
  { path: "/orders", component: <Order /> },

  // change password
  { path: "/change-password", component: <ChangePassword /> },

  // Trade or market
  { path: "/market", component: <Trade /> },
  // Group Order
  { path: "/group_order", component: <GroupOrder /> },
  // group order report
  { path: "/group_report", component: <GroupOrderReport /> },
  // trade details
  { path: "/trade_detail", component: <TradeDetail /> },
  // Import Csv
  { path: "/import", component: <ImportCsv /> },
  // schedule order
  { path: "/schedule_order", component: <ScheduleOrder /> },
  // notification
  { path: "/notification", component: <Notification /> },

  // Daily report

  { path: "/daily_report", component: <DailyReport /> },
];

const publicRoutes: Array<RouteObject> = [
  // { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
];

export { authProtectedRoutes, publicRoutes };
