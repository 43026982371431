import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import AddUsers from "./AddUser";
import {
  deleteUser,
  getUser,
  updateStatus,
} from "../../../slices/user/userSlice";
import UpdatePassword from "./UpdatePassword";
import { formatedDateAndTime } from "../../../helpers/util";
import { DeleteModal } from "../../../Common/DeleteModal";

interface userProps {
  isShow: any;
  hideUserModal: any;
}

const UserTable = ({ isShow, hideUserModal }: userProps) => {
  const dispatch = useDispatch();

  const usersList = useSelector((state: any) => state?.user?.data);
  const updateStatusLoading = useSelector(
    (state: any) => state?.user?.updateStatusLoading ?? false
  );
  const deleteUserLoading = useSelector(
    (state: any) => state?.user?.deleteUserLoading ?? false
  );
  const [users, setUsers] = useState<any>([]);
  const [userId, setUserId] = useState<any>([]);

  const [passwordModal, setPasswordModal] = useState<any>(false);
  const [deleteUsers, setDeleteUsers] = useState<any>(false);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deleteId, setDeleteid] = useState<any>();

  const handleDeleteModal = useCallback(
    (data: any) => {
      if (delet === true) {
        setDeleteid("");
      } else {
        setDeleteid(data);
      }

      setDelet(!delet);
    },
    [delet]
  );
  const handleDeleteId = () => {
    dispatch(deleteUser({ id: deleteId })).then((res: any) => {
      setDelet(false);
    });
  };
  const hidePassWordModal = (value: any) => {
    if (passwordModal === true) {
      setUserId("");
    } else {
      setUserId(value?.id);
    }
    setPasswordModal(!passwordModal);
  };

  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 10,
  });

  useEffect(() => {
    dispatch(getUser({ page: selected?.page, perPage: selected?.perPage }));
  }, [dispatch, selected, updateStatusLoading, deleteUserLoading]);

  useEffect(() => {
    setUsers(usersList?.data);
  }, [usersList]);

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;

    if (item === "All Tasks") {
      setUsers([...usersList]);
    } else {
      handleSearchData({
        data: usersList?.data,
        item: item,
        setState: setUsers,
      });
    }
  };

  const handleChangeStatus = (id: any) => {
    dispatch(updateStatus({ id: id }));
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return (
            <div>
              <Link to="#" className="text-body align-middle fw-medium">
                {cell.row.original.name}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "EMAIL",
        accessor: "email",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case 1:
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  Active
                </span>
              );
            case 0:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  Inactive
                </span>
              );

            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },

      {
        Header: "REGISTERED ON",
        accessor: "created_at",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return formatedDateAndTime(cell?.row?.original?.created_at);
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <>
              <li className="list-inline-item">
                <div className="form-switch  form-check form-switch-sm form-switch-primary ">
                  <OverlayTrigger
                    trigger={["click", "hover"]}
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom"> Change Status </Tooltip>
                    }
                    rootClose={true}
                  >
                    <Form.Check
                      type="checkbox"
                      role="switch"
                      defaultChecked={cell.row.original.status}
                      onClick={() => {
                        handleChangeStatus(cell.row.original?.id);
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </li>
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-bottom"> Change Password </Tooltip>
                  }
                  rootClose={true}
                >
                  <Button
                    className={`btn  btn-soft-secondary btn-sm m-0 `}
                    type="button"
                    onClick={() => {
                      hidePassWordModal({ id: cell.row.original?.id });
                    }}
                  >
                    <i className="ri-lock-password-fill align-middle ri-lg"></i>
                  </Button>
                </OverlayTrigger>
              </li>
              <li className="list-inline-item">
                <OverlayTrigger
                  trigger={["click", "hover"]}
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-bottom"> Delete User </Tooltip>}
                  rootClose={true}
                >
                  <Button
                    className={`btn  btn-soft-danger btn-sm m-0 `}
                    type="button"
                    onClick={() => {
                      handleDeleteModal(cell.row.original?.id);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill  align-middle  ri-lg"></i>
                  </Button>
                </OverlayTrigger>
              </li>
            </>
          </ul>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <Row className="pb-4 gy-3">
            <Col sm={4}>
              <button
                className="btn btn-primary addPayment-modal"
                onClick={hideUserModal}
              >
                <i className="las la-plus me-1"></i> Add New
              </button>
            </Col>

            <div className="col-sm-auto ms-auto">
              <div className="d-flex gap-3">
                <div className="search-box">
                  <Form.Control
                    type="text"
                    id="searchMemberList"
                    placeholder="Search for Result"
                    onChange={(e: any) => handleSearch(e.target)}
                  />
                  <i className="las la-search search-icon"></i>
                </div>
              </div>
              <div className="mt-2 d-flex gap-1 align-items-center text-muted">
                <i className="ri-information-fill ri-lg text-info"> </i>
                user can't be able to login when status is Inactive
              </div>
            </div>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <Card.Body>
                  {users && users.length > 0 ? (
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={users || []}
                      customPageSize={10}
                      divClassName="table-card table-responsive"
                      tableClass="table-hover table-nowrap align-middle mb-0"
                      isBordered={false}
                      PaginationClass="align-items-center mt-4 gy-3"
                      selected={selected}
                      setSelected={setSelected}
                      totalPage={usersList?.totalPages}
                    />
                  ) : (
                    <NoSearchResult title={"No Users Found"} />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <AddUsers
            isShow={isShow}
            handleClose={hideUserModal}
            handleShow={isShow}
          />
          <UpdatePassword
            isShow={passwordModal}
            handleClose={hidePassWordModal}
            userId={userId}
          />
          <DeleteModal
            title={{ text: "Delete User", btnText: "Delete" }}
            show={delet}
            handleClose={handleDeleteModal}
            deleteModalFunction={handleDeleteId}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default UserTable;
