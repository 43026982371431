import React, { useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Col, Container, Row } from "react-bootstrap";
import MarketSideTable from "./MarketSideTable";

const Trade = () => {
  document.title = "Market  | Crypto-Trade-App";

  const [isShow, setIsShow] = useState(false);

  const hideUserModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Market Management" title="Market " />
          <Row>
            <Col md={12}>
              <MarketSideTable isShow={isShow} hideUserModal={hideUserModal} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Trade;
