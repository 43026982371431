import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";


const initialState = {
  loading: false,
  data: [],
  error: null,
  updateStatusLoading: false,
};

export const getCurrency = createAsyncThunk(
  "get-currency",
  async (data: any) => {
    try {
      const response: any = await apiClient.get(`api/currencies`, data);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
// update CurrencyStatus
export const CurrencyStatus = createAsyncThunk(
  "updateStatus",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/currency/status/${data?.id}`,
        data
      );

      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const currencySlice = createSlice({
  name: "currencySetting",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getCurrency.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getCurrency.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload || null;
    });
    builder.addCase(getCurrency.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    // update status
    builder.addCase(CurrencyStatus.pending, (state: any, action: any) => {
      state.updateStatusLoading = true;
    });
    builder.addCase(CurrencyStatus.rejected, (state: any, action: any) => {
      state.updateStatusLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(CurrencyStatus.fulfilled, (state: any, action: any) => {
      state.updateStatusLoading = false;
    });
  },
});

export default currencySlice.reducer;
