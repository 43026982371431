import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  editPriceData: [],
  editScheduleLoading: false,
  cancelScheduleLoading: false,
  importLoading: false,
};
//get Schedule order
export const getScheduleOrders = createAsyncThunk(
  "get-schedule-orders",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/schedule/orders`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// cancel schedule order
export const cancelScheduleOrder = createAsyncThunk(
  "cancel-schedule-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/schedule/cancel/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// edit schedule order
export const editScheduleOrder = createAsyncThunk(
  "edit-schedule-order",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/schedule/edit/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// import csv
export const ImportFile = createAsyncThunk(
  "ImportFile",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.filePost(
        `api/orders/import`,
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const scheduleOrderSlice = createSlice({
  name: "scheduleOrderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get schedule orders
    builder.addCase(getScheduleOrders.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getScheduleOrders.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(getScheduleOrders.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });

    // edit schedule order
    builder.addCase(editScheduleOrder.pending, (state: any, action: any) => {
      state.editScheduleLoading = true;
    });
    builder.addCase(editScheduleOrder.rejected, (state: any, action: any) => {
      state.editScheduleLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(editScheduleOrder.fulfilled, (state: any, action: any) => {
      state.editScheduleLoading = false;
      state.editPriceData = action.payload;
    });

    // cancel schedule order
    builder.addCase(cancelScheduleOrder.pending, (state: any, action: any) => {
      state.cancelScheduleLoading = true;
    });
    builder.addCase(cancelScheduleOrder.rejected, (state: any, action: any) => {
      state.cancelScheduleLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(
      cancelScheduleOrder.fulfilled,
      (state: any, action: any) => {
        state.cancelScheduleLoading = false;
      }
    );
    // import csv file
    builder.addCase(ImportFile.pending, (state: any, action: any) => {
      state.importLoading = true;
    });
    builder.addCase(ImportFile.rejected, (state: any, action: any) => {
      state.importLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(ImportFile.fulfilled, (state: any, action: any) => {
      state.importLoading = false;
    });
  },
});

export default scheduleOrderSlice.reducer;
