import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../slices/logout/logoutSlice";
import { profileUser } from "../slices/user/userSlice";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state?: any) => state?.user?.profileUserData ?? []
  );
  const changeNameLoading = useSelector(
    (state: any) => state?.user?.changeUserNameLoading ?? false
  );
  useEffect(() => {
    dispatch(profileUser({}));
  }, [changeNameLoading]);

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Dropdown className="header-item">
        <Dropdown.Toggle className="btn bg-transparent border-0 arrow-none p-0 m-0">
          <span className="d-flex align-items-center  mt-4">
            <img
              src="https://img.icons8.com/officel/16/user.png"
              alt="user"
              className="rounded-circle header-profile-user"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                {userData?.name}{" "}
                <i className="las la-angle-down fs-12 ms-1"></i>
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className="dropdown-item">
            <Link to={"/user-profile"}>
              <div>
                <i className="bx bx-user fs-15 align-middle me-1"></i>{" "}
                <span key="t-profile">Profile</span>
              </div>
            </Link>
          </Dropdown.Item>

          <Dropdown.Item className="dropdown-item d-block">
            <Link to={"/change-password"}>
              <i className="bx bx-wrench fs-15 align-middle me-1"></i>{" "}
              <span key="t-settings">Change Password</span>
            </Link>
          </Dropdown.Item>
          <div className="dropdown-divider"></div>
          <Dropdown.Item
            className="dropdown-item text-danger"
            onClick={() => {
              dispatch(logout({})).then((res: any) => {
                if (res?.payload?.type === "success") {
                  localStorage?.clear();
                  navigate("/login");
                }
              });
            }}
          >
            <i className="bx bx-power-off fs-15 align-middle me-1 text-danger"></i>{" "}
            <span key="t-logout">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
