import { useState } from "react";
import {
  addSecretKey,
  getSecretKey,
} from "../../slices/settingSlice/UserSettingSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
interface userProps {
  isShow: boolean;
  handleClose: any;
  editData: any;
  setEditData: any;
}
const AddSecretKeyModal = ({
  isShow,
  handleClose,
  editData,
  setEditData,
}: userProps) => {
  const dispatch: any = useDispatch();
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);
  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const addSecretLoading = useSelector(
    (state: any) => state?.userSetting?.loading ?? false
  );
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData ? editData?.name : "",
      platform: editData ? editData?.platform : "coin_dcx",
      secret_key: editData ? editData?.secret_key : "",
      api_key: editData ? editData?.api_key : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter name"),
      platform: Yup.string().required("Please Select platform"),
      secret_key: Yup.string().required("Please Enter Secret Key"),
      api_key: Yup.string().required("Please Enter API Key"),
    }),
    onSubmit: (values: any) => {
      let secretData = {
        name: values?.name,
        secret_key: editData?.secret_key,
        api_key: editData?.api_key,
        platform: editData?.platform,
        id: editData?.id,
      };
      let finalData = editData ? secretData : values;

      dispatch(addSecretKey(finalData)).then((res: any) => {
        if (res?.payload?.type === "success") {
          dispatch(getSecretKey({}));
          setShowApiKey(false);
          setShowSecretKey(false);
          setEditData("");
          handleClose("");
          validation.resetForm();
        }
      });
    },
  });
  return (
    <div>
      <Modal centered show={isShow} style={{ display: "block" }} tabIndex={-1}>
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">
              {editData ? "Update" : "Add"} Secret Key
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleClose();
                validation.resetForm();
              }}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="mt-3">
                <Col lg={12}>
                  <div className="mb-3 ">
                    <Form.Label htmlFor="name">Enter Name</Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Control
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        autoComplete="off"
                        className="position-relative"
                        type={"text"}
                        value={validation.values.name || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isInvalid={
                          validation.errors.name && validation.touched.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="mb-3 ">
                    <Form.Label htmlFor="name"> Select Platform</Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Select
                        className={`form-select `}
                        style={{
                          cursor: `${editData ? "not-allowed" : ""}`,
                        }}
                        disabled={editData && true}
                        name="mail_mailers_smtp_encryption"
                        id="mail_mailers_smtp_encryption"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation?.values?.mail_mailers_smtp_encryption}
                        isInvalid={
                          validation.errors.mail_mailers_smtp_encryption &&
                          validation.touched.mail_mailers_smtp_encryption
                            ? true
                            : false
                        }
                      >
                        <option
                          value={editData ? editData?.platform : "coin_dcx"}
                        >
                          {editData
                            ? editData?.platform?.split("_")?.join(" ")
                            : "coin dcx"}
                        </option>
                      </Form.Select>
                      {validation.touched.name && validation.errors.name ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="mb-3 ">
                    <Form.Label htmlFor="api_key">Enter API Key</Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Control
                        id="api_key"
                        name="api_key"
                        placeholder="Enter API Key"
                        className="position-relative"
                        type={showApiKey ? "text" : "password"}
                        value={validation.values.api_key || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isInvalid={
                          validation.errors.api_key &&
                          validation.touched.api_key
                            ? true
                            : false
                        }
                        readOnly={editData && true}
                      />
                      {validation.touched.api_key &&
                      validation.errors.api_key ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.api_key}
                        </Form.Control.Feedback>
                      ) : null}
                      <button
                        className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setShowApiKey(!showApiKey)}
                        style={{
                          top: "0px",
                          right: "15px",
                        }}
                      >
                        <i
                          className={`${
                            !showApiKey ? `ri-eye-fill` : `ri-eye-off-line`
                          }  align-middle`}
                        ></i>
                      </button>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3 ">
                    <Form.Label htmlFor="secret_key">
                      Enter Secret Key
                    </Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Control
                        id="secret_key"
                        name="secret_key"
                        placeholder="Enter Secret Key"
                        className="position-relative"
                        type={showSecretKey ? "text" : "password"}
                        value={validation.values.secret_key || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isInvalid={
                          validation.errors.secret_key &&
                          validation.touched.secret_key
                            ? true
                            : false
                        }
                        readOnly={editData && true}
                      />
                      {validation.touched.secret_key &&
                      validation.errors.secret_key ? (
                        <Form.Control.Feedback type="invalid">
                          {validation.errors.secret_key}
                        </Form.Control.Feedback>
                      ) : null}
                      <button
                        className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setShowSecretKey(!showSecretKey)}
                        style={{
                          top: "0px",
                          right: "15px",
                        }}
                      >
                        <i
                          className={`${
                            !showSecretKey ? `ri-eye-fill` : `ri-eye-off-line`
                          }  align-middle`}
                        ></i>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="hstack gap-2 justify-content-between mt-4">
                <div className=" hstack gap-2">
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    disabled={addSecretLoading}
                  >
                    {addSecretLoading && <Spinner size="sm" />}{" "}
                    {editData ? "Update" : "Save"}
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default AddSecretKeyModal;
