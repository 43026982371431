import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  updateLoading: false,
  testSettingLoading: false,
};

export const getSetting = createAsyncThunk(
  "smtp-getSetting",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/settings`, data);
      return response?.data;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const upDateSetting = createAsyncThunk(
  "smtp-updateSetting",
  async (data: any) => {
    try {
      const response: any = await apiClient.put(`api/settings`, data);
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const testSetting = createAsyncThunk(
  "smtp-testSmtpSetting",
  async (data: any) => {
    try {
      const response: any = await apiClient.create(
        `api/settings/test-mail`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

const getSettingSlice = createSlice({
  name: "getSetting",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getSetting.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getSetting.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(getSetting.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(upDateSetting.pending, (state: any, action: any) => {
      state.updateLoading = true;
    });
    builder.addCase(upDateSetting.rejected, (state: any, action: any) => {
      state.updateLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(upDateSetting.fulfilled, (state: any, action: any) => {
      state.updateLoading = false;
    });

    builder.addCase(testSetting.pending, (state: any, action: any) => {
      state.testSettingLoading = true;
    });
    builder.addCase(testSetting.rejected, (state: any, action: any) => {
      state.testSettingLoading = false;
      state.error = action.payload || null;
    });
    builder.addCase(testSetting.fulfilled, (state: any, action: any) => {
      state.testSettingLoading = false;
    });
  },
});

export default getSettingSlice.reducer;
