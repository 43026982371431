import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../slices/login/loginSlice";

const ChangePassword = () => {
  document.title = "Change Password | Crypto-Trade-App";
  const dispatch: any = useDispatch();

  const [passwordField, setPasswordField] = useState<any>({
    current_password: false,
    password: false,
    password_confirmation: false,
  });

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("Please Enter Current Password"),
      password: Yup.string().required("Please Enter New Password"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
        .required("Please Enter Confirm New Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(changePassword(values));
      validation.resetForm();
    },
  });

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb pageTitle="Setting" title="Change Password" /> */}
          <h5 className="text-muted mb-2">Change Password</h5>
          <Row>
            <Col xl={12}>
              <div className="card">
                <div className="card-body">
                  <div className="p-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Col lg={6}>
                        <div className="mb-3 ">
                          <Form.Label htmlFor="current_password">
                            Current Password
                          </Form.Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Form.Control
                              id="current_password"
                              name="current_password"
                              placeholder="Enter Current Password"
                              className="position-relative"
                              type={
                                passwordField?.current_password
                                  ? "text"
                                  : "password"
                              }
                              value={validation.values.current_password || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              isInvalid={
                                validation.errors.current_password &&
                                validation.touched.current_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.current_password &&
                            validation.errors.current_password ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.current_password}
                              </Form.Control.Feedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute  mr-5 text-decoration-none text-muted password-addon"
                              type="button"
                              id="password-addon"
                              style={{
                                top: "0px",
                                right: "15px",
                              }}
                            >
                              <i
                                onClick={() =>
                                  setPasswordField({
                                    ...passwordField,
                                    current_password:
                                      !passwordField?.current_password,
                                  })
                                }
                                className={`${
                                  !passwordField?.current_password
                                    ? `ri-eye-fill`
                                    : `ri-eye-off-line`
                                }  align-middle`}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3 ">
                          <Form.Label htmlFor="password">
                            New Password
                          </Form.Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Form.Control
                              id="password"
                              name="password"
                              placeholder="Enter New Password"
                              className="position-relative"
                              type={
                                passwordField?.password ? "text" : "password"
                              }
                              value={validation.values.password || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              isInvalid={
                                validation.errors.password &&
                                validation.touched.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.password}
                              </Form.Control.Feedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute  text-decoration-none text-muted password-addon"
                              type="button"
                              id="password-addon"
                              style={{
                                top: "0px",
                                right: "15px",
                              }}
                            >
                              <i
                                onClick={() =>
                                  setPasswordField({
                                    ...passwordField,
                                    password: !passwordField?.password,
                                  })
                                }
                                className={`${
                                  !passwordField?.password
                                    ? `ri-eye-fill`
                                    : `ri-eye-off-line`
                                }  align-middle`}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3 ">
                          <Form.Label htmlFor="password_confirmation">
                            Confirm New Password
                          </Form.Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Form.Control
                              id="password_confirmation"
                              name="password_confirmation"
                              placeholder="Enter Confirm New Password"
                              className="position-relative"
                              type={
                                passwordField?.password_confirmation
                                  ? "text"
                                  : "password"
                              }
                              value={
                                validation.values.password_confirmation || ""
                              }
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              isInvalid={
                                validation.errors.password_confirmation &&
                                validation.touched.password_confirmation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password_confirmation &&
                            validation.errors.password_confirmation ? (
                              <Form.Control.Feedback type="invalid">
                                {validation.errors.password_confirmation}
                              </Form.Control.Feedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute text-decoration-none text-muted password-addon"
                              type="button"
                              id="password-addon"
                              style={{
                                top: "0px",
                                right: "15px",
                              }}
                            >
                              <i
                                onClick={() =>
                                  setPasswordField({
                                    ...passwordField,
                                    password_confirmation:
                                      !passwordField?.password_confirmation,
                                  })
                                }
                                className={`${
                                  !passwordField?.password_confirmation
                                    ? `ri-eye-fill`
                                    : `ri-eye-off-line`
                                }  align-middle `}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </Col>

                      <div className="hstack gap-2 justify-content-between mt-4">
                        <div className=" hstack gap-2">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ChangePassword;
