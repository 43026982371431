import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import {
  CurrencyStatus,
  getCurrency,
} from "../../../slices/settingSlice/CurrencySlice";
import { getMatched } from "../../../helpers/util";

const CurrencyTable = () => {
  const dispatch = useDispatch();

  const currencyData = useSelector((state: any) => state?.currency?.data ?? []);
  const updateStatusLoading = useSelector(
    (state: any) => state?.currency?.updateStatusLoading
  );
  const [users, setUsers] = useState<any>([]);
  const [selected, setSelected] = useState<any>({
    page: 1,
    perPage: 50,
  });

  useEffect(() => {
    let currencyData = {
      page: selected?.page,
      perPage: selected?.perPage,
    };
    dispatch(getCurrency(currencyData));
  }, [dispatch, updateStatusLoading, selected]);

  useEffect(() => {
    setUsers(currencyData?.data);
  }, [currencyData]);

  const handleChangeStatus = (id: any) => {
    dispatch(CurrencyStatus({ id: id }));
  };
  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          return (
            <div>
              <img
                src={`${getMatched(cell.row.original.short_name)}`}
                alt=""
                className="avatar-xs rounded-circle me-2"
              />
              <Link to="#" className="text-body align-middle fw-medium">
                {cell.row.original.name}
              </Link>
            </div>
          );
        },
      },
      {
        Header: "SHORT NAME",
        accessor: "short_name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          switch (cell.row.original.status) {
            case 1:
              return (
                <span className="badge bg-primary-subtle text-primary p-2">
                  Active
                </span>
              );
            case 0:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  Inactive
                </span>
              );

            default:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );
          }
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
            <>
              <li className="list-inline-item">
                <div className="form-switch form-check form-switch-sm ">
                  <OverlayTrigger
                    trigger={["click", "hover"]}
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom">Change Status</Tooltip>
                    }
                    rootClose={true}
                  >
                    <Form.Check
                      type="checkbox"
                      role="switch"
                      defaultChecked={cell.row.original.status}
                      onChange={() => {
                        handleChangeStatus(cell.row.original?.id);
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </li>
            </>
          </ul>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {users && users.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={users || []}
                  customPageSize={selected?.perPage ?? 50}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  selected={selected}
                  setSelected={setSelected}
                  totalPage={currencyData?.totalPages}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CurrencyTable;
