import { Button, Modal } from "react-bootstrap";
interface ReasonProps {
  isShow?: boolean;
  handleClose?: any;
  cancelData?: any;
}
export const ReasonModal = ({
  isShow,
  handleClose,
  cancelData,
}: ReasonProps) => {

  return (
    <div>
      <Modal show={isShow} centered>
        <Modal.Header className="p-4 pb-0">
          <Modal.Title as="h5" className="text-capitalize">
            Reason Of {cancelData?.status}
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
        </Modal.Header>
        <Modal.Body className="text-center p-5">
          <i className="bx bx-message-rounded-x text-danger display-5"></i>
          <div className="mt-4">
            {cancelData?.description ? (
              <p className="text-muted mb-4">{cancelData?.description}</p>
            ) : (
              <p className="text-muted mb-4">
                {" "}
                This Schedule Order is cancelled by user.
              </p>
            )}
            <div className="hstack gap-2 justify-content-center">
              <Button className="btn btn-secondary" onClick={handleClose}>
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
