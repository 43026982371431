import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { editScheduleOrder } from "../../slices/scheduleOrder/scheduleOrderSlice";

interface userProps {
  isShow: boolean;
  handleClose: any;
  actionData: any;
}

const ScheduleEditModal = ({ isShow, handleClose, actionData }: userProps) => {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      side: actionData?.side ?? "",
      price_per_unit: Number(actionData?.price_per_unit) ?? "",
      total_quantity: Number(actionData?.total_quantity) ?? "",
      trigger_price: Number(actionData?.trigger_price) ?? "",
      note: actionData?.note ?? "",
    },
    validationSchema: Yup.object({
      side: Yup.string().required("Please Enter Side"),
      price_per_unit: Yup.string().required("Please Enter Price Per Unit"),
      total_quantity: Yup.string().required("Please Enter Total Quantity"),
      trigger_price: Yup.string().required("Please Enter Trigger Price"),
    }),

    onSubmit: (values: any) => {
      let data = {
        price_per_unit: values?.price_per_unit,
        side: values?.side,
        total_quantity: values?.total_quantity,
        trigger_price: values?.trigger_price,
        note: values?.note,
        id: actionData?.id,
      };
      dispatch(editScheduleOrder(data)).then((res: any) => {
        if (res?.payload?.type === "success") {
          handleClose();
          formik.resetForm();
        }
      });
    },
  });
  const calculatePayableAmount = (quantity: any, pricePerUnit: any) => {
    const amount = quantity * pricePerUnit;
    formik?.setFieldValue("payable_amount", amount == 0 ? "" : amount);
  };
  const handlePriceChange = (event: any) => {
    formik?.setFieldValue("price_per_unit", event?.target?.value);
    formik?.setFieldTouched("price_per_unit", false);
    calculatePayableAmount(
      formik?.values?.total_quantity,
      event?.target?.value
    );
  };

  const handleQuantityChange = (event: any) => {
    formik?.setFieldValue("total_quantity", event?.target?.value);
    formik?.setFieldTouched("total_quantity", false);
    calculatePayableAmount(
      event?.target?.value,
      formik?.values?.price_per_unit
    );
  };
  const handleAmountChange = (event: any) => {
    formik?.setFieldValue("payable_amount", event?.target?.value);
    formik?.setFieldTouched("payable_amount", false);
    let updateQuantity = event?.target?.value / formik?.values?.price_per_unit;
    formik?.setFieldValue(
      "total_quantity",
      updateQuantity === 0 ? "" : updateQuantity
    );
  };
  useEffect(() => {
    calculatePayableAmount(
      Number(actionData?.total_quantity),
      Number(actionData?.price_per_unit)
    );
  }, [actionData]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5" className="text-capitalize">
              Edit Schedule Order
            </Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row className="mt-3">
                  <div className="mb-3">
                    <Form.Label htmlFor="side">Side</Form.Label>
                    <Form.Control
                      id="side"
                      name="side"
                      placeholder="Enter Enter"
                      type="text"
                      value={formik.values.side || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.side && formik.touched.side ? true : false
                      }
                      readOnly={true}
                    />
                    {formik.touched.side && formik.errors.side ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.side}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Form.Label htmlFor="price_per_unit">
                      Price Per Unit
                    </Form.Label>
                    <Form.Control
                      id="price_per_unit"
                      name="price_per_unit"
                      placeholder="Enter Price Per Unit"
                      type="number"
                      step={0.1}
                      value={formik.values.price_per_unit || ""}
                      onChange={handlePriceChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.price_per_unit &&
                        formik.touched.price_per_unit
                          ? true
                          : false
                      }
                    />
                    {formik.touched.price_per_unit &&
                    formik.errors.price_per_unit ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.price_per_unit}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Form.Label htmlFor="price">Trigger Price</Form.Label>
                    <Form.Control
                      id="trigger_price"
                      name="trigger_price"
                      placeholder="Enter Trigger Price"
                      type="number"
                      step={0.1}
                      value={formik.values.trigger_price || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.trigger_price &&
                        formik.touched.trigger_price
                          ? true
                          : false
                      }
                    />
                    {formik.touched.trigger_price &&
                    formik.errors.trigger_price ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.trigger_price}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Form.Label htmlFor="total_quantity">
                      Total Quantity
                    </Form.Label>
                    <Form.Control
                      id="total_quantity"
                      name="total_quantity"
                      placeholder="Enter Total Quantity"
                      type="number"
                      step={0.1}
                      value={formik.values.total_quantity || ""}
                      onChange={handleQuantityChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.total_quantity &&
                        formik.touched.total_quantity
                          ? true
                          : false
                      }
                    />
                    {formik.touched.total_quantity &&
                    formik.errors.total_quantity ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.total_quantity}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Form.Label htmlFor="payable_amount">
                      Payable Amount
                    </Form.Label>
                    <Form.Control
                      id="payable_amount"
                      name="payable_amount"
                      placeholder="Enter Payable Amount"
                      type="number"
                      onChange={handleAmountChange}
                      value={formik?.values?.payable_amount || ""}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.payable_amount &&
                        formik.touched.payable_amount
                      }
                    />
                    {formik.touched.payable_amount &&
                    formik.errors.payable_amount ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.payable_amount}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Form.Label htmlFor="note">Note</Form.Label>
                    <textarea
                      className={`form-control ${
                        formik.touched.note && formik.errors.note
                          ? "is-invalid"
                          : ""
                      }`}
                      rows={3}
                      id="note"
                      name="note"
                      placeholder="Enter Your Note"
                      onChange={formik?.handleChange}
                      value={formik?.values?.note || ""}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.note && formik.errors.note ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.note}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Row>
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className={`btn text-capitalize btn-secondary `}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleEditModal;
