import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showErrorMsg, showSuccessMsg } from "../../Common/Toast/Toast";
import { apiClient } from "../../helpers/api_helper";

const initialState = {
  loading: false,
  data: [],
  error: null,
  addNotificationLoading: false,
  deleteNotificationLoading: false,
  updateNotificationData: [],
  updateNotificationLoading: false,
};
//get all notification
export const getNotification = createAsyncThunk(
  "get-notification",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.get(`api/market-notification`, data);
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//update notification
export const updateNotification = createAsyncThunk(
  "update-notification",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.put(
        `api/market-notification/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// add market order notification
export const addNotification = createAsyncThunk(
  "add-notification",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.create(
        `api/market-notification`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// delete notification
export const deletenotification = createAsyncThunk(
  "delete-notification",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.delete(
        `api/market-notification/${data?.id}`,
        data
      );
      if (response?.type === "success") {
        showSuccessMsg(response?.message);
      } else {
        showErrorMsg(response?.message);
      }
      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get all notification
    builder.addCase(getNotification.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getNotification.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(getNotification.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });

    //update notification
    builder.addCase(updateNotification.pending, (state: any, action: any) => {
      state.updateNotificationLoading = true;
    });
    builder.addCase(updateNotification.rejected, (state: any, action: any) => {
      state.updateNotificationLoading = false;
      state.updateNotificationData = [];
      state.error = action.error.message || null;
    });
    builder.addCase(updateNotification.fulfilled, (state: any, action: any) => {
      state.updateNotificationLoading = false;
      state.updateNotificationData = action.payload;
    });
    //add notification
    builder.addCase(addNotification.pending, (state: any, action: any) => {
      state.addNotificationLoading = true;
    });
    builder.addCase(addNotification.rejected, (state: any, action: any) => {
      state.addNotificationLoading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(addNotification.fulfilled, (state: any, action: any) => {
      state.addNotificationLoading = false;
    });

    //delete notification
    builder.addCase(deletenotification.pending, (state: any, action: any) => {
      state.deleteNotificationLoading = true;
    });
    builder.addCase(deletenotification.rejected, (state: any, action: any) => {
      state.deleteNotificationLoading = false;
      state.data = [];
      state.error = action.error.message || null;
    });
    builder.addCase(deletenotification.fulfilled, (state: any, action: any) => {
      state.deleteNotificationLoading = false;
    });
  },
});

export default notificationSlice.reducer;
