import moment from "moment";

// create object into array
export const createListOfObject = (data: any) => {
  const isDataValid = data || {};
  const dataList = Object.entries(isDataValid).map(([label, value]) => ({
    label,
    value,
  }));
  return dataList;
};

const getAuthToken = () => {
  const user = localStorage?.getItem("TOKEN");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
const getUserToken = () => {
  const user = localStorage?.getItem("USER");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const setAuthToken = (data: any) => {
  localStorage?.setItem("TOKEN", JSON.stringify(data));
};
const setUserToken = (data: any) => {
  localStorage?.setItem("USER", JSON.stringify(data));
};
const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: Parameters<F>) {
    const context = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

// if value==1 then admin else other account or user
const getRole = (value: number) => {
  if (value === 0) {
    return "user";
  } else if (value === 1) {
    return "admin";
  } else {
    return;
  }
};

const getMatched = (value: any) => {
  let img: string = "https://img.icons8.com/ios-filled/50/cryptocurrency.png"; // Default image
  const matchedCoin = dataOfCoins.find((coin) => coin.name === value); // Using find instead of filter

  if (matchedCoin) {
    img = matchedCoin.img;
  }

  return img;
};

const dataOfCoins = [
  {
    name: "BNB",
    img: "https://img.icons8.com/external-black-fill-lafs/64/000000/external-Binance-cryptocurrency-black-fill-lafs.png",
  },
  { name: "BTC", img: "https://img.icons8.com/arcade/64/bitcoin.png" },
  {
    name: "BUSD",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Binance-USD-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "DAI",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Dai-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "ETH",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Ethereum-cryptocurrency-black-fill-lafs.png",
  },
  { name: "INR", img: "https://img.icons8.com/papercut/60/rupee.png" },
  {
    name: "TRX",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-TRON-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "TUSD",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-TrueUSD-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "USDC",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-USDC-cryptocurrency-black-fill-lafs.png",
  },
  { name: "USDT", img: "https://img.icons8.com/ios-filled/50/tether.png" },
  {
    name: "XRP",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-XRP-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "SHIB",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Shiba-Inu-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "ADA",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Cardano-cryptocurrency-black-fill-lafs.png",
  },
  {
    name: "UNI",
    img: "https://img.icons8.com/external-black-fill-lafs/64/external-Uniswap-cryptocurrency-black-fill-lafs.png",
  },
];

const isValidArray = (data: any) => {
  return data && Array.isArray(data) && data.length > 0;
};

const formatedDate = (value: any) => {
  let format = moment(value).format("DD MMMM,YYYY");
  return format;
};

const formatedDateAndTime = (value: any) => {
  let time = moment(value).format("DD/MM/YYYY  hh:mm:ss A");

  return time;
};
const getMinusPlus = (value: any) => {
  let data = Math.abs(value) == value;
  return data;
};

const setAfterDecimal = (value: any, precision?: any) => {
  const rounded = precision
    ? value?.toFixed(precision).replace(/\.?0+$/, "")
    : value;
  return rounded;
};

const formatNumber = (num?: any) => {
  if (num > 0.1 || num < 0) {
    return Number(num)
      .toFixed(2)
      .replace(/\.?0+$/, "");
  } else {
    return Number(num)
      ?.toFixed(4)
      .replace(/\.?0+$/, "");
  }
};

// convert array of object to your choosing name
const changeLableArray = (value: any, mainId: any) => {
  let array = value?.map((e: any) => {
    return {
      label: e?.name,
      group_id: e?.id,
      main_id: mainId,
    };
  });
  return array;
};
// dropdown styles
const dropDownStyle = () => {
  const colourStyles = {
    dropdownIndicator: (styles: any) => ({ ...styles, color: "#068134" }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "block",
      backgroundColor: "#068134",
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "#103D56",
      left: "0%",
      lineHeight: "1.3rem",
      paddingLeft: "0.5rem",
      marginLeft: "0rem",
    }),
    control: () => ({
      fontSize: "0.85rem",
      display: "flex",
      border: "1px solid #159443",
      borderRadius: "5px",
      fontWeight: "bold",
      textTransform: "capitalize",
    }),
    option: (styles: any, { data }: any) => ({
      ...styles,
      padding: "5px",
      fontSize: "0.90rem",
      textAlign: "left",
      color: data?.status === 0 ? "#2563eb" : "#103D56" ?? "#103D56",
      background: "white",
      cursor: "pointer",
      border: "0.1rem  #103D56",
      ":last-of-type": {
        borderBottom: "none",
      },
      ":hover": {
        fontWeight: "bold",
        background: "#43b28d",
        color: "white",
      },
    }),

    multiValue: (styles: any) => ({ ...styles, background: "orange" }),
    multiValueRemove: (styles: any) => ({ ...styles, color: "green" }),
    clearIndicator: (styles: any) => ({ ...styles, color: "pink" }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      border: "1px solid red",
    }),
  };

  return colourStyles;
};

export {
  setAuthToken,
  setUserToken,
  getAuthToken,
  getUserToken,
  debounce,
  dataOfCoins,
  getMatched,
  getRole,
  isValidArray,
  formatedDate,
  formatedDateAndTime,
  getMinusPlus,
  setAfterDecimal,
  changeLableArray,
  dropDownStyle,
  formatNumber,
};
