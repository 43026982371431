import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "../../helpers/api_helper";


const initialState = {
  loading: false,
  data: [],
  error: null,
  tradeData: [],
  tradeLoading: false,
};

// get dashboard
export const getDashboard = createAsyncThunk(
  "get-dashboard",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.get(`api/dashboard`, data);

      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// get trade detail
export const getTradeDetail = createAsyncThunk(
  "get-tradeDetail",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response: any = await apiClient.get(`api/trade-details`, data);

      return response;
    } catch (error: any) {
      if (error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    //get
    builder.addCase(getDashboard.pending, (state: any, action: any) => {
      state.loading = true;
    });
    builder.addCase(getDashboard.rejected, (state: any, action: any) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || null;
    });
    builder.addCase(getDashboard.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.data = action.payload;
    });
    //get trade detail
    builder.addCase(getTradeDetail.pending, (state: any, action: any) => {
      state.tradeLoading = true;
    });
    builder.addCase(getTradeDetail.rejected, (state: any, action: any) => {
      state.tradeLoading = false;
      state.tradeData = [];
      state.error = action.payload || null;
    });
    builder.addCase(getTradeDetail.fulfilled, (state: any, action: any) => {
      state.tradeLoading = false;
      state.tradeData = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
